import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";
import { Router } from "@angular/router";
import { IConnexion } from "@connexion_update/class/connexion.interface";
import { FormDataConnexion } from "@app/modules/connexion-update/class/formDataConnexion";
import { IsNotCorrectValue } from '@app/modules/inscription/helpers/must-match.validators';

@Component({
  selector: 'app-contribution-param',
  templateUrl: './contribution-param.component.html',
  styleUrls: ['./contribution-param.component.css']
})
export class ContributionParamComponent implements OnInit {
  public userEmail:string
  contributionFormUpdate: FormGroup;
  constructor(   
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
  ) { }

  ngOnInit() {
    this.getCurrentUser()
    this.contributionFormUpdate = this.contributionFormUpdateBuiler();
    this.getSettings()
  }

  public contributionFormUpdateBuiler(): FormGroup {
   
    this.contributionFormUpdate = this.formBuilder.group({
      montant: new FormControl("0", Validators.required),
      cle: new FormControl("0.6", Validators.required),
    }, {
      validator: IsNotCorrectValue('montant', 'cle')
    });
    return this.contributionFormUpdate
  }

  getSettings() {
    this.contactService.getSettingGroupApiService().subscribe(
      (data:any) => {
        this.contributionFormUpdate.get("montant").setValue(data.data.contribution_amount);
        this.contributionFormUpdate.get("cle").setValue(data.data.contribution_key);
      }, error => {
        console.log(error)
      }
    )
  }

  public forceVirgule(e) {
    if (e.key.match(/[a-z]/i)) {
      e.target.value = parseFloat(e.target.value )
    }
  }

  public getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe(
      (res:any) => {
          this.userEmail = res.data.email
      }
    )
  }

  public validContribution(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const montant = this.contributionFormUpdate.get("montant").value.replace(',','.')
    const cle = this.contributionFormUpdate.get("cle").value.replace(',','.')
    let dataBody = {
      contribution_amount: montant,
      contribution_key: cle
    }
    
    this.contactService.addSettingsService(dataBody).subscribe(
      (data:any) => {
        this.contributionFormUpdate.get("montant").setValue(data.data.contribution_amount);
        this.contributionFormUpdate.get("cle").setValue(data.data.contribution_key);
        this.serviceNotif.success('Succès', "Les données ont été mises à jour", {
          position: ['top', 'right'],
          timeOut: 2000,
          animate: 'fade',
          showProgressBar: true
        });
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'right'],
          timeOut: 2000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    )
  }
  
  get f() {
    return this.contributionFormUpdate.controls;
  }

  get cle() {
    return this.contributionFormUpdate.get("cle");
  }
  
  get montant() {
    return this.contributionFormUpdate.get("montant");
  }

}
