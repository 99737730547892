import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";

export interface FileEntry {
  fileObject: File,
  //params: {[key:string]: any}
  fieldName: string,
  fieldId: string
}
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  status: "initial" | "uploading" | "success" | "fail" = "initial";

  @Input() name: string; // as file parameter name

  @Output() fileSelectedFired = new EventEmitter<FileEntry>();
  @Input() componentID: string;
  @Input() functionalDataID: string;

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  onChange(evt) {
    const file: File = <File>evt.target.files[0];

    if (file) {
      this.status = "initial";
      const formData = new FormData();
      formData.append(name, file, file.name);
      const data: FileEntry = { fileObject: file, fieldName: this.name, fieldId: this.functionalDataID};
      this.fileSelectedFired.next(data);
    }

  }
}
