import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnexionService } from '@connexion/services/connexion.service';
import { ContactService } from '../contactez-nous/services/contact.service';

@Component({
  selector: 'app-adherent-transfert-list',
  templateUrl: './adherent-transfert-list.component.html',
  styleUrls: ['./adherent-transfert-list.component.css']
})
export class AdherentTransfertListComponent implements OnInit {
  datas = null;
  public toAssociation;
  public role;
  public transfertData: any
  public searchText: any;
  p: number = 1;
  constructor(private contactService: ContactService, private router: Router,private route: ActivatedRoute,
    private connectionService: ConnexionService) { }

  ngOnInit() {
    this.getTransfertRequest()
    this.getRoleUser()
  }

  public getTransfertRequest() {
    this.contactService.getTransfertRequestService().subscribe((res: any) => {
      if (res) {
        if (res.data.length > 0) {
          this.transfertData = res.data
        }
      }
    })
  }

  getRoleUser() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.role = "admin"
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.role = "manager"
        this.router.navigate(["/search_adherents"]);
      }
    })
  }

  getTransfertInfos() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.getTransfertRequestService().subscribe((res: any) => {
      if (res) {
        if (res.data.length > 0) {
          let ownTransfert = res.data.filter((item: any) => parseInt(item.id) === id)
          this.datas = ownTransfert[0].member
          this.toAssociation = ownTransfert[0].toAssociation
        }
      }
    })
  }

  goToTransfertAction(idTransfert) {
    this.router.navigate(["/adherent_transfert_action", idTransfert]);
  }

}
