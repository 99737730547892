export class ConnexionData {
    email: string;
    currentEmail: string;
    token: string;

    constructor() {
      this.email = null;
      this.currentEmail = null;
      this.token = null;
    }
  }