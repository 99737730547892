import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ComponentRef, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LofNews } from '@app/modules/actualites/interfaces/lof-news.interface';
import { NewsService } from '@app/modules/actualites/services/news.service';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageCroppedEvent, ImageCropperComponent, Transformations } from 'ngx-image-cropper';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { IModalDialog, IModalDialogButton, IModalDialogOptions } from 'ngx-modal-dialog';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements OnInit, OnDestroy, IModalDialog /* Added for ngx-modal-dialog */, AfterViewInit {
  descriptionTextMaxSize = 700;
  descriptionRemainingTextSize: number;
  editNewsForm = this.formBuilder.group({
    type: this.formBuilder.control('', Validators.required),
    description: this.formBuilder.control('', [Validators.required, Validators.maxLength(this.descriptionTextMaxSize)]),
    image: this.formBuilder.control('')
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: 'auto',
    minHeight: '3rem',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    placeholder: 'Saisir votre texte ....',
    width: 'auto'
  };

  /// ngx-imgdatatourl
  imgSource: string = null;
  imgOptions: Options = {
    resize: {
      maxHeight: 150, // 128 x 128
      maxWidth: 150,
      quality: 0.6
    },
    allowedExtensions: ['JPG', 'JPEG', 'PnG']
  };
  orgImageDataUrl: string = null;
  ///

  lofNews: LofNews;

  currentUser: any;

  actionButtons: IModalDialogButton[];


  /// ngx-cropped
  croppedImage: any = '';
  imageChangedEvent: any = '';
  canvasRotation = 0;
  transform: Transformations;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  ///
  isCancelAction = false;

  // should change the contact service to current user vork
  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private newsService: NewsService, private router: Router, @Inject(DOCUMENT) private doc: any)
  {
    this.actionButtons = [
      {
        text: 'Annuler',
        onAction: () => {
          this.isCancelAction = true;
          return this.isCancelAction;
        }
      },
      {
        text: 'Enregistrer',
        onAction: () => {
          if (this.editNewsForm && this.editNewsForm.valid) {
            return this.send(this.editNewsForm);
          } else {
            // force control to touched
            Object.keys(this.editNewsForm.controls).forEach(field => {
              this.editNewsForm.get(field).markAsTouched({onlySelf: true});
            });
            return false;
          }
        }
      }
    ];
    this.descriptionRemainingTextSize = this.descriptionTextMaxSize;
    this.editNewsForm.get('description').valueChanges.subscribe(v => {
      if (v !== undefined && v !== null) {
        const remainingCharsCount = this.descriptionTextMaxSize - v.length;
        this.descriptionRemainingTextSize = remainingCharsCount >= 0 ? remainingCharsCount : 0;
      }
    });
  }
  ngAfterViewInit(): void {
    const insertImgBtn = this.doc.getElementById('insertImage-news-description');
    insertImgBtn.disabled = true;
    const insertVideoBtn = this.doc.getElementById('insertVideo-news-description');
    insertVideoBtn.disabled = true;
  }

  ngOnDestroy(): void {
    // to handle closing and redirect to news page when not opened from there
    if(!this.isCancelAction && this.router.routerState.snapshot.url !== 'actualites') {
      this.router.navigate(['actualites']);
    }
  }


  // dialogInit: (reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) => void;
  dialogInit (reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<LofNews>>): void {
    // check and load for update

    if (options.data) {
      this.lofNews = options.data;
      options.title = 'Modification actualité';
      this._initEditForm();
    }
    if (options.title === undefined || options.title === null || options.title.trim() === '') {
      options.title = 'Ajout actualité';
    }
  }

  _initEditForm() {
    if (this.lofNews) {
      this.editNewsForm.get('type').setValue(this.lofNews.type);
      this.editNewsForm.get('description').setValue(this.lofNews.description);
      this.croppedImage = this.lofNews.thumbnail;
    }

  }

  ngOnInit() {
    this.getCurrentUser();

  }

  get type() {
    return this.editNewsForm.get('type');
  }

  get description() {
    return this.editNewsForm.get('description');
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any)=> {
      this.currentUser = item.data;
    });
  }

  send(fgroup: FormGroup): boolean {
    if(fgroup) {
      const newsType = fgroup.get('type').value;
      const newsDescription = fgroup.get('description').value;
      // const newsImage = fgroup.get('image').value;

      if (this.lofNews === undefined || this.lofNews === null) {
        // for creation
        const newsToCreate =  {} as LofNews;
        newsToCreate.authorId=this.currentUser['id'];
        newsToCreate.description = newsDescription;
        newsToCreate.type = newsType;
        newsToCreate.status = 'DRAFT';
        if (this.croppedImage) {
          newsToCreate.thumbnail = this.croppedImage;
        }
        this.newsService.createNews(newsToCreate);
      } else {
        this.lofNews.updatedById = this.currentUser['id'];
        this.lofNews.type = newsType;
        this.lofNews.description = newsDescription;
        this.lofNews.thumbnail = this.croppedImage;
        this.newsService.updateNews(this.lofNews);
      }
      return true;
    } else {
      return false;
    }
  }

  selectedFile(imageResult: ImageResult): void {
    if (imageResult.error) {
      alert(imageResult.error);
    }

    this.imgSource = imageResult.resized
      && imageResult.resized.dataURL
      || imageResult.dataURL;
    this.orgImageDataUrl = imageResult.dataURL;
  }

  /// cropped img
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  rotateLeft() {
    if (this.imageCropper) {
      this.imageCropper.rotateLeft();
    }
  }

  rotateRight() {
    if (this.imageCropper) {
      this.imageCropper.rotateRight();
    }
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

}
