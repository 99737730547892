import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '@app/modules/contactez-nous/services/formDataService';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public errorPage: any = false;
  isValid = false;
  constructor(
    private router: Router,
    private formDataService: FormDataService,
    private contactService: ContactService,
    ) {}

  // eslint-disable-next-line no-empty-function
  ngOnInit() {
    this.getCallHttp();

    if (this.formDataService.getConfirmationPaiement().isValid === "valid") {
      this.isValid = true;
    }

  }
  getCallHttp() {
    this.contactService.getCallHttp().subscribe((data: any) => {
      if (data) {
        return data;
      }
    },
    error => {
      if (error.status !== 200) {
        this.router.navigate(['error_page'])
        this.errorPage = true;
      }
    })
  }

}
