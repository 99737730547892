import { Component, OnInit } from '@angular/core';
import { FormDataSubscribe } from '../../services/formDataSubscribe';

@Component({
  selector: 'app-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.css']
})
export class Step6Component implements OnInit {
  type = true;
  constructor(
    private formDataSucribe: FormDataSubscribe,
  ) { }

  ngOnInit() {
    
    if (this.formDataSucribe.getChoix().type === "ASSOCIATION") {
      this.type = false;

    } else if (this.formDataSucribe.getChoix().type === "GROUP") {
    }
  }

}
