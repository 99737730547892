import * as moment from "moment/moment";

export const formatFormDate = (d: string) => {
    if (d) {
        try {
            //const formDate = moment(d, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD/MM/YYYY'); //2024-09-05T23:36:13.000Z
            const formDate = moment(d, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate().getTime(); //2024-09-05T23:36:13.000Z
            return formDate;
        } catch (e) {
            console.error(e);
            return d;
        }
    }
    return null;
};
