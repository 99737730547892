export class EventData {
    memberId: string;
    dateOfDeath: string;
    countryOfDeath: string;
    cityOfDeath: string;
    deathCertificateFile: string;
    pictureFile: string;
    idCardRectoFile: string;
    idCardVersoFile: string;
    livingCertificateFile: string;

    constructor() {
      this.memberId = null;
      this.dateOfDeath = null;
      this.countryOfDeath = null;
      this.cityOfDeath = null;
      this.deathCertificateFile = null;
      this.pictureFile = null;
      this.idCardRectoFile = null;
      this.idCardVersoFile = null;
      this.livingCertificateFile = null;
    }
  }
