import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-actualite',
  templateUrl: './update-manage.component.html',
  styleUrls: ['./update-manage.component.css']
})
export class UpdateManageComponent implements OnInit {
  add = true
  list = false
  public userForm: FormGroup;
  public datas: any;
  public monRole: any;
  public searchText: any;
  public detailId;
  passwordPatern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;

  constructor(
    private serviceNotif: NotificationsService,
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public userFormBuilder(): FormGroup {
    return new FormGroup({
      'firstName': new FormControl(""),
      'lastName': new FormControl(""),
      'password': new FormControl("",),
      'passwordConf': new FormControl("", [Validators.pattern(this.passwordPatern)]),
      'email': new FormControl("", [Validators.pattern(this.emailPattern)]),
      'role': new FormControl(""),
      'statut': new FormControl(""),
    })
  }

  ngOnInit() {
    this.getCurrentUser()
    this.getUsers()
    this.userForm = this.userFormBuilder()
  }

  newUser() {
    this.add = true
    this.list = false
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any)=> {
      if (item.data.role.value === "USER" || item.data.role.value === "ASSOCIATION_MANAGER") {
        this.router.navigate(['home'])
      }
    })
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

    const input = {
      "firstName": this.userForm.get('firstName').value || this.datas.firstName,
      "lastName": this.userForm.get('lastName').value || this.datas.lastName,
      "password": this.userForm.get('password').value || this.datas.password,
      "email": this.userForm.get('email').value || this.datas.email,
      "role": this.userForm.get('role').value || this.monRole,
      "enabled": this.userForm.get('statut').value || this.datas.enabled

    };
    this.contactService.updateUserService(input, this.detailId).subscribe((data: any) => {
      if (data) {
        this.userForm.reset();
        this.serviceNotif.success('Succès', "Utilisateur crée !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
      this.add = false
      this.list = true
      this.router.navigate(['gestion_des_utilisateurs'])
    },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      });
  }

  delete() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.deleteUserService(id).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['gestion_des_utilisateurs'])
      }
    },
      error => {
        this.serviceNotif.error('Error', "Echec de suppression !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      });
  }

   getUsers() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.detailId = id;
    let userRole = ""
     this.contactService.getUserLofService(id).subscribe((data: any) => {
      if (data) {
        this.userForm.controls.role.setValue(data.data.role.value)
        this.datas = data.data
      }
    });
    return this.datas
  }


  listUser() {
    this.getUsers()
    this.add = false
    this.list = true
  }

  get f() { return this.userForm.controls; }
  get firstName() { return this.userForm.get('firstName'); }
  get lastName() { return this.userForm.get('lastName'); }
  get password() { return this.userForm.get('password'); }
  get passwordConf() { return this.userForm.get('passwordConf'); }
  get email() { return this.userForm.get('email'); }
  get role() { return this.userForm.get('role'); }
  get statut() { return this.userForm.get('statut'); }

}
