export class AdherentsData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  position: string;
  function: string;
  department: string;
  address: string;
  phone: string;
  email: string;
  postalCode: string;

  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.dateOfBirth = null;
    this.position = null;
    this.function = null;
    this.department = null;
    this.address = null;
    this.phone = null;
    this.email = null;
    this.postalCode = null;
  }
}
