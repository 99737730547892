import { Injectable } from "@angular/core";
import { AdherentsData } from "@adherent_subscribe/class/adherent";
import { IAdhrents } from "@adherent_subscribe/interfaces/adherent.interface";

@Injectable()
export class AdherentsFormDataSubscribe {
  private adherentsFormData = new AdherentsData();

  public getAdherentsRegister(): IAdhrents {
    return {
      firstName: this.adherentsFormData.firstName,
      lastName: this.adherentsFormData.lastName,
      dateOfBirth: this.adherentsFormData.dateOfBirth,
      position: this.adherentsFormData.position,
      function: this.adherentsFormData.function,
      department: this.adherentsFormData.department,
      // address: this.adherentsFormData.address,
      // phone: this.adherentsFormData.phone,
      // email: this.adherentsFormData.email,
      // postalCode: this.adherentsFormData.postalCode,
    };
  }

  public setAdherentsRegister(input: IAdhrents) {
    this.adherentsFormData.firstName = input.firstName;
    this.adherentsFormData.lastName = input.lastName;
    this.adherentsFormData.dateOfBirth = input.dateOfBirth;
    this.adherentsFormData.position = input.position;
    this.adherentsFormData.function = input.function;
    // this.adherentsFormData.department = input.department;
    // this.adherentsFormData.address = input.address;
    // this.adherentsFormData.phone = input.phone;
    // this.adherentsFormData.email = input.email;
    // this.adherentsFormData.postalCode = input.postalCode;
  }

  public clearDataAdherents() {
    const clearAdherents: IAdhrents = {
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      position: null,
      function: null,
      department: null,
      // address: null,
      // phone: null,
      // email: null,
      // postalCode: null
    };
    this.setAdherentsRegister(clearAdherents);
  }
}
