import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../environments/environment";
import { ConnexionService } from './modules/connexion/services/connexion.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  count = 0
  envName = environment.name;
  title = 'lof';

  constructor(
    private connectionService: ConnexionService,
    public router: Router,
    ) {
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      window.onmousemove = () => {
        this.count = 0
      }
      this.addTimeSession()
    }
  }

  addTimeSession() {
     setInterval(() => {
      this.count = this.count + 1
      if (this.count === 1900) {
        this.connectionService.deconnexionUser()
        this.count = 0
        this.router.navigate(['home'])
        window.location.reload()
      }
    }, 1000)
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); 
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
