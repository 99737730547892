import { Component, OnInit } from '@angular/core';
import { FormDataService } from '../contactez-nous/services/formDataService';
import { IConfirmation } from '../connexion/interfaces/connexion.interface';

const input: IConfirmation = {
  isValid: "invalid"
};

@Component({
  selector: 'app-confirm-paiement',
  templateUrl: './confirm-paiement.component.html',
  styleUrls: ['./confirm-paiement.component.css']
})
export class ConfirmPaiementComponent implements OnInit {

  constructor(
    private formDataService: FormDataService
  ) { }

  ngOnInit() {
    this.changeMenuDisplay();
  }
  
  public changeMenuDisplay(){
    this.formDataService.setConfirmationPaiement(input);    
  }

  public goToSpaceMember() {
    const inputCon: IConfirmation = {
      isValid: "valid"
    };
    this.formDataService.setConfirmationPaiement(inputCon)
    window.location.reload();
  }

}
