import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

type Value = number;

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ],
})
export class DateComponent implements OnInit, ControlValueAccessor {
  @Input() max:Date;
  @Input() min: Date;
  @Input() placeholder: string;

  isDisabled: boolean;
  _value: Value;

  @Output() changed = new EventEmitter<Value>();

  constructor() { }

  ngOnInit() {
  }

  get inputValue(): Date {
    return this._value  ? new Date(this._value) : null;
  }
  private propagateChange: any = () => {};
  private propagaTouched: any = () => {};

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const changedValue = event.value ? event.value.getTime() : null;
  }

  onPickerClosed() {

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagaTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: Value): void {
    this._value = obj ? new Date(obj).getTime() : null;
  }

  onChange(event: MatDatepickerInputEvent<Date>){
    const v = event.value ? event.value.getTime() : null;

    this._value = v;
    this.propagateChange(v);
    this.changed.next(v);
  }
  onClosed() {
    this.propagaTouched();
  }

  clearValue() {
    this._value = null;
    this.propagateChange(null);
    this.changed.next(null);
  }
}
