import { HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LofApiBackendService } from '@app/modules/business/lof-backend/lof-api-backend.service';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LofNews } from '../interfaces/lof-news.interface';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  headerOptions = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('Authorization', `Bearer ${localStorage.getItem("token")}`)

  };

  lofNewsArray: LofNews[] = [];
  lofNewsSubscription$: Observable<any>;
  lofNewsSubject$: BehaviorSubject<LofNews[]> = new BehaviorSubject([]);
  isConnected: boolean;
  currentUser: any;
  isLofAdminOrSuperAdmin: boolean;

  constructor(private lofApiBackendService: LofApiBackendService,
    private contactService: ContactService,
    private serviceNotif: NotificationsService
    ) {
      this._init();
    }

  private _init(): void {
    if (localStorage.getItem('token')) {
      this.isConnected = true;
      // Why calling each time? user role may change, right???
      this.contactService.getCurrentUserService().subscribe((item: any) => {
        this.currentUser = item.data;
        this.isLofAdminOrSuperAdmin = item.data.role.value === 'SUPER_ADMIN' || item.data.role.value === 'LOF_ADMIN';
        // ack of second call
        if(this.isLofAdminOrSuperAdmin) {
          this.lofNewsSubscription$ = this._allLofNewsObservable();
        } else {
          this.lofNewsSubscription$ = this._getPublishedNews();
        }
        this._loadLofNews();
      });

    } else {
      this.isConnected = false;
      this.isLofAdminOrSuperAdmin = false;
      this.currentUser = undefined;
      this.lofNewsSubscription$ = this._getPublishedNews();
      this._loadLofNews();
    }
  }

  private _loadLofNews() {
    if (this.lofNewsSubscription$) {
      this.lofNewsSubscription$.subscribe(
        newsDatas => {
          this._updateLofNewsWith(newsDatas);
        },
        error => this.serviceNotif.error('Erreur', "Une erreur est survenue lors de la récupération des actualités. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
        position: ['top', 'right'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      }));
    }
  }

  private _getPublishedNews() {
    return this.lofApiBackendService.getPublishedNewsBackendService();
  }

  private _allLofNewsObservable(): Observable<any> {
    return this.lofApiBackendService.getAllNewsBackendService(this.headerOptions);
  }

  private _updateLofNewsWith(newLofNewsArray: LofNews[]) : void {
    this.lofNewsArray = newLofNewsArray;
    this.lofNewsSubject$.next(this.lofNewsArray);
  }

  public getLofNews(): Observable<LofNews[]> {
    return this.lofNewsSubject$.asObservable();
  }


  public createNews(newsToCreate: LofNews): void {
    this.lofApiBackendService.addNewsBackendService(newsToCreate, this.headerOptions).subscribe(response => {
      const createdLofNews = response.data;
      if (createdLofNews) {
        // Add a top of this.lofNewsArray
        this.lofNewsArray.unshift(createdLofNews);
        this._updateLofNewsWith(this.lofNewsArray);
        this.serviceNotif.success('Succès', "Votre actualité est créée !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    },
    error => {
      this.serviceNotif.error('Erreur', "Une erreur est survenue lors de la création de l'actualité. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
        position: ['top', 'right'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      });
    });
  }

  public updateNews(newsToUpdate: LofNews): void {
    this.lofApiBackendService.updateNewsBackendService(newsToUpdate, this.headerOptions).subscribe(uptdNews => {
      const updatedNews = uptdNews;
      this.lofNewsArray = this.lofNewsArray.map(e => e.id === updatedNews.id ? updatedNews : e);
      this._updateLofNewsWith(this.lofNewsArray);
      this.serviceNotif.success('Succès', "Votre actualité est modifiée !", {
        position: ['top', 'right'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
       });
      },
      error => {
        this.serviceNotif.error('Erreur', "Une erreur lors de la mise à jour de l'actualité. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    );
  }

  public deleteNews(lofNewsId: number): void {
    this.lofApiBackendService.remove(lofNewsId, this.headerOptions).subscribe(
      result => {
        this.lofNewsArray = this.lofNewsArray.filter(e => e.id !== lofNewsId);
        this._updateLofNewsWith(this.lofNewsArray);
      },
      error => {}
    );
  }


  public publishNews(lofNewsId: number, dataInfo: any): Observable<any> {
    return this.lofApiBackendService.publishNewsBackendService(lofNewsId, dataInfo, this.headerOptions);
  }
}
