
export class ContactData {
    name: string;
    phone: string;
    email: string;
    subject: string;
    message: string;

    constructor() {
      this.name = null;
      this.phone = null;
      this.email = null;
      this.subject = null;
      this.message = null;
    }
  }

  export class IsValidData {
    isValid: boolean;

    constructor() {
      this.isValid = null;
    }
  }

  export class RegistrationStat {
    association: number;
    member: number;
    coefficient: number;

    constructor() {
      this.association = null;
      this.member = null;
      this.coefficient = null;
    }
  }
