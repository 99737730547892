import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { FormDataEvent } from '@adherent_event/class/formDataEvent';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsService } from 'angular2-notifications';
import { FormGroup } from '@angular/forms';
import {FileEntry} from "@shared/components/file-upload/file-upload.component";
declare let $: any;

@Component({
  selector: "app-adherent-event-list",
  templateUrl: "./adherent-event-list.component.html",
  styleUrls: ["./adherent-event-list.component.css"],
})
export class AdherentEventListComponent implements OnInit {
  public imagePath;
  imgURL: any;
  public imagePathPicture;
  imgURLPicture: any;
  public searchText: any;
  public imagePathCardRectoFile;
  imgURLCardRectoFile: any;
  public imagePathCertificateFile;
  imgURLCertificateFile: any;
  public message: string;
  public messagePicture: string;
  public messageCardRectoFile: string;
  public messageCertificateFile: string;
  status: boolean = false;
  statusPicture: boolean = false;
  statusCardRectoFile: boolean = false;
  statusCertificateFile: boolean = false;
  linkGenerate: boolean = false;
  eventsData: any = [];
  isUpdatingEvents: boolean = false;
  isDataSend: boolean
  eventForm: FormGroup;
  infoEvent: any = [];
  assosLofId;
  p: number = 1;
  currentAsso: Object
  datas = null;
  public role;
  public detailId;
  owners = null;
  keyword: any
  memberSelected = []
  memberInfo = []
  allMember = []
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private eventFormDataSucribe: FormDataEvent,
    private serviceNotif: NotificationsService
  ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.getAllEvents();
  }

  generateLink(picName, action) {
    const dataBody = {
      "filename": picName,
      "action": action
    }
    this.contactService.viewEventSubmitService(dataBody).subscribe((res: any) => {
      window.open(res.link)
    })
  }

  deleteEvent(idMember) {
    this.contactService.deleteEventSubmitService(idMember).subscribe(
      (res: any) => {
        this.isDataSend = true
        this.serviceNotif.success("Succès", "Suppression réussie", {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      },
      error => {
        this.serviceNotif.error("Error", "Echec de suppression !", {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      })
  }

  getAllEvents() {
    this.contactService.getAllEventSubmitService().subscribe((res: any) => {
      this.eventsData = res;
    })
  }

  canTransfert() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            this.currentAsso = res.data
            if (res.data.status !== "ENABLED") {
              this.router.navigate(['search_adherents'])
            }
          }
        })
  }

  public getCurrentAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            this.contactService.getAssociationGroupService().subscribe((data: any) => {
              if (data) {
                let filterData = data.data.filter(assoName => assoName.name !== res.data.name)
                this.allMember = data.data
                filterData.forEach(element => {
                  if (element.status === "ENABLED") {
                    this.canTransfert()
                    this.assosLofId = element
                    this.memberSelected.push(element.name)
                  }
                });
              }
            })
          }
        }
      )
  }


  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

  }

  getAllMemberAssociation() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.detailId = id;
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.contactService.getAllMemberService().subscribe((res: any) => {
          if (res) {
            const isExist = res.data.find(e => parseInt(e.id) === parseInt(this.detailId))
            if (isExist === undefined) {
              this.router.navigate(['search_adherents'])
            }
            res.data.forEach((info) => {
              if (parseInt(info.id) === parseInt(this.detailId)) {
                const input = {
                  memberId: info.id,
                  dateOfDeath: null,
                  countryOfDeath: null,
                  cityOfDeath: null,
                  deathCertificateFile: null,
                  pictureFile: null,
                  idCardRectoFile: null,
                  idCardVersoFile: null,
                  livingCertificateFile: null,
                }
                this.eventFormDataSucribe.setEvent(input);
                this.datas = info;
                return this.datas;
              }
            });
          }
        })
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentAssociation()
        this.contactService
          .getMemberAssociationGroupService()
          .subscribe((res: any) => {
            if (res) {
              res.data.forEach((info) => {
                if (parseInt(info.id) === parseInt(this.detailId)) {
                  const input = {
                    memberId: info.id,
                    dateOfDeath: null,
                    countryOfDeath: null,
                    cityOfDeath: null,
                    deathCertificateFile: null,
                    pictureFile: null,
                    idCardRectoFile: null,
                    idCardVersoFile: null,
                    livingCertificateFile: null,
                  }
                  this.eventFormDataSucribe.setEvent(input);
                  this.datas = info;
                  return this.datas;
                }
              });
            }
          });
      }
    })
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    })
  }

  get f() { return this.eventForm.controls; }

  onFocused(e) {
  }

  onChangeSearch(e) {

  }

  selectEvent(e) {

  }

    onFileSelected(v: FileEntry) {
        const eventId = v.fieldId;
        const formData = new FormData();
        formData.append(v.fieldName, v.fileObject);
        this.contactService.updateEventFilesBackendService(formData, eventId).subscribe((res: any) => {
          console.log(res);
          if (res) {
            // FIXME: quick fix: reload events
            this.getAllEvents();
          }
        });
    }
}
