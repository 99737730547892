import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class AuthGuardService implements CanActivate{
    exist :boolean = true
    constructor(
      private router: Router,
      private serviceNotif: NotificationsService,
    ) {

    }
    canActivate(actRouteSnapShot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): boolean {
        if(localStorage.getItem('token')) {
          this.serviceNotif.success('Succès', "Vous êtes connecté !", {
            position: ['top', 'right'],
            timeOut: 2000,
            animate: 'fade',
            showProgressBar: true
          });
          return true;
        }
        this.serviceNotif.error('Info', "Cette page necessite une authentification !", {
          position: ['top', 'right'],
          timeOut: 2000,
          animate: 'fade',
          showProgressBar: true
        });
        this.router.navigate(['connexion'], { queryParams: { returnUrl: routerStateSnapshot.url }}); 
        return false;
    }
}