import { Component, OnInit } from '@angular/core';
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit {

  constructor(    
  ) { }

  ngOnInit() {
  
  }

}
