import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormDataService } from '@app/modules/contactez-nous/services/formDataService';
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';
import { ContactService } from '@contactez_nous/services/contact.service';
import { ApplicationUser } from '../../models/application-user';
import { Observable, Subscription } from 'rxjs';
declare let $ : any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public errorPage: any = false;
  connected: boolean; // for what
  isConnected: boolean; // for what
  isValid = false;
  public LangForm: FormGroup;
  public lang: "";
  public datas;
  logginObs$: Observable<ApplicationUser>;
  loginObserverSub: Subscription;
  constructor(
    public router: Router,
    private connectionService: ConnexionService,
    private contactService: ContactService,
    private formDataService: FormDataService
  ) {
    this.LangForm = new FormGroup({
      lang_pic: new FormControl("drapeau_français.png")
    });
  }
  ngOnDestroy(): void {
   if (this.loginObserverSub) {
    this.loginObserverSub.unsubscribe();
   };
  }

  changeLang() {
    this.lang = this.LangForm.get('lang_pic').value
  }

  getCallHttp() {
    this.contactService.getCallHttp().subscribe((data: any) => {
      if (data) {
        return data;
      }
    },
    error => {
      if (error.status !== 200) {
        this.router.navigate(['error_page'])
        this.errorPage = true;
      }
    })
  }

  _ngOnInit() {
    this.getCallHttp(); // For What??

    $('.navbar-collapse a').click(function(){
      $(".navbar-collapse").collapse('hide');
    });
    if (localStorage.getItem('token')) {
      this.connected = true;
      this.isConnected = true;
      this.getCurrentUser();
    } else {
      this.connected = false;
      this.isConnected = false;
    }
    this.changeLang();
    if (this.formDataService.getConfirmationPaiement().isValid === "valid") {
      this.isValid = true;
    }

  }

  ngOnInit() {
    $('.navbar-collapse a').click(function(){
      $(".navbar-collapse").collapse('hide');
    });

    this.logginObs$ = this.connectionService.userObservable$;
    this.loginObserverSub = this.logginObs$.subscribe(currentLoggedUser => {
      this.updateConnectionData(currentLoggedUser);
    });

    this.checkConnected();

    this.changeLang();
    if (this.formDataService.getConfirmationPaiement().isValid === "valid") {
      this.isValid = true;
    }
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any) => {
      if (!item.data) {
        this.connectionService.deconnexionUser();
        window.location.reload();
        this.router.navigate(['home'])
      } else {
        this.datas = item.data;
      }
    },
    error => {
      this.deconnexionUser()
    });
  }

  goToSpaceMember() {
    if (localStorage.getItem('token')) {
      this.connected = true;
      this.router.navigate(['home_member'])
    } else {
      this.connected = false;
    }
  }

  displayHomeMenu() {
    this.getCallHttp();
    this.connected = false;
  }

  deconnexionUser() {
    this.connected = !this.connected;
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  updateConnectionData(user: ApplicationUser): void {
    // later rev
    this.datas = user;
    if(this.datas){
      this.connected = this.connectionService.isConnected();
      this.isConnected =  this.connectionService.isConnected();
    } else {
      this.isConnected = false;
      this.isConnected =  false;
      //this.connectionService.deconnexionUser()
    }
  }

  checkConnected(): void {
    this.connected = this.connectionService.isConnected();
    this.isConnected = this.connectionService.isConnected();
    this.datas = this.connectionService.getApplicationUser();
  }
}
