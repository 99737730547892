import { Injectable } from '@angular/core';
import { ConnexionData } from '@connexion_update/class/connexion';
import { IConnexion } from '@connexion_update/class/connexion.interface';

@Injectable()
export class FormDataConnexion {
    private connexionData = new ConnexionData();

    public getConnexionData(): IConnexion {
        return {
            email: this.connexionData.email,
            currentEmail: this.connexionData.currentEmail,
            token: this.connexionData.token
        };
    }

    public setConnexion(input: IConnexion) {
        this.connexionData.email = input.email;
        this.connexionData.currentEmail = input.currentEmail;
        this.connexionData.token = input.token;
    }
}
