import { Injectable } from '@angular/core';
import { EventData } from '@adherent_event/class/EventData';
import { IEvent } from '@adherent_event/interface/event.interface';

@Injectable()
export class FormDataEvent {
    private eventData = new EventData();

    public getEvent(): IEvent {
        return {
            memberId: this.eventData.memberId,
            dateOfDeath: this.eventData.dateOfDeath,
            countryOfDeath: this.eventData.countryOfDeath,
            cityOfDeath: this.eventData.cityOfDeath,
            deathCertificateFile: this.eventData.deathCertificateFile,
            pictureFile: this.eventData.pictureFile,
            idCardRectoFile: this.eventData.idCardRectoFile,
            idCardVersoFile: this.eventData.idCardVersoFile,
            livingCertificateFile: this.eventData.livingCertificateFile
        };
    }

    public setEvent(input: IEvent) {
        this.eventData.memberId = input.memberId;
        this.eventData.dateOfDeath = input.dateOfDeath;
        this.eventData.countryOfDeath = input.countryOfDeath;
        this.eventData.cityOfDeath = input.cityOfDeath;
        this.eventData.deathCertificateFile = input.deathCertificateFile;
        this.eventData.pictureFile = input.pictureFile;
        this.eventData.idCardRectoFile = input.idCardRectoFile;
        this.eventData.idCardVersoFile = input.idCardVersoFile;
        this.eventData.livingCertificateFile = input.livingCertificateFile;
    }

    public clearDataEvent() {
        const clearEvent: IEvent = {
            memberId: null,
            dateOfDeath: null,
            countryOfDeath: null,
            cityOfDeath: null,
            deathCertificateFile: null,
            pictureFile : null,
            idCardRectoFile : null,
            idCardVersoFile : null,
            livingCertificateFile : null
        }
        this.setEvent(clearEvent);
    }

}
