import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { IConnexionRegister } from '../../interfaces/inscription.interface';
import { FormDataSubscribe } from '../../services/formDataSubscribe';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { MustMatch } from '../../../helpers/must-match.validators';
import { Router } from '@angular/router';
import {NotificationsService} from 'angular2-notifications'
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';
import { environment } from "@environments/environment";

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.css']
})
export class Step5Component implements OnInit {
  public siteKey = "6Lei_hYaAAAAALvSy3yz9QQ6vg2vml4Iud_xyMgC"
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';
  captcha = false
  fieldTextType: boolean;
  fieldTextType2: boolean;
  passwordPatern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?\-&])[A-Za-z\d@$!%*#?\-&]{8,20}$/;
  registerForm: FormGroup;
  public types = true;
  finishRegistration = {};
  random = 0;
  constructor(
    private formDataSucribe: FormDataSubscribe,
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private loginService: ConnexionService,
    private router: Router,
    private serviceNotif: NotificationsService,
  ) {
  }

  public registerFormBuilder(registerFormBuilderInput: IConnexionRegister): FormGroup {
    this.registerForm = this.formBuilder.group({
      identifiant: [registerFormBuilderInput.identifiant, Validators.required],
      password: [registerFormBuilderInput.password, [Validators.required, Validators.pattern(this.passwordPatern)]],
      confirmPassword: [registerFormBuilderInput.confirmPassword, [Validators.required, Validators.pattern(this.passwordPatern)]],
      recaptcha: environment.enableCaptcha? ['', Validators.required]: ['']
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    return this.registerForm
  }

  ngOnInit() {
    if(!this.formDataSucribe.getContact().nom1) {
      this.router.navigate(['inscription'])
    }
    this.random = this.getRandomNumberBetween(2020, 5000)
    this.registerForm = this.registerFormBuilder(this.formDataSucribe.getConnexionRegister());
    if (this.formDataSucribe.getChoix().type === "ASSOCIATION") {
      this.types = false;
    } else if (this.formDataSucribe.getChoix().type === "GROUP") {
    }

  }

  async handleSuccess(response:any) {
    await this.sendTokenToBackend(response);
  }

  sendTokenToBackend(token) {
    this.loginService.sendTokenRecaptchaService(token).subscribe(
      data => {
      },
      err => {
        console.log(err)
      },
      () => {}
    );
  }

  goToCaptcha() {
    this.captcha = true
  }

  handleReset() {

  }

  handleExpire() {
    this.captcha = false
  }

  handleLoad () {

  }


  public getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    this.checkIfAssociationExist()
  }

  public checkIfAssociationExist() {
    const dataBody = {
      "userEmail": this.registerForm.get("identifiant").value,
      "step": "3"
    }
    this.contactService.checkAssociationBackendService(dataBody).subscribe(
      (res:any) => {
        if (!res.exist) {
            const input: IConnexionRegister = {
              identifiant: this.registerForm.get('identifiant').value,
              password: this.registerForm.get('password').value,
              confirmPassword: this.registerForm.get('confirmPassword').value,
            };

            this.formDataSucribe.setConnexionRegister(input);

            if (this.formDataSucribe.getChoix().type === "ASSOCIATION") {
              this.types = false;
              this.finishRegistration =
              {
                "association": {
                  "name": this.formDataSucribe.getAssociation().nom,
                  "email": this.formDataSucribe.getAssociation().email,
                  "address": this.formDataSucribe.getAssociation().numVoie+" "+this.formDataSucribe.getAssociation().adresse,
                  "city": this.formDataSucribe.getAssociation().ville,
                  "postalCode": this.formDataSucribe.getAssociation().code,
                  "phone": this.formDataSucribe.getAssociation().telephone,
                  "department": this.formDataSucribe.getAssociation().department,
                  "type": this.formDataSucribe.getChoix().type,
                  "rnaNumber": this.formDataSucribe.getAssociation().identifiant
                },
                "owners": [
                  {
                    "lastName": this.formDataSucribe.getContact().nomG,
                    "firstName": this.formDataSucribe.getContact().prenomG,
                    "email": this.formDataSucribe.getContact().emailG,
                    "phone": this.formDataSucribe.getContact().mobileG,
                    "city": this.formDataSucribe.getContact().villeG,
                    "position": 1,
                    "function": this.formDataSucribe.getContact().statutG
                  },
                  {
                    "lastName": this.formDataSucribe.getContact().nom1,
                    "firstName": this.formDataSucribe.getContact().prenom1,
                    "email": this.formDataSucribe.getContact().email1,
                    "phone": this.formDataSucribe.getContact().mobile1,
                    "city": this.formDataSucribe.getContact().ville1,
                    "position": 2,
                    "function": this.formDataSucribe.getContact().statut1
                  },
                  {
                    "lastName": this.formDataSucribe.getContact().nom2,
                    "firstName": this.formDataSucribe.getContact().prenom2,
                    "email": this.formDataSucribe.getContact().email2,
                    "phone": this.formDataSucribe.getContact().mobile2,
                    "city": this.formDataSucribe.getContact().ville2,
                    "position": 3,
                    "function": this.formDataSucribe.getContact().statut2
                  }
                ],
                "payment": {
                  "reference": `PWT-44323-${this.random}`,
                  "amount": 100,
                  "provider": "HELLOASSO",
                  "status": "PENDING"
                },
                "credentials": {
                  "email": this.formDataSucribe.getConnexionRegister().identifiant,
                  "password": this.formDataSucribe.getConnexionRegister().password
                }
              };

            } else if (this.formDataSucribe.getChoix().type === "GROUP") {
              this.finishRegistration =
              {
                "association": {
                  "name": this.formDataSucribe.getGroupe().nom,
                  "email": this.formDataSucribe.getGroupe().email,
                  "address": this.formDataSucribe.getGroupe().numVoie+" "+this.formDataSucribe.getGroupe().adresse,
                  "city": this.formDataSucribe.getGroupe().ville,
                  "postalCode": this.formDataSucribe.getGroupe().code,
                  "phone": this.formDataSucribe.getGroupe().telephone,
                  "department": this.formDataSucribe.getGroupe().department,
                  "type": this.formDataSucribe.getChoix().type
                },
                "owners": [
                  {
                    "lastName": this.formDataSucribe.getContact().nomG,
                    "firstName": this.formDataSucribe.getContact().prenomG,
                    "email": this.formDataSucribe.getContact().emailG,
                    "phone": this.formDataSucribe.getContact().mobileG,
                    "city": this.formDataSucribe.getContact().villeG,
                    "position": 1,
                    "function": this.formDataSucribe.getContact().statutG
                  },
                  {
                    "lastName": this.formDataSucribe.getContact().nom1,
                    "firstName": this.formDataSucribe.getContact().prenom1,
                    "email": this.formDataSucribe.getContact().email1,
                    "phone": this.formDataSucribe.getContact().mobile1,
                    "city": this.formDataSucribe.getContact().ville1,
                    "position": 2,
                    "function": this.formDataSucribe.getContact().statut1
                  },
                  {
                    "lastName": this.formDataSucribe.getContact().nom2,
                    "firstName": this.formDataSucribe.getContact().prenom2,
                    "email": this.formDataSucribe.getContact().email2,
                    "phone": this.formDataSucribe.getContact().mobile2,
                    "city": this.formDataSucribe.getContact().ville2,
                    "position": 3,
                    "function": this.formDataSucribe.getContact().statut2
                  }
                ],
                "payment": {
                  "reference": `PWT-44323-${this.random}`,
                  "amount": 100,
                  "provider": "HELLOASSO",
                  "status": "PENDING"
                },
                "credentials": {
                  "email": this.formDataSucribe.getConnexionRegister().identifiant,
                  "password": this.formDataSucribe.getConnexionRegister().password
                }
              };
            }
            this.contactService.registerMemberService(this.finishRegistration).subscribe(
              res => {
                if (this.formDataSucribe.getChoix().type !== null) {
                  this.registerForm.reset();
                  this.formDataSucribe.clearDataAssociation()
                  this.formDataSucribe.clearDataContact()
                  this.formDataSucribe.clearDataGroupe()
                  this.formDataSucribe.clearConnexionRegister()
                  this.serviceNotif.success('Succès', "Enregistrement réussi !", {
                    position: ['top', 'right'],
                    timeOut: 2000,
                    animate: 'fade',
                    showProgressBar: true
                  });
                  setTimeout (() => {
                    this.router.navigate(["/home"]);          
                }, 2000);
                }
              },
              error => {
                this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
                  position: ['top', 'left'],
                  timeOut: 5000,
                  animate: 'fade',
                  showProgressBar: true
                });
                console.log("error send register -> ", error);
              }
            )
        } else {
          this.serviceNotif.error('Error', `Cet identifiant existe déjà dans notre base.`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      }  
    )
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  get f() { return this.registerForm.controls; }

  get identifiant() { return this.registerForm.get('identifiant'); }
  get password() { return this.registerForm.get('password'); }
  get confirmPassword() { return this.registerForm.get('confirmPassword'); }

}
