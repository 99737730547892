import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router'
import { ConnexionService } from '@connexion/services/connexion.service';
declare let $: any;

@Component({
  selector: 'app-import-etape2',
  templateUrl: './import-etape2.component.html',
  styleUrls: ['./import-etape2.component.css']
})
export class ImportEtape2Component implements OnInit {
  finishImport = false;
  public role;
  fd = null;
  fileExist = undefined;
  progression: number = 0;
  loading = false;
  public errorDataFile:any = [];
  currentUser: any;
  currentUserAssoLofId: string;
  constructor(
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    private connectionService: ConnexionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCurrentUser();
  }

  getFileUpload(e) {
    this.fd = new FormData();
    this.fd.append('file', e.target.files[0]);
    this.fd.append('withErrorData', true);
    this.fileExist = e.target.files[0];
    if (this.currentUserAssoLofId) { // LOF-201 needed for upload csv.
      const user = { lofId: this.currentUserAssoLofId };
      this.fd.append('user', user);
    }
  }

  deconnexion() {
    this.connectionService.deconnexionUser();
    window.location.reload();
    this.router.navigate(['home']);
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value;
      this.currentUser = item.data;
      if (item.data.role.value === "USER") {
        this.router.navigate(['home']);
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentMembersAssociation();
      }
    });
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            this.currentUserAssoLofId = res['data']['lofId'];
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              });
            }
          }
        }
      );
  }

  public importData(): void {
    if (this.fileExist !== undefined) {
      this.contactService.importMemberService(this.fd).subscribe(
        (res: any) => {
          console.log(res);
          switch (res.type) {
            case HttpEventType.Sent:
              this.loading = true;
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progression =  Math.round(res.loaded/res.total *100);
              console.log(`Uploaded! ${this.progression}%`);
              break;
            case HttpEventType.Response:
              console.log('User successfully created!', res.body);
              this.loading = false;
              this.finishImport = true;
              this.serviceNotif.success('Succès', `${res.body.total} adhérents crées !`, {
                position: ['top', 'right'],
                timeOut: 5000,
                animate: 'fade',
                showProgressBar: true
              });
          }
        },
        error => {
          this.finishImport = undefined;
          let errorMessage = "";
          switch (error.error.errors.code) {
            case "DUPLICATE_MEMBERS":
              $("#ModalError").click();
              $('#ModalError').modal({
                backdrop: 'static',
              });
              errorMessage = "Erreur Doublons existant";
              break;
            case "VALIDATION_ERROR":
              $("#ModalError").click();
              $('#ModalError').modal({
                backdrop: 'static',
              });
              errorMessage = "Erreur champs obligatoire non renseigné(s)";
              break;
            case "INVALID_MEMBER_COUNT":
              $("#ModalError").click();
              $('#ModalError').modal({
                backdrop: 'static',
              });
              errorMessage = "Nombre d'adhérents inferieur à 15";
              break;
            case "EXISTING_MEMBERS":
              $("#ModalError").click();
              $('#ModalError').modal({
                backdrop: 'static',
              });
              errorMessage = "Erreur des membres existent dans le système";
              break;
            default:
              errorMessage = "Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028";
              break;
          }
          this.serviceNotif.error('Error', `${errorMessage}`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
          this.errorDataFile = error.error.errors;
        }
      );
      this.fileExist = undefined;
    } else {
      this.serviceNotif.error('Error', `Fichier csv obligatoire`, {
        position: ['top', 'left'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      });
    window.location.reload();
    }
  }

}
