import { Component, OnInit } from "@angular/core";
import { IGroupe } from "@inscription/components/interfaces/inscription.interface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormDataSubscribe } from "@inscription/components/services/formDataSubscribe";
import { Router } from '@angular/router';
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { NotificationsService } from "angular2-notifications";

@Component({
  selector: "app-step1bis",
  templateUrl: "./step1bis.component.html",
  styleUrls: ["./step1bis.component.css"],
})
export class Step1bisComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  numberPatern = /^[1-9][0-9]*$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  zipCodePatern = /^[0-9]{5}(?:-[0-9]{5})?$/;
  groupeForm: FormGroup;
  groupeSend: boolean;
  keyword = "name";
  public adresseList = {
    list: [],
    focus: false
  }
  departements = [
    "Ain",
    "Aisne",
    "Allier",
    "Alpes-de-Haute-Provence",
    "Hautes-Alpes",
    "Alpes-Maritimes",
    "Ardèche",
    "Ardennes",
    "Ariège",
    "Aube",
    "Aude",
    "Aveyron",
    "Bouches-du-Rhône",
    "Calvados",
    "Cantal",
    "Charente",
    "Charente-Maritime",
    "Cher",
    "Corrèze",
    "Corse-du-Sud",
    "Haute-Corse",
    "Côte-d'Or",
    "Côtes d'Armor",
    "Creuse",
    "Dordogne",
    "Doubs",
    "Drôme",
    "Eure",
    "Eure-et-Loir",
    "Finistère",
    "Gard",
    "Haute-Garonne",
    "Gers",
    "Gironde",
    "Hérault",
    "Ille-et-Vilaine",
    "Indre",
    "Indre-et-Loire",
    "Isère",
    "Jura",
    "Landes",
    "Loir-et-Cher",
    "Loire",
    "Haute-Loire",
    "Loire-Atlantique",
    "Loiret",
    "Lot",
    "Lot-et-Garonne",
    "Lozère",
    "Maine-et-Loire",
    "Manche",
    "Marne",
    "Haute-Marne",
    "Mayenne",
    "Meurthe-et-Moselle",
    "Meuse",
    "Morbihan",
    "Moselle",
    "Nièvre",
    "Nord",
    "Oise",
    "Orne",
    "Pas-de-Calais",
    "Puy-de-Dôme",
    "Pyrénées-Atlantiques",
    "Hautes-Pyrénées",
    "Pyrénées-Orientales",
    "Bas-Rhin",
    "Haut-Rhin",
    "Rhône",
    "Haute-Saône",
    "Saône-et-Loire",
    "Sarthe",
    "Savoie",
    "Haute-Savoie",
    "Paris",
    "Seine-Maritime",
    "Seine-et-Marne",
    "Yvelines",
    "Deux-Sèvres",
    "Somme",
    "Tarn",
    "Tarn-et-Garonne",
    "Var",
    "Vaucluse",
    "Vandée",
    "Vienne",
    "Haute-Vienne",
    "Vosges",
    "Yonne",
    "Territoire de Belfort",
    "Essonne",
    "Hauts-de-Seine",
    "Seine-St-Denis",
    "Val-de-Marne",
    "Val-D'Oise",
  ];

  constructor(private formDataSucribe: FormDataSubscribe,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    public router: Router,
    ) {}

  public groupeFormBuiler(groupeFormDataInput: IGroupe): FormGroup {
    return new FormGroup({
      nom: new FormControl(groupeFormDataInput.nom, [Validators.required]),
      adresse: new FormControl(groupeFormDataInput.adresse, [
        Validators.required,
      ]),
      numVoie: new FormControl(groupeFormDataInput.numVoie, [
        Validators.required,
        Validators.pattern(this.numberPatern),
      ]),
      ville: new FormControl(groupeFormDataInput.ville, [Validators.required]),
      code: new FormControl(groupeFormDataInput.code, [
        Validators.required,
        Validators.pattern(this.zipCodePatern),
      ]),
      telephone: new FormControl(groupeFormDataInput.telephone, [
        Validators.required,
        Validators.pattern(this.phonePatern),
      ]),
      email: new FormControl(groupeFormDataInput.email, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      department: new FormControl(groupeFormDataInput.department, [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {    
    if(!this.formDataSucribe.getChoix().type) {
      this.router.navigate(['inscription'])
    }
    this.groupeForm = this.groupeFormBuiler(this.formDataSucribe.getGroupe());
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    this.checkIfAssociationExist()
  }

  public checkIfAssociationExist() {
    const dataBody = {
      "name": this.groupeForm.get("nom").value,
      "email": this.groupeForm.get("email").value,
      "rnaNumber": '',
      "phone": this.groupeForm.get("telephone").value,
      "step": "1"
    }
    this.contactService.checkAssociationBackendService(dataBody).subscribe(
      (res:any) => {
        if (!res.exist) {
          const input: IGroupe = {
            nom: this.groupeForm.get("nom").value,
            adresse:this.groupeForm.get("adresse").value,
            numVoie: this.groupeForm.get("numVoie").value,
            ville: this.groupeForm.get("ville").value,
            code: this.groupeForm.get("code").value,
            telephone: this.groupeForm.get("telephone").value,
            email: this.groupeForm.get("email").value,
            department: this.groupeForm.get("department").value,
          };
          this.formDataSucribe.setGroupe(input);
          this.groupeSend = true;
          this.router.navigate(['/inscription_etape_2']);
        } else {
          this.serviceNotif.error('Error', `Doublon d’une des informations suivantes : Nom, Email, Téléphone `, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      }  
    )
  }

  get f() {
    return this.groupeForm.controls;
  }

  get nom() {
    return this.groupeForm.get("nom");
  }
  get adresse() {
    return this.groupeForm.get("adresse");
  }
  get numVoie() {
    return this.groupeForm.get("numVoie");
  }
  get ville() {
    return this.groupeForm.get("ville");
  }
  get code() {
    return this.groupeForm.get("code");
  }
  get identifiant() {
    return this.groupeForm.get("identifiant");
  }
  get telephone() {
    return this.groupeForm.get("telephone");
  }
  get email() {
    return this.groupeForm.get("email");
  }
  get department() {
    return this.groupeForm.get("department");
  }

  public searchAdresseByEntry(e) {
    this.adresseList.list = [];
    if (e && e.length >= 3) {
      this.contactService.getCheckAdressApiService(e).subscribe((item: any) => {
        if (item) {
          item.features.forEach((addresse: any) => {
            this.adresseList.list.push(addresse.properties.label)
          });
        }
      })
    } else {
      this.adresseList.list = [];
    }
  }

  setSelectedAdressePlace(data) {
    if (data) {
      this.contactService.getCheckAdressApiService(data).subscribe((item: any) => {
        if (item) {
          item.features.forEach((addresse: any) => {
            if (addresse.properties.label === data) {
              this.groupeForm.controls['code'].setValue(addresse.properties.postcode);
              this.groupeForm.controls['ville'].setValue(addresse.properties.city);
              this.groupeForm.controls['department'].setValue(addresse.properties.context);
            }
          });
        }
      })
      this.groupeForm.controls['adresse'].setValue(data);
    }
  }

  get hasAtLeastOneAdresse() {
    return this.adresseList.focus && this.adresseList.list && this.adresseList.list.length > 0;
  }

  entryFocusAdresseAction() {
    this.adresseList.focus = true;
  }

  entryBlurAdresseAction() {
    this.adresseList.focus = false;
  }

  onFocused(e) {}

  onChangeSearch(e) {}

  selectEvent(e) {}
}
