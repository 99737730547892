import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";
import { Router } from "@angular/router";
import { IConnexion } from "@connexion_update/class/connexion.interface";
import { FormDataConnexion } from "@app/modules/connexion-update/class/formDataConnexion";

@Component({
  selector: 'app-apply-connexion-update',
  templateUrl: './apply-connexion-update.component.html',
  styleUrls: ['./apply-connexion-update.component.css']
})
export class ApplyConnexionUpdateComponent implements OnInit {
  conexionFormValid: FormGroup;
  passwordPatern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?\-&])[A-Za-z\d@$!%*#?\-&]{8,20}$/;
  currentEmail: string;
  newEmail: string;

  constructor(    
    private formDataConnexion: FormDataConnexion,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.conexionFormValid = this.conexionFormValideBuiler();
    if (this.formDataConnexion.getConnexionData().token === null) {
      this.router.navigate(['/update_connexion']);
    }
    this.newEmail =  this.formDataConnexion.getConnexionData().email
    this.currentEmail = this.formDataConnexion.getConnexionData().currentEmail
  }

  public conexionFormValideBuiler(): FormGroup {
    return new FormGroup({
      passe: new FormControl("", [
        Validators.required,
        Validators.pattern(this.passwordPatern),
      ])
    });
  }

  public validateSend(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    const password = this.conexionFormValid.get("passe").value
    const newEmail = this.formDataConnexion.getConnexionData().email 
    const oldEmail = this.formDataConnexion.getConnexionData().currentEmail 
    const token = this.formDataConnexion.getConnexionData().token 
    this.contactService.validateCurrentUsersEmailService(newEmail, oldEmail, token,  password).subscribe(
      (res:any) => {
        if (res.message === "Success") {
          this.serviceNotif.success("Succès", `Votre identifiant à été modifié`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
          this.conexionFormValid.get('passe').clearValidators();
          this.conexionFormValid.controls['passe'].setValue('');
        } else if(res.message !== "Success") {
          this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Cet identifiant existe déjà dans notre base`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    )
  }

  get f() {
    return this.conexionFormValid.controls;
  }

  get passe() {
    return this.conexionFormValid.get("passe");
  }
}
