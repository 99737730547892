import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateBirth",
})
export class DateBirthPipe implements PipeTransform {
  transform(value: any): any {
    const getAge = (birthDate) =>
      Math.floor(
        (new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e10
      );
    const age:any = getAge(value);
    return age;
  }
}

@Pipe({
  name: "dateBirthError",
})
export class DateBirthErrorPipe implements PipeTransform {
  transform(value: any): any {
    let checkAge = true
    const getAge = (birthDate) =>
      Math.floor(
        (new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e10
      );
    const age = getAge(value);
    if (age < 0) {
      return checkAge = false;
    }
    return checkAge;
  }
}

@Pipe({
  name: "status",
})
export class Status implements PipeTransform {
  transform(value: any): any {
    let status = "";
    if (typeof(value) === "string" && value.toUpperCase() === "ENABLED") {
      status = "Actif";
    } else if (typeof(value) === "string" && value.toUpperCase() === "DEMO") {
      status = "En approbation";
    } else if (typeof(value) === "string" && value.toUpperCase() === "DISABLED") {
      status = "Inactif";
    } else if (value === true) {
      status = "Actif";
    } else if (value === false) {
      status = "Inactif";
    }
    return status;
  }
}

@Pipe({
  name: "role",
})
export class RoleLof implements PipeTransform {
  transform(value: any): any {
    let role = "";
    if (value === 1) {
      role = "Gestionnaire";
    } else if (value === 2) {
      role = "2e contact";
    } else if (value === 3) {
      role = "3e contact";
    } else if (value === 0) {
      role = "Aucun";
    }
    return role;
  }
}

@Pipe({
  name: "yesno",
})
export class YesNo implements PipeTransform {
  transform(value: any): any {
    let status = "";
    if (value === true) {
      status = "Oui";
    } else {
      status = "Non";
    }
    return status;
  }
}

@Pipe({
  name: "poste",
})
export class FunctionAdherent implements PipeTransform {
  transform(value: any): any {
    let poste = "";
    if (value === "PRESIDENT") {
      poste = "Président(e)";
    } else if (value === "VICE_PRESIDENT") {
      poste = "Vice-président(e)";
    } else if (value === "TRESORIARY") {
      poste = "Trésorier(e)";
    } else if (value === "SECRETARY") {
      poste = "Secrétaire";
    } else if (value === "NONE") {
      poste = "Aucune";
    } else if (value === "OTHER") {
      poste = "Autre";
    }
    return poste;
  }
}

@Pipe({
  name: "carrence",
})
export class CarrenceAdherents implements PipeTransform {
  transform(value: any): any {
    let carrence = "";
    if (value === "CONTRIBUTOR") {
      carrence = "Contributeur";
    } else if (value === "BENEFICIARY") {
      carrence = "Bénéficiaire";
    } else if (value === "NOT_BENEFICIARY") {
      carrence = "Non bénéficiaire";
    } else if (value === "DEMO") {
      carrence = "En approbation";
    } else if (value === "PENDING") {
      carrence = "En attente";
    } else if (value === "APPROVED") {
      carrence = "Approuvé";
    } else if (value === "REJECTED") {
      carrence = "Refusé";
    }else if (value === "INACTIVE") {
      carrence = "Inactif";
    }
    return carrence;
  }
}

@Pipe({
  name: "typeMember",
})
export class TypeMembers implements PipeTransform {
  transform(value: any): any {
    let type = "";
    if (value === "ASSOCIATION") {
      type = "ASSOCIATION";
    } else if (value === "GROUP") {
      type = "GROUPE";
    }
    return type;
  }
}


@Pipe({
  name: "convertNumber",
})
export class ConvertNumber implements PipeTransform {
  transform(value: any): any {
    let type = value;
    if ((type % 1) != 0) {
      if (type.toString().split(".")[1].length < 2) {
        return type + '0'
      }
    }
    return type;
  }
}

@Pipe({
  name: "gender",
})
export class genderMember implements PipeTransform {
  transform(value: any): any {
    let type = "";
    if (value === "F") {
      type = "Feminin";
    } else if (value === "M") {
      type = "Masculin";
    }
    return type;
  }
}

