import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormDataService } from '../contactez-nous/services/formDataService';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router'
import { ConnexionService } from '@connexion/services/connexion.service';
import { NotificationsService } from 'angular2-notifications';
import { ModalDialogService } from 'ngx-modal-dialog';
import { EditNewsComponent } from '../shared/components/edit-news/edit-news.component';

declare let $: any;

@Component({
  selector: 'app-home-member-space',
  templateUrl: './home-member-space.component.html',
  styleUrls: ['./home-member-space.component.css']
})
export class HomeMemberSpaceComponent implements OnInit {
  isValid = "";
  members = null;
  public role;
  @ViewChild('newsModal', { read: ViewContainerRef } ) newsModalContainer!: ViewContainerRef;  

  constructor(
    private formDataService: FormDataService,
    private contactService: ContactService,
    private connectionService: ConnexionService,
    private serviceNotif: NotificationsService,
    private router: Router,
    private modalService: ModalDialogService
  ) { }

  ngOnInit() {
    this.isValid = this.formDataService.getConfirmationPaiement().isValid;
    this.getAllMemberAssociation()
    this.getCurrentUser()
  }

  goToInfo() {
    this.router.navigate(['group_asso_list', this.members])
  }

  getAllMemberAssociation() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentMembersAssociation()
      } 
    })
  }

  getBackupDatabase() {
    this.contactService.getBackupDatabase().subscribe((item: any) => {
      if (item.message === "OK") {
        this.serviceNotif.success('Succès', "Backup réussie !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      } else if (item.message !== "OK") {
        this.serviceNotif.error('Error', `Echec du backup`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      } 
    }, error => {
      this.serviceNotif.error('Error', `Echec du backup`, {
        position: ['top', 'left'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      });
      console.log(error)
    })
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    },
    error => {
      this.deconnexion()
    })
  }

  deconnexion() {
    this.connectionService.deconnexionUser();
    window.location.reload();
    this.router.navigate(['home']);
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
             this.members = res.data.lofId
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            return this.members
          }
        }
      )
  }

  public addNews (): void {
    this.modalService.openDialog(this.newsModalContainer, {
      childComponent: EditNewsComponent
    });
  }
}
