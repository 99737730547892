import { Component, OnInit } from '@angular/core';
import { ConnexionService } from '@connexion/services/connexion.service';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router'
declare let $: any;

@Component({
  selector: 'app-import-etape1',
  templateUrl: './import-etape1.component.html',
  styleUrls: ['./import-etape1.component.css']
})
export class ImportEtape1Component implements OnInit {
  public role;

  constructor(    
    private connectionService: ConnexionService,
    private contactService: ContactService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.getCurrentUser()
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
      if (item.data.role.value === "USER") {
        this.router.navigate(['home'])
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentMembersAssociation()
      } 
    })
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
          }
        }
      )
  }

}
