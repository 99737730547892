import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";
import { Router } from "@angular/router";
import { IConnexion } from "@connexion_update/class/connexion.interface";
import { FormDataConnexion } from "@app/modules/connexion-update/class/formDataConnexion";

@Component({
  selector: 'app-connexion-update',
  templateUrl: './connexion-update.component.html',
  styleUrls: ['./connexion-update.component.css']
})
export class ConnexionUpdateComponent implements OnInit {
  public userEmail:string
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  conexionFormUpdate: FormGroup;
  constructor(   
    private formDataConnexion: FormDataConnexion,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCurrentUser()
    this.conexionFormUpdate = this.conexionFormUpdateBuiler();
  }

  public conexionFormUpdateBuiler(): FormGroup {
    return new FormGroup({
      newEmail: new FormControl("", [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ])
    });
  }

  public getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe(
      (res:any) => {
          this.userEmail = res.data.email
      }
    )
  }

  public sendNewEmail(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const newEmail = this.conexionFormUpdate.get("newEmail").value
    this.contactService.updateCurrentUsersEmailService(newEmail, false).subscribe(
      (res:any) => {
        const input: IConnexion = {
          email: newEmail,
          currentEmail: this.userEmail,
          token: res.token
        };
        this.formDataConnexion.setConnexion(input);
        if (res.message) {
          this.serviceNotif.success("Succès", `Le code vous a déjà été envoyé, vérifiez votre boite mail !`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
          this.router.navigate(["check_update_connexion"]);
        } else if (res.token) {
          this.serviceNotif.success("Succès", `Un code a été envoyé dans votre boite mail !`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
          this.router.navigate(["check_update_connexion"]);
        }
      },
      error => {
        this.serviceNotif.error("Error", `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      })
  }
  
  get f() {
    return this.conexionFormUpdate.controls;
  }

  get newEmail() {
    return this.conexionFormUpdate.get("newEmail");
  }

}
