import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormDataSubscribe } from './services/formDataSubscribe';
import { IChoix } from '@inscription/components/interfaces/inscription.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {
  choixTypeForm: FormGroup;
  choixTypeSend: boolean;
  constructor(
    private formDataSucribe: FormDataSubscribe,
    private router: Router,    
  ) { }

  public typeFormBuiler(memberChoice: IChoix): FormGroup {
    return new FormGroup({
      'type': new FormControl(memberChoice.type, [Validators.required])});
  }

  ngOnInit() {
    this.choixTypeForm = this.typeFormBuiler(this.formDataSucribe.getChoix());
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

    const input: IChoix = {
      type: this.choixTypeForm.get('type').value,
    };

    this.formDataSucribe.setChoix(input);
    this.choixTypeSend = true;

    if (input.type === "ASSOCIATION") {
      this.router.navigate(['inscription_etape_1']);
    } else if (input.type === "GROUP") {
      this.router.navigate(['inscription_groupe']);
    }
  }

  get f() { return this.choixTypeForm.controls; }

  get type() { return this.choixTypeForm.get('type'); }

}
