import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAdhrents } from '../adherent-subscribe/interfaces/adherent.interface';
import { AdherentsFormDataSubscribe } from '../adherent-subscribe/services/AdherentsFormDataSubscribe';
import { ConnexionService } from '../connexion/services/connexion.service';
declare let $: any;

@Component({
  selector: "app-adherent-transfert-action",
  templateUrl: "./adherent-transfert-action.component.html",
  styleUrls: ["./adherent-transfert-action.component.css"],
})
export class AdherentTransfertActionComponent implements OnInit {
  isDataSend: boolean
  adherentForm: FormGroup;
  assosLofId;
  public toAssociation;
  datas = null;
  public role;
  public detailId;
  public user = localStorage.getItem("user");
  owners = null;
  keyword: any
  memberSelected = []
  memberInfo = []
  allMember = []
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private adherentsFormDataSucribe: AdherentsFormDataSubscribe,
    private connectionService: ConnexionService,
    private serviceNotif: NotificationsService
  ) { }

  public registerFormBuilder(adherendFormBuilderInput: IAdhrents): FormGroup {
    return new FormGroup({
      'lofId': new FormControl(''),
      'member': new FormControl('', Validators.required),
      'firstName': new FormControl('')
    })
  }

  ngOnInit() {
    this.getRoleUser()
    this.adherentForm = this.registerFormBuilder(this.adherentsFormDataSucribe.getAdherentsRegister())
    this.getCurrentAssociation()
  }

  public getTransfertRequest(lofCurrentId) {
    this.contactService.getTransfertRequestService().subscribe((res: any) => {
      if (res) {
        if (res.data.length > 0) {
          let ownTransfert = res.data.filter((item: any) => item.toAssociation.lofId === lofCurrentId && item.status === "PENDING")
          if (ownTransfert.length === 0) {
            this.router.navigate(['search_adherents'])
          } else {
            this.getTransfertInfos()
            this.adherentForm = this.registerFormBuilder(this.adherentsFormDataSucribe.getAdherentsRegister())
          }
        }
      }
    })
  }

  public getTransformStatus(value) {
      let carrence = "";
      if (value === "CONTRIBUTOR") {
        carrence = "Contributeur";
      } else if (value === "BENEFICIARY") {
        carrence = "Bénéficiaire";
      } else if (value === "NOT_BENEFICIARY") {
        carrence = "Non bénéficiaire";
      } else if (value === "DEMO") {
        carrence = "En approbation";
      } else if (value === "PENDING") {
        carrence = "En attente";
      } else if (value === "APPROVED") {
        carrence = "Approuvé";
      } else if (value === "REJECTED") {
        carrence = "Refusé";
      }else if (value === "INACTIVE") {
        carrence = "Inactif";
      }
      return carrence;
  }

  getRoleUser() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.role = "admin"
        this.getTransfertInfos()
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.role = "manager"
        this.getTransfertInfos()
      }
    })
  }

  public getCurrentAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            this.getTransfertRequest(res.data.lofId)
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            this.contactService.getAssociationGroupService().subscribe((data: any) => {
              if (data) {
                let filterData = data.data.filter(assoName => assoName.name !== res.data.name)
                this.allMember = data.data
                filterData.forEach(element => {
                  this.assosLofId = element
                  this.memberSelected.push(element.name)
                });
              }
            })
          }
        }
      )
  }



  getTransfertInfos() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.getTransfertRequestService().subscribe((res: any) => {
      if (res) {
        if (res.data.length > 0) {
          let ownTransfert = res.data.filter((item: any) => parseInt(item.id) === id)
          this.datas = ownTransfert[0].member
          this.toAssociation = ownTransfert[0].toAssociation
        }
      }
    })
  }

  public delete() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.contactService.deleteTransfertRequestService(id).subscribe((res: any) => {
      if (res) {
        this.isDataSend = true
        this.serviceNotif.success('Succès', "Votre décision à été prise en compte !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    },
      error => {
        this.serviceNotif.error('Erreur', "Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      })
    }

  public send(decision: string) {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    let body = {
      'status': decision
    }
    this.contactService.putTransfertRequestService(body, id).subscribe((res: any) => {
      if (res) {
        this.isDataSend = true
        this.serviceNotif.success('Succès', "Votre décision à été prise en compte !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    },
      error => {
        this.serviceNotif.error('Erreur', "Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      })
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  get f() { return this.adherentForm.controls; }
  get firstName() { return this.adherentForm.get('firstName'); }
  get lastName() { return this.adherentForm.get('lastName'); }
  get dateOfBirth() { return this.adherentForm.get('dateOfBirth'); }
  get position() { return this.adherentForm.get('position'); }
  get function() { return this.adherentForm.get('function'); }
  get department() { return this.adherentForm.get('department'); }

  onFocused(e) {
  }

  onChangeSearch(e) {

  }

  selectEvent(e) {

  }
}
