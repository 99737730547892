import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LofApiBackendService } from '@business/lof-backend/lof-api-backend.service';
import { IConexion, IAuthSecure } from '@connexion/interfaces/connexion.interface';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ApplicationUser } from '@app/modules/shared/models/application-user';
@Injectable({
  providedIn: 'root',
})
export class ConnexionService implements OnDestroy {
  public count = 0;
  public clear = null;
  private appUserSubject = new BehaviorSubject<ApplicationUser | null>(null);
  userObservable$ = this.appUserSubject.asObservable();

  constructor(
    private lofApiBackendService: LofApiBackendService,
    public router: Router,
  ) {
  }
  ngOnDestroy(): void {
    this.deconnexionUser();
  }

  public authenticateUser(input: IConexion): Observable<IAuthSecure> {
    return this.lofApiBackendService.loginBackendService(input).pipe(
      map(
        authData => {
          this.setLocalStorage(authData);
          const loginToken = authData.token;
          const loggedUser: ApplicationUser = this.getApplicationUserFromToken(loginToken);
          this.appUserSubject.next(loggedUser);
          return authData;
        }
      )
    );

  }

  public sendTokenRecaptchaService(token: any) {
    const tokenSend = {
      "token": token
    }
    return this.lofApiBackendService.sendTokenRecaptchaBackendService(tokenSend)
  }

  public deconnexionUser() {
    localStorage.removeItem("token")
    localStorage.removeItem("expiredIn")
    localStorage.removeItem("user")
    sessionStorage.clear()
  }

  public forgotPasswordService(data) {
    const email = {
      "email": data
    }
    return this.lofApiBackendService.forgotPasswordBackendService(email).pipe(tap(res => {
      return res
    }))
  }

  // hack
  isConnected(): boolean {
    // same as done in auth guard service
    return localStorage.getItem('token') !== null;
  }

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  private setLocalStorage(data) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("expiredIn", data.expiresIn);
    localStorage.setItem("user", sessionStorage.getItem("user"));
  }
  private getApplicationUserFromToken(loginToken: string): ApplicationUser {
    if (loginToken) {
      // 0: payload, 1: user, 2: sec key
      const tokenUserData = loginToken.split('.')[1];
      const atobUserData = atob(tokenUserData);
      const loggerUserData = JSON.parse(atobUserData);
      const loggedUser: ApplicationUser = {
        id: loggerUserData.id,
        firstname: loggerUserData.firstname,
        lastName: loggerUserData.lastName,
        role: loggerUserData.role,
        lofId: loggerUserData.lofId
      };
      return loggedUser;
    }
    return null;
  }

  getApplicationUser(): ApplicationUser {
    return this.getApplicationUserFromToken(this.getToken());
  }

}
