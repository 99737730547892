import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function IsNotCorrectValue(montant: string, cle: string) {
    return (formGroup: FormGroup) => {
        const montant1 = formGroup.controls[montant];
        const cle1 = formGroup.controls[cle];
        if (isNaN(parseFloat(montant1.value.replace(',','.'))) || isNaN(parseFloat(cle1.value.replace(',','.')))) {
            montant1.setErrors({ mustMatch: true })
            cle1.setErrors({ mustMatch: true })
        } else {
            montant1.setErrors(null)
            cle1.setErrors(null)
        }
    }
}