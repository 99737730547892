import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormDataSubscribe } from "../../services/formDataSubscribe";
import { IAssociation } from "../../interfaces/inscription.interface";
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";

@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.css"],
})
export class Step1Component implements OnInit {
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  numberPatern = /^[0-9]/;
  zipCodePatern = /^[0-9]{5}(?:-[0-9]{5})?$/;
  associationForm: FormGroup;
  associationSend: boolean;
  assoTitre = [];
  public associationList = {
    list: [],
    focus: false
  }
  public adresseList = {
    list: [],
    focus: false
  }
  nameApi = [];
  keyword = "item";
  keyword2 = "department";
  departements = [
    "Ain",
    "Aisne",
    "Allier",
    "Alpes-de-Haute-Provence",
    "Hautes-Alpes",
    "Alpes-Maritimes",
    "Ardèche",
    "Ardennes",
    "Ariège",
    "Aube",
    "Aude",
    "Aveyron",
    "Bouches-du-Rhône",
    "Calvados",
    "Cantal",
    "Charente",
    "Charente-Maritime",
    "Cher",
    "Corrèze",
    "Corse-du-Sud",
    "Haute-Corse",
    "Côte-d'Or",
    "Côtes d'Armor",
    "Creuse",
    "Dordogne",
    "Doubs",
    "Drôme",
    "Eure",
    "Eure-et-Loir",
    "Finistère",
    "Gard",
    "Haute-Garonne",
    "Gers",
    "Gironde",
    "Hérault",
    "Ille-et-Vilaine",
    "Indre",
    "Indre-et-Loire",
    "Isère",
    "Jura",
    "Landes",
    "Loir-et-Cher",
    "Loire",
    "Haute-Loire",
    "Loire-Atlantique",
    "Loiret",
    "Lot",
    "Lot-et-Garonne",
    "Lozère",
    "Maine-et-Loire",
    "Manche",
    "Marne",
    "Haute-Marne",
    "Mayenne",
    "Meurthe-et-Moselle",
    "Meuse",
    "Morbihan",
    "Moselle",
    "Nièvre",
    "Nord",
    "Oise",
    "Orne",
    "Pas-de-Calais",
    "Puy-de-Dôme",
    "Pyrénées-Atlantiques",
    "Hautes-Pyrénées",
    "Pyrénées-Orientales",
    "Bas-Rhin",
    "Haut-Rhin",
    "Rhône",
    "Haute-Saône",
    "Saône-et-Loire",
    "Sarthe",
    "Savoie",
    "Haute-Savoie",
    "Paris",
    "Seine-Maritime",
    "Seine-et-Marne",
    "Yvelines",
    "Deux-Sèvres",
    "Somme",
    "Tarn",
    "Tarn-et-Garonne",
    "Var",
    "Vaucluse",
    "Vandée",
    "Vienne",
    "Haute-Vienne",
    "Vosges",
    "Yonne",
    "Territoire de Belfort",
    "Essonne",
    "Hauts-de-Seine",
    "Seine-St-Denis",
    "Val-de-Marne",
    "Val-D'Oise",
  ];

  constructor(private formDataSucribe: FormDataSubscribe,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    public router: Router,
  ) { }

  public associationFormBuiler(
    associationFormDataInput: IAssociation
  ): FormGroup {
    return new FormGroup({
      nom: new FormControl(associationFormDataInput.nom, [Validators.required]),
      adresse: new FormControl(associationFormDataInput.adresse, [
        Validators.required,
      ]),
      numVoie: new FormControl(associationFormDataInput.numVoie, [
        Validators.required,
        Validators.pattern(this.numberPatern),
      ]),
      ville: new FormControl(associationFormDataInput.ville, [
        Validators.required,
      ]),
      code: new FormControl(associationFormDataInput.code, [
        Validators.required,
        Validators.pattern(this.zipCodePatern),
      ]),
      identifiant: new FormControl(associationFormDataInput.identifiant),
      telephone: new FormControl(associationFormDataInput.telephone, [
        Validators.required,
        Validators.pattern(this.phonePatern),
      ]),
      email: new FormControl(associationFormDataInput.email, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      department: new FormControl(associationFormDataInput.department, [
        Validators.required,
      ]),
    });
  }

  public checkIfAssociationExist() {
    const dataBody = {
      "name": this.associationForm.get("nom").value,
      "email": this.associationForm.get("email").value,
      "rnaNumber": this.associationForm.get("identifiant").value === null ? undefined:this.associationForm.get("identifiant").value,
      "phone": this.associationForm.get("telephone").value,
      "step": "1"
    }
    this.contactService.checkAssociationBackendService(dataBody).subscribe(
      (res:any) => {
        if (!res.exist) {
          const input: IAssociation = {
            nom: this.associationForm.get("nom").value,
            adresse:this.associationForm.get("adresse").value,
            numVoie: this.associationForm.get("numVoie").value,
            ville: this.associationForm.get("ville").value,
            code: this.associationForm.get("code").value,
            identifiant: this.associationForm.get("identifiant").value === null ? undefined:this.associationForm.get("identifiant").value,
            telephone: this.associationForm.get("telephone").value,
            email: this.associationForm.get("email").value,
            department: this.associationForm.get("department").value,
          };
          this.formDataSucribe.setAssociation(input);
          this.associationSend = true;
          this.router.navigate(['/inscription_etape_2']);
        } else {
          this.serviceNotif.error('Error', `Doublon d’une des informations suivantes : Nom, Email, Numéro RNA, Téléphone `, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      }  
    )
  }

  ngOnInit() {
    if (!this.formDataSucribe.getChoix().type) {
      this.router.navigate(['inscription'])
    }
   
    this.associationForm = this.associationFormBuiler(
      this.formDataSucribe.getAssociation()
    );
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    this.checkIfAssociationExist()
  }

  get f() {
    return this.associationForm.controls;
  }

  get nom() {
    return this.associationForm.get("nom");
  }
  get adresse() {
    return this.associationForm.get("adresse");
  }
  get numVoie() {
    return this.associationForm.get("numVoie");
  }
  get ville() {
    return this.associationForm.get("ville");
  }
  get code() {
    return this.associationForm.get("code");
  }
  get identifiant() {
    return this.associationForm.get("identifiant");
  }
  get telephone() {
    return this.associationForm.get("telephone");
  }
  get email() {
    return this.associationForm.get("email");
  }
  get department() {
    return this.associationForm.get("department");
  }

  get hasAtLeastOneAssociation() {
    return this.associationList.focus && this.associationList.list && this.associationList.list.length > 0;
  }
  get hasAtLeastOneAdresse() {
    return this.adresseList.focus && this.adresseList.list && this.adresseList.list.length > 0;
  }

  public searchByEntry(value: string) {
    if (value && value.length >= 3) {
      this.contactService.getCheckAssociationApiService(value).subscribe((item: any) => {
        if (item) {
          item.association.forEach((assos: any) => {
            this.associationList.list.push(assos.titre)
          });
        }
      })
    } else {
      this.associationList.list = [];
    }
  }
  
  public searchAdresseByEntry(e) {
    this.adresseList.list = [];
    if (e && e.length >= 3) {
      this.contactService.getCheckAdressApiService(e).subscribe((item: any) => {
        if (item) {
          item.features.forEach((addresse: any) => {
            this.adresseList.list.push(addresse.properties.label)
          });
        }
      })
    } else {
      this.adresseList.list = [];
    }
  }

  setSelectedItemPlace(data) {
    if (data) {
      this.associationForm.controls['nom'].setValue(data);
    }
  }

  setSelectedAdressePlace(data) {
    if (data) {
      this.contactService.getCheckAdressApiService(data).subscribe((item: any) => {
        if (item) {
          item.features.forEach((addresse: any) => {
            if (addresse.properties.label === data) {
              this.associationForm.controls['code'].setValue(addresse.properties.postcode);
              this.associationForm.controls['ville'].setValue(addresse.properties.city);
              this.associationForm.controls['department'].setValue(addresse.properties.context);
            }
          });
        }
      })
      this.associationForm.controls['adresse'].setValue(data);
    }
  }

  entryFocusAction() {
    this.associationList.focus = true;
  }

  entryBlurAction() {
    this.associationList.focus = false;
  }

  entryFocusAdresseAction() {
    this.adresseList.focus = true;
  }

  entryBlurAdresseAction() {
    this.adresseList.focus = false;
  }

  requestApiAssociation(e) {
    this.nameApi = [];
    if (e.length > 2) {
      this.contactService.getCheckAssociationApiService(e).subscribe((item: any) => {
        if (item) {
          item.association.forEach((assos: any) => {
            this.assoTitre.push(assos.titre)
            this.nameApi.push(assos.titre)
          });
        }
      })
    }
  }

  getUnique(array) {
    var uniqueArray = [];
    for (var i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  public checAssociationName(e) {
    if (typeof (e) === "object" && e.target.value) {
      let name
      this.nameApi.forEach(asso => {
        if (asso.titre) {
          name = asso.titre.toUpperCase()
        }
      })
      if (!name) {
        this.serviceNotif.info("Attention", `Il est prudent de choisir dans la liste !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      }
      if (name && this.nameApi.filter(asso => {
        asso.titre.toUpperCase() === e.target.value.toUpperCase()
      })) {
        this.serviceNotif.info("Attention", `Votre association n'est pas reconnue par les services administratif !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
        this.associationForm.controls.nom.setValue('')
      }
    } if (typeof (e) === "string") {
    }

  }

  onFocused(e) {
    this.requestApiAssociation(e)
  }

  onChangeSearch(e) {
    this.requestApiAssociation(e)
  }

  selectEvent(e) {
    this.checAssociationName(e)
  }
}
