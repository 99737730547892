import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router'
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';
@Component({
  selector: 'app-search-member',
  templateUrl: './search-member.component.html',
  styleUrls: ['./search-member.component.css']
})
export class SearchMemberComponent implements OnInit {
  datas = null;
  public searchText: any;
  p: number = 1;
  constructor(
    private contactService: ContactService,
    private router: Router,      
  ) { }

  ngOnInit() {
    this.getAssociationGroup()
    
  }

  public getAssociationGroup() {
    this.contactService.getAssociationGroupService().subscribe((data: any) => {
      if (data) {
        this.datas = data;

        return this.datas;
      }
    })
  }

  goToInfo(idMember) {
    this.router.navigate(['/group_asso_list', idMember])
  }
}
