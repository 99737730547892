import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FormDataService } from "@contactez_nous/services/formDataService";
import {
  IConexion,
} from "@connexion/interfaces/connexion.interface";
import { ConnexionService } from "../services/connexion.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { environment } from "@environments/environment";
declare let $ : any;

@Component({
  selector: "app-connexion",
  templateUrl: "./connexion.component.html",
  styleUrls: ["./connexion.component.css"],
})
export class ConnexionComponent implements OnInit {
  public siteKey = "6Lei_hYaAAAAALvSy3yz9QQ6vg2vml4Iud_xyMgC"
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';
  captcha = false
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  passwordPatern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#\-?&])[A-Za-z\d@$!%*#\-?&]{8,20}$/;
  conexionForm: FormGroup;
  resetPassWordForm: FormGroup;
  fieldTextType: boolean;
  conexionSend: boolean;
  errorReset = false;
  loading = false;
  data: [];
  errorEmail = false;
  displayErrorMsg: Boolean = false;
  displaySuccessMsg: Boolean = false;
  userInfo: IConexion;

  constructor(
    private loginService: ConnexionService,
    private formDataService: FormDataService,
    private serviceNotif: NotificationsService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

 async handleSuccess(response:any) {
    await this.sendTokenToBackend(response);
  }

  sendTokenToBackend(token) {
    this.loginService.sendTokenRecaptchaService(token).subscribe(
      data => {
      },
      err => {
        console.log(err)
      },
      () => {}
    );
  }

  goToCaptcha() {
    this.captcha = true
  }

  handleReset() {

  }

  handleExpire() {
    this.captcha = false
  }

  handleLoad () {

  }

  public conexionFormBuiler(conexionFormDataInput: IConexion): FormGroup {
    return new FormGroup({
      identifiant: new FormControl("", [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(this.passwordPatern),
      ]),
       recaptcha: environment.enableCaptcha? new FormControl('', Validators.required): new FormControl('')
    });
  }

  public resetPassFormBuiler(): FormGroup {
    return new FormGroup({
      identifiantReset: new FormControl("", [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ])
    });
  }

  ngOnInit() {
    if (localStorage.getItem("token")) {
      this.router.navigate(["home_member"]);
    }
    this.resetPassWordForm = this.resetPassFormBuiler()
    this.conexionForm = this.conexionFormBuiler(
      this.formDataService.getConexion()
    );
  }

  public authenticateUser(form: FormGroup): void {
    if (!form.valid) {
      return;
    }
  }

  public forgotPass(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.loading = true
    const email = this.resetPassWordForm.get("identifiantReset").value
    this.loginService.forgotPasswordService(email).subscribe((data: any) => {
      if (data) {
        this.loading = false
        this.errorEmail = false;
        this.errorReset = true
        this.serviceNotif.success("Succès", `Un lien vous a été envoyé dans votre boite mail !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      }
    },
      error => {
        this.loading = true
        this.errorReset = false;
        this.errorEmail = true;
        this.serviceNotif.error("Error", `Identifiant non reconnu !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      })
  }

  public submit(form: FormGroup): boolean {
    this.displayErrorMsg = false;
    this.displaySuccessMsg = false;
    if (!form.valid) {
      return;
    }

    const input: IConexion = {
      email: this.conexionForm.get("identifiant").value,
      password: this.conexionForm.get("password").value,
    };
    sessionStorage.setItem("user", input.email);
    this.loginService.authenticateUser(input).subscribe(
      (data) => {

        if (data) {

          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home_member';

          setTimeout(() => {
            this.router.navigateByUrl(returnUrl);
          }, 5000);
          this.serviceNotif.success("Succès", `Vous êtes connecté !`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
        }
      },
      (error) => {
        sessionStorage.removeItem("user");
        this.serviceNotif.error("Error", `Login ou mot de passe incorrect !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      }
    );

    this.formDataService.setConexion(input);
    this.conexionSend = true;
    this.formDataService.clearDataConexion();
    this.conexionForm.get("password").setValue("");
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get f() {
    return this.conexionForm.controls;
  }

  get identifiant() {
    return this.conexionForm.get("identifiant");
  }
  get password() {
    return this.conexionForm.get("password");
  }

  get identifiantReset() {
    return this.resetPassWordForm.get("identifiantReset");
  }
}
