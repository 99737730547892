import { Injectable } from '@angular/core';
import { LofApiBackendService } from '@business/lof-backend/lof-api-backend.service';
import { HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NotificationsService } from "angular2-notifications";

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  /*header = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
      .set('Authorization', `Bearer ${localStorage.getItem("token")}`)
  }*/
  constructor(
    private lofApiBackendService: LofApiBackendService,
    private serviceNotif: NotificationsService,
  ) { }

  get header(): any {
    const bearerToken = localStorage.getItem("token");
    return {
      headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
        .set('Authorization', `Bearer ${bearerToken}`)
    };
  }

  public sendMailService(input) {
    return this.lofApiBackendService.sendMailBackendService(input);
  }

  public registerMemberService(input) {
    return this.lofApiBackendService.registerBackendService(input);
  }

  public getAssociationGroupService() {
    return this.lofApiBackendService.getAssociationGroupBackendService().pipe(tap(res => {
      return res
    }))
  }

  public getAssociationEmailInfoService() {
    return this.lofApiBackendService.getAssociationEmailInfoService().pipe(tap(res => {
      return res
    }))
  }

  public getCallHttp() {
    return this.lofApiBackendService.getCallHttp().pipe(tap(res => {
      return res;
    }))
  }

  public getBackupDatabase() {
    return this.lofApiBackendService.getBackupDatabase(this.header).pipe(tap(res => {
      return res
    }))
  }

  public getOwnerAssociationGroupService(memberId) {
    return this.lofApiBackendService.getOwnerAssociationGroupBackendService(this.header, memberId).pipe(tap(res => {
      return res
    }))
  }

  public getMemberAssociationGroupService() {
    return this.lofApiBackendService.getMembersAssociationGroupBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public activeAssociationWithGroupService(lofId) {
    return this.lofApiBackendService.activeAssociationWithGroupBackendService(lofId, this.header);
  }

  public activeAssociationWithRequestBodyGroupService(lofId, bodyData) {
    return this.lofApiBackendService.activeAssociationWithRequestBodyGroupBackendService(lofId, bodyData, this.header);
  }

  public transfertMemberSubmitService(memberLofId, fromAssosLofId, toAssosLofId) {
   const dataBody = {
      "memberId": memberLofId,
      "fromAssociationId": fromAssosLofId,
      "toAssociationId": toAssosLofId
    }
    return this.lofApiBackendService.transfertMemberSubmitBackendService(dataBody, this.header)
  }

  public checkAssociationBackendService(dataBody) {
    return this.lofApiBackendService.checkAssociationBackendService(dataBody)
  }

  public contributionAssociationBackendService() {
    return this.lofApiBackendService.contributionAssociationBackendService(this.header)
  }

  public declareEventSubmitService(dataBody) {
    return this.lofApiBackendService.declareEventSubmitBackendService(dataBody, this.header)
  }

  public updateEventSubmitService(dataBody, idEvent) {
    return this.lofApiBackendService.updateEventSubmitBackendService(dataBody, idEvent, this.header)
  }
  public updateEventFilesBackendService(dataBody, idEvent) {
    return this.lofApiBackendService.updateEventFilesBackendService(dataBody, idEvent, this.header)
  }

  public deleteEventSubmitService(idMember) {
    return this.lofApiBackendService.deleteEventSubmitBackendService(idMember, this.header)
  }

  public getAllEventSubmitService() {
    return this.lofApiBackendService.getAllEventSubmitBackendService(this.header)
  }

  public viewEventSubmitService(dataBody) {
    return this.lofApiBackendService.viewEventSubmitBackendService(dataBody, this.header)
  }

  public desActiveAssociationGroupService(lofId) {
    return this.lofApiBackendService.desActiveAssociationGroupBackendService(lofId, this.header)
  }

  public getCheckAssociationApiService(assoName) {
    return this.lofApiBackendService.getCheckAssociationApiBackendService(assoName, this.header).pipe(tap(res => {
      return res
    }))
  }

  public getSettingGroupApiService() {
    return this.lofApiBackendService.getSettingGroupBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public addSettingsService(dataBody) {
    return this.lofApiBackendService.addSettingsBackendService(dataBody, this.header)
  }

  public getCheckAdressApiService(addresseName) {
    return this.lofApiBackendService.getCheckAdressApiBackendService(addresseName).pipe(tap(res => {
      return res
    }))
  }

  public getUserLofService(userId) {
    return this.lofApiBackendService.getUserLofBackendService(userId, this.header).pipe(tap(res => {
      return res
    }))
  }

  public getTransfertRequestService() {
    return this.lofApiBackendService.getTransfertRequestBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public putTransfertRequestService(body, transfertId) {
    return this.lofApiBackendService.putTransfertRequestBackendService(body, transfertId, this.header)
  }

  public deleteTransfertRequestService(transfertId) {
    return this.lofApiBackendService.deleteTransfertBackendService(transfertId, this.header)
  }

  public getCurrentMembersAssociationGroupService() {
    return this.lofApiBackendService.getCurrentMembersAssociationGroupBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public exportAssociationGroupService() {
    return this.lofApiBackendService.exportBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public exportPdfAssociationGroupService() {
    return this.lofApiBackendService.exportPdfBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public exportMemberService(infoLofConnect) {
    let bodySend = {
      "format": "table",
      "lofId": infoLofConnect
    }
    return this.lofApiBackendService.exportMemberBackendService(bodySend, this.header).pipe(tap(res => {
      return res
    }))
  }

  public exportPdfMemberService(infoLofConnect) {
    let bodySend = {
      "format": "pdf",
      "lofId": infoLofConnect
    }
    return this.lofApiBackendService.exportPdfMemberBackendService(bodySend, this.header).pipe(tap(res => {
      return res
    }))
  }

  public importMemberService(fileImport) {
    const header = { ...this.header, reportProgress: true, observe: 'events' };
    return this.lofApiBackendService.importBackendService(fileImport, header);
  }

  public updateMemberService(newData, lofId) {
    return this.lofApiBackendService.updateMemberBackendService(newData, lofId, this.header)
  }

  public updateAssociationService(newData, lofId) {
    return this.lofApiBackendService.updateAssociationBackendService(newData, lofId, this.header)
  }

  public getAllMemberService() {
    return this.lofApiBackendService.getAllMemberBackendService(this.header)
  }

  public getMemberAssoService(assoLofId) {
    return this.lofApiBackendService.getMemberAssoBackendService(assoLofId, this.header)
  }

  public deleteMemberService(memberLofId) {
    return this.lofApiBackendService.deleteMemberBackendService(memberLofId, this.header).pipe(tap(res => {
      return res
    }))
  }

  public deleteAssociationService(assoLofId) {
    return this.lofApiBackendService.deleteAssociationBackendService(assoLofId, this.header).pipe(tap(res => {
      return res
    }))
  }

  public getByAssociationService(assoLofId) {
    return this.lofApiBackendService.getAssociationBackendService(assoLofId, this.header).pipe(tap(res => {
      return res
    }))
  }

  public getUserService() {
    return this.lofApiBackendService.getUsersBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public getCurrentUserService() {
    return this.lofApiBackendService.getCurrentUsersBackendService(this.header).pipe(tap(res => {
      return res
    }))
  }

  public resetPasswordService(resetToken, newPassword) {
    let newCredentials = {
      "resetToken": resetToken,
      "newPassword": newPassword
    }
    return this.lofApiBackendService.resetPasswordBackendService(newCredentials).pipe(tap(res => {
      return res
    }))
  }

  public updateCurrentUsersEmailService(newEmail: any, resend:boolean) {
    let newConnexionEmail:Record<string, string | boolean> = {
      "newEmail": newEmail,
      "resend": resend
    }
    return this.lofApiBackendService.updateCurrentUsersEmailBackendService(newConnexionEmail, this.header).pipe(tap(res => {
      return res
    }))
  }

  public checkCurrentUsersEmailService(code: any, token:string) {
    let newCode:Record<string, string> = {
      "code": code,
      "token": token
    }
    return this.lofApiBackendService.checkCurrentUsersEmailBackendService(newCode, this.header).pipe(tap(res => {
      return res
    }))
  }

  public validateCurrentUsersEmailService(newEmail: any, oldEmail: any, token: any, password:string) {
    let newData:Record<string, string> = {
      "newEmail": newEmail,
      "oldEmail": oldEmail,
      "token": token,
      "password": password
    }
    return this.lofApiBackendService.validateCurrentUsersEmailBackendService(newData, this.header).pipe(tap(res => {
      return res
    }))
  }

  public addUserService(data) {
    return this.lofApiBackendService.addUserBackendService(data, this.header)
  }

  public updateUserService(data, lofId) {
    return this.lofApiBackendService.updateUserBackendService(data, lofId, this.header)
  }

  public deleteUserService(lofId) {
    return this.lofApiBackendService.deleteUserBackendService(lofId, this.header)
  }

  public checkIfSameMail(mailG:string, mail1:string, mail2:string): boolean {
    let checkSame:boolean = false;
    if (mailG === mail1) {
      this.serviceNotif.error('Error', `Les 3 e-mails du gestionnaire, du 2e contact et du 3e contact ne doivent pas être identiques`, {
        position: ["top", "left"],
        timeOut: 5000,
        animate: "fade",
        showProgressBar: true,
      });
      checkSame = true
    } else if (mailG === mail2) {
      this.serviceNotif.error('Error', `Les 3 e-mails du gestionnaire, du 2e contact et du 3e contact ne doivent pas être identiques`, {
        position: ["top", "left"],
        timeOut: 5000,
        animate: "fade",
        showProgressBar: true,
      });
      checkSame = true
    } else if (mail1 === mail2) {
      this.serviceNotif.error('Error', `Les 3 e-mails du gestionnaire, du 2e contact et du 3e contact ne doivent pas être identiques`, {
        position: ["top", "left"],
        timeOut: 5000,
        animate: "fade",
        showProgressBar: true,
      });
      checkSame = true
    }
    return checkSame;
  }

  public getMemberById(mbrId: string){
    return this.lofApiBackendService.getMemberByIdBackendService(mbrId, this.header);
  }

  public getUserLastEventById(memberId: string) {
    return this.lofApiBackendService.getUserLastEventBackendService(memberId, this.header);
  }

}
