import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paiement-contribution',
  templateUrl: './paiement-contribution.component.html',
  styleUrls: ['./paiement-contribution.component.css']
})
export class PaiementContributionComponent implements OnInit {  
  public memberFee = 0;
  constructor(
    private contactService: ContactService,
    private router: Router,    
  ) { }

  ngOnInit() {
    this.getCurrentUser()
    this.contributionAssociation()
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any)=> {
      if (item.data.role.value === "SUPER_ADMIN" || item.data.role.value === "LOF_ADMIN") {
        this.router.navigate(['home'])
      }
    })
  }

  public contributionAssociation() {
    this.contactService.contributionAssociationBackendService().subscribe(
      (res:any) => {
        this.memberFee = res.amount
      }
    )
  }

}
