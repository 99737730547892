import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";
import { Router } from "@angular/router";
import { IConnexion } from "@connexion_update/class/connexion.interface";
import { FormDataConnexion } from "@app/modules/connexion-update/class/formDataConnexion";
@Component({
  selector: 'app-check-connexion-update',
  templateUrl: './check-connexion-update.component.html',
  styleUrls: ['./check-connexion-update.component.css']
})
export class CheckConnexionUpdateComponent implements OnInit {
  codePattern = /^[0-9]{6}$/;
  conexionFormCheck: FormGroup;
  constructor(    
    private formDataConnexion: FormDataConnexion,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.conexionFormCheck = this.conexionFormUpdateCheckBuiler();
    if (this.formDataConnexion.getConnexionData().token === null) {
      this.router.navigate(['/update_connexion']);
    }
  }

  public conexionFormUpdateCheckBuiler(): FormGroup {
    return new FormGroup({
      code: new FormControl("", [
        Validators.required,
        Validators.pattern(this.codePattern),
      ])
    });
  }

  public resendSend() {
    const newEmail = this.formDataConnexion.getConnexionData().email 
    this.contactService.updateCurrentUsersEmailService(newEmail, true).subscribe(
      (res:any) => {
        const input: IConnexion = {
          email: newEmail,
          currentEmail: this.formDataConnexion.getConnexionData().currentEmail,
          token: res.token
        };
        this.formDataConnexion.setConnexion(input);
        if (res) {
          this.serviceNotif.success("Succès", `Le nouveau code vous a été envoyé, vérifiez votre boite mail !`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
        } 
      },
      error => {
        this.serviceNotif.error("Error", `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      })
  }

  public checkCodeSend(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const code = this.conexionFormCheck.get("code").value
    const token = this.formDataConnexion.getConnexionData().token 

    this.contactService.checkCurrentUsersEmailService(code, token).subscribe(
      (res:any) => {
        if (res.message === "Success") {
          this.serviceNotif.success("Succès", `Bravo, il ne reste plus qu'à vérifier votre profil`, {
            position: ["top", "left"],
            timeOut: 5000,
            animate: "fade",
            showProgressBar: true,
          });
          this.router.navigate(["apply_update_connexion"]);
        } else if(res.message !== "Success") {
          this.serviceNotif.error('Error', `Le code est incorrect`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    )
  }
    
  get f() {
    return this.conexionFormCheck.controls;
  }

  get code() {
    return this.conexionFormCheck.get("code");
  }

}
