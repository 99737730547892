import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './date.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [DateComponent],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatInputModule,
        MatIconModule
    ],
    exports: [
        DateComponent
    ]
})
export class DateModule { }
