import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { Router } from "@angular/router";
import { ConnexionService } from '@connexion/services/connexion.service';
declare let $: any;
@Component({
  selector: "app-search-adherents",
  templateUrl: "./search-adherents.component.html",
  styleUrls: ["./search-adherents.component.css"],
})
export class SearchAdherentsComponent implements OnInit {
  public isSelectAsso = false;
  public listAssoSelect = [];
  public role;
  public memberStatus=""
  public desactivateTrans = false
  public isCountOver = false;
  public isDataTransfertView: boolean;
  public transfertData: any
  public dataTransfertCount: any;
  public datas: any;
  public members: any;
  public searchText: any;
  public user = localStorage.getItem("user");
  p: number = 1;

  currentlofId: string;

  constructor(private contactService: ContactService, private router: Router,
    private connectionService: ConnexionService,
  ) { }

  ngOnInit() {
    this.getAllMemberAssociation();
    // this.getTransfertRequest();
  }

  getAllMemberAssociation() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.role = "admin";
        this.getAllMember();
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.role = "manager";
        this.getCurrentMembersAssociation();
        this.contactService
          .getMemberAssociationGroupService()
          .subscribe((res: any) => {
            if (res) {
              // LOF-159: only enabled members
              this.datas = res.data.filter(x => x.status === 'DEMO' || x.status === 'CONTRIBUTOR' || x.status === 'BENEFICIARY');
            }
          });
      }
    });
  }

  getExportMember(lofId) {
    this.contactService.exportMemberService(lofId).subscribe((data: any) => {
      if (data && data.url) {
        window.open(data.url, '_blank');
      }
    });
  }

  getExportPdfMember(lofId) {
    this.contactService.exportPdfMemberService(lofId).subscribe((data: any) => {
      if (data && data.url) {
        window.open(data.url, '_blank');
      }
    });
  }

  public getAllMember() {
    this.contactService.getAllMemberService().subscribe((res: any) => {
      if (res) {
        this.datas = res.data;
      }
    })
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  public goTolistTransfert() {
    this.isDataTransfertView = true;
  }

  public getTransfertRequest(lofCurrentId) {
    this.contactService.getTransfertRequestService().subscribe((res: any) => {
      if (res) {
        if (res.data.length > 0) {
         let ownTransfert = res.data.filter((item:any) => item.toAssociation.lofId === lofCurrentId && item.status === "PENDING")
         if (ownTransfert.length > 0) {
          this.isCountOver = true
         }
          this.dataTransfertCount = ownTransfert.length
          this.transfertData = ownTransfert
        }
      }
    })
  }

  public getCurrentMembersAssociation() {
    this.contactService
      .getCurrentMembersAssociationGroupService()
      .subscribe((res: any) => {
        if (res) {
          this.memberStatus = res.data.status;
          this.currentlofId = res.data.lofId;
          this.getTransfertRequest(res.data.lofId);
          if (res.data.status === "DEMO") {
            $("#exampleModalCenter_UNKNOW").click();
            $('#exampleModalCenter_UNKNOW').modal({
              backdrop: 'static',
              keyboard: false  // to prevent closing with Esc button (if you want this too)
            })
          }
        } else {
          this.currentlofId = null;
        }
      });
  }

  getUnique(array) {
    var uniqueArray = [];
    for (var i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }

  checAssociationName(e) {
    if (e.target.value.length >= 3) {
      this.listAssoSelect = []
      this.contactService.getAssociationGroupService().subscribe((data: any) => {
        if (data) {
          data.data.forEach(element => {
            this.listAssoSelect.push(element.name)
            this.listAssoSelect = this.getUnique(this.listAssoSelect)
          });
        }
      })
    }
  }

  onFocused(e) {
  }

  onChangeSearch(e) {
  }

  selectEvent(e) {
    this.contactService.getAssociationGroupService().subscribe((data: any) => {
      if (data) {
        let assos = data.data.filter(x => x.name === e)
        if (assos.length === 1) {
          this.contactService.getMemberAssoService(assos[0].lofId).subscribe((data: any) => {
            if (data) {
              this.currentlofId = assos[0].lofId;
              /*this.getExportPdfMember(assos[0].lofId);
              this.getExportMember(assos[0].lofId);*/
              this.isSelectAsso = true;
              this.datas = data.data;
            } else {
              this.currentlofId = null;
            }
          })
        }
      }
    })
  }

  goToInfo(idAherent) {
    this.router.navigate(["/adherent_details", idAherent]);
  }

  goToTransfertData(idAherent) {
    this.router.navigate(["/adherent_transfert", idAherent]);
  }

  goToDeclareEventData(idTransfert) {
    this.router.navigate(["/adherent_event", idTransfert]);
  }

  goToTransfertAction(idTransfert) {
    this.router.navigate(["/adherent_transfert_action", idTransfert]);
  }

  public handleXlsExportSelected(evt) {
    evt.preventDefault();
    if (this.currentlofId) {
      this.getExportMember(this.currentlofId);
    }

  }
  public handlePdfExportSelected(evt) {
    evt.preventDefault();
    if (this.currentlofId) {
      this.getExportPdfMember(this.currentlofId);
    }

  }
}
