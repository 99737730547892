import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateModule} from "@app/modules/controls/date/date.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
      DateModule
  ], exports: [
      DateModule
  ]
})
export class ControlsModule { }
