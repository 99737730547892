import { Component, OnInit } from "@angular/core";
import { FormDataSubscribe } from "../../services/formDataSubscribe";
import { IContact } from "@inscription/components/interfaces/inscription.interface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from "angular2-notifications";
@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.css"],
})
export class Step2Component implements OnInit {
  keyword = "name";
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  contactForm: FormGroup;
  contactSend: boolean;
  type = true;

  constructor(
    private contactService: ContactService,
    private formDataSucribe: FormDataSubscribe,
    private serviceNotif: NotificationsService,
    public router: Router,
    ) {}

  public contactFormBuiler(contactFormDataInput: IContact): FormGroup {
    return new FormGroup({
      nomG: new FormControl(contactFormDataInput.nomG, [Validators.required]),
      prenomG: new FormControl(contactFormDataInput.prenomG, [
        Validators.required,
      ]),
      statutG: new FormControl(contactFormDataInput.statutG, [
        Validators.required,
      ]),
      villeG: new FormControl(contactFormDataInput.villeG, [
        Validators.required,
      ]),
      mobileG: new FormControl(contactFormDataInput.mobileG, [
        Validators.required,
        Validators.pattern(this.phonePatern),
      ]),
      emailG: new FormControl(contactFormDataInput.emailG, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      nom1: new FormControl(contactFormDataInput.nom1, [Validators.required]),
      prenom1: new FormControl(contactFormDataInput.prenom1, [
        Validators.required,
      ]),
      statut1: new FormControl(contactFormDataInput.statut1, [
        Validators.required,
      ]),
      ville1: new FormControl(contactFormDataInput.ville1, [
        Validators.required,
      ]),
      mobile1: new FormControl(contactFormDataInput.mobile1, [
        Validators.required,
        Validators.pattern(this.phonePatern),
      ]),
      email1: new FormControl(contactFormDataInput.email1, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      nom2: new FormControl(contactFormDataInput.nom2, [Validators.required]),
      prenom2: new FormControl(contactFormDataInput.prenom2, [
        Validators.required,
      ]),
      statut2: new FormControl(contactFormDataInput.statut2, [
        Validators.required,
      ]),
      ville2: new FormControl(contactFormDataInput.ville2, [
        Validators.required,
      ]),
      mobile2: new FormControl(contactFormDataInput.mobile2, [
        Validators.required,
        Validators.pattern(this.phonePatern),
      ]),
      email2: new FormControl(contactFormDataInput.email2, [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      infosG: new FormControl(contactFormDataInput.infosG),
      confirmLecture: new FormControl(contactFormDataInput.confirmLecture, [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {  
    if(!this.formDataSucribe.getAssociation().department && !this.formDataSucribe.getGroupe().department) {
      this.router.navigate(['inscription'])
    }
   
    this.contactForm = this.contactFormBuiler(
      this.formDataSucribe.getContact()
    );

    if (this.formDataSucribe.getChoix().type === "ASSOCIATION") {
      this.type = false;
    } else if (this.formDataSucribe.getChoix().type === "GROUP") {
    }
  }

  public checkIfAssociationExist() {
    const dataBody = {
      "ownerEmails": [
        this.contactForm.get("emailG").value,
        this.contactForm.get("email1").value,
        this.contactForm.get("email2").value
      ],
      "step": "2"
    }

    if (!this.contactService.checkIfSameMail(dataBody.ownerEmails[0], dataBody.ownerEmails[1], dataBody.ownerEmails[2])) {
      this.contactService.checkAssociationBackendService(dataBody).subscribe(
        (res:any) => {
          if (!res.exist) {
            const input: IContact = {
              nomG: this.contactForm.get("nomG").value,
              prenomG: this.contactForm.get("prenomG").value,
              statutG: this.contactForm.get("statutG").value,
              villeG: this.contactForm.get("villeG").value,
              mobileG: this.contactForm.get("mobileG").value,
              emailG: this.contactForm.get("emailG").value,
        
              nom1: this.contactForm.get("nom1").value,
              prenom1: this.contactForm.get("prenom1").value,
              statut1: this.contactForm.get("statut1").value,
              ville1: this.contactForm.get("ville1").value,
              mobile1: this.contactForm.get("mobile1").value,
              email1: this.contactForm.get("email1").value,
        
              nom2: this.contactForm.get("nom2").value,
              prenom2: this.contactForm.get("prenom2").value,
              statut2: this.contactForm.get("statut2").value,
              ville2: this.contactForm.get("ville2").value,
              mobile2: this.contactForm.get("mobile2").value,
              email2: this.contactForm.get("email2").value,
              infosG: this.contactForm.get("infosG").value,
              confirmLecture: this.contactForm.get("confirmLecture").value,
            };
        
            this.formDataSucribe.setContact(input);
        
            this.contactSend = true;
            this.router.navigate(['/inscription_etape_5']);
          } else {
            this.serviceNotif.error('Error', `l'un des adresses e-mails existe déjà pour un contact dans notre base `, {
              position: ["top", "left"],
              timeOut: 5000,
              animate: "fade",
              showProgressBar: true,
            });
          }
        },
        error => {
          this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
          console.log("error send register -> ", error);
        }  
      )
    }
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    this.checkIfAssociationExist()

  }

  get f() {
    return this.contactForm.controls;
  }

  // Gestionaires
  get nomG() {
    return this.contactForm.get("nomG");
  }
  get prenomG() {
    return this.contactForm.get("prenomG");
  }
  get statutG() {
    return this.contactForm.get("statutG");
  }
  get villeG() {
    return this.contactForm.get("villeG");
  }
  get mobileG() {
    return this.contactForm.get("mobileG");
  }
  get emailG() {
    return this.contactForm.get("emailG");
  }

  // 2ieme Contact
  get nom1() {
    return this.contactForm.get("nom1");
  }
  get prenom1() {
    return this.contactForm.get("prenom1");
  }
  get statut1() {
    return this.contactForm.get("statut1");
  }
  get ville1() {
    return this.contactForm.get("ville1");
  }
  get mobile1() {
    return this.contactForm.get("mobile1");
  }
  get email1() {
    return this.contactForm.get("email1");
  }

  // 3ieme Contact
  get nom2() {
    return this.contactForm.get("nom2");
  }
  get prenom2() {
    return this.contactForm.get("prenom2");
  }
  get statut2() {
    return this.contactForm.get("statut2");
  }
  get ville2() {
    return this.contactForm.get("ville2");
  }
  get mobile2() {
    return this.contactForm.get("mobile2");
  }
  get email2() {
    return this.contactForm.get("email2");
  }

  get infosG() {
    return this.contactForm.get("infosG");
  }
  get confirmLecture() {
    return this.contactForm.get("confirmLecture");
  }
}
