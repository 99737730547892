import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAdhrents } from '../adherent-subscribe/interfaces/adherent.interface';
import { AdherentsFormDataSubscribe } from '../adherent-subscribe/services/AdherentsFormDataSubscribe';
import { ConnexionService } from '../connexion/services/connexion.service';
declare let $: any;

@Component({
  selector: "app-adherent-transfert",
  templateUrl: "./adherent-transfert.component.html",
  styleUrls: ["./adherent-transfert.component.css"],
})
export class AdherentTransfertComponent implements OnInit {
  isDataSend: boolean
  adherentForm: FormGroup;
  assosLofId;
  datas = null;
  public role;
  public detailId;
  public user = localStorage.getItem("user");
  owners = null;
  keyword: any
  memberSelected = []
  memberInfo = []
  allMember = []
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private adherentsFormDataSucribe: AdherentsFormDataSubscribe,
    private connectionService: ConnexionService,
    private serviceNotif: NotificationsService
  ) { }

  public registerFormBuilder(adherendFormBuilderInput: IAdhrents): FormGroup {
    return new FormGroup({
      'lofId': new FormControl('', Validators.required),
      'member': new FormControl('', Validators.required),
    })
  }

  ngOnInit() {
    this.canTransfert();
    this.adherentForm = this.registerFormBuilder(this.adherentsFormDataSucribe.getAdherentsRegister())
    this.getAllMemberAssociation();
    this.getCurrentUser()
  }

  canTransfert() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status !== "ENABLED") {
              this.router.navigate(['search_adherents'])
            }
          }
        })
  }

  public getCurrentAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            this.contactService.getAssociationGroupService().subscribe((data: any) => {
              if (data) {
                let filterData = data.data.filter(assoName => assoName.name !== res.data.name);

                this.allMember = data.data;
                filterData.sort((m,n) => m.name > n.name ? 1 : -1)
                    .forEach(element => {
                  if (element.status === "ENABLED") {
                    this.canTransfert();
                    this.assosLofId = element;
                    this.memberSelected.push(element.name);
                  }
                });
              }
            })
          }
        }
      )
  }

  public searchMember(assoName) {
    this.allMember.forEach(member => {
      if (member.name === assoName) {
        this.memberInfo = member
        this.adherentForm.controls.lofId.setValue(member.lofId)
      }
    })
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    let toAssosLofId = this.adherentForm.get("lofId").value
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          this.contactService.transfertMemberSubmitService(this.datas.lofId, res.data.lofId, toAssosLofId).subscribe((res: any) => {
            if (res) {
              this.isDataSend = true
              this.serviceNotif.success('Succès', "Votre demande à été bien envoyée !", {
                position: ['top', 'right'],
                timeOut: 5000,
                animate: 'fade',
                showProgressBar: true
              });
            }
          },
            error => {
              this.serviceNotif.error('Erreur', "Echec du transfert : veuillez contacter l'administrateur de LOF !", {
                position: ['top', 'right'],
                timeOut: 5000,
                animate: 'fade',
                showProgressBar: true
              });
            })
        })
  }

  deleteMember() {
    this.contactService.deleteMemberService(this.datas.lofId).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['search_adherents'])
      }
    },
      error => {
        this.serviceNotif.error('Error', "Echec de suppression !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      });
  }

  getAllMemberAssociation() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.detailId = id;
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.contactService.getAllMemberService().subscribe((res: any) => {
          if (res) {
            const isExist = res.data.find(e => parseInt(e.id) === parseInt(this.detailId))
            if (isExist === undefined) {
              this.router.navigate(['search_adherents'])
            }
            res.data.forEach((info) => {
              if (parseInt(info.id) === parseInt(this.detailId)) {
                this.datas = info;
                return this.datas;
              }
            });
          }
        })
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentAssociation()
        this.contactService
          .getMemberAssociationGroupService()
          .subscribe((res: any) => {
            if (res) {
              res.data.forEach((info) => {
                if (parseInt(info.id) === parseInt(this.detailId)) {
                  this.datas = info;
                  return this.datas;
                }
              });
            }
          });
      }
    });
  }

  public getAllMember() {
    this.contactService.getAllMemberService().subscribe((res: any) => {
      if (res) {
        this.datas = res.data;
      }
    })
  }


  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    })
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  get f() { return this.adherentForm.controls; }
  get firstName() { return this.adherentForm.get('firstName'); }
  get lastName() { return this.adherentForm.get('lastName'); }
  get dateOfBirth() { return this.adherentForm.get('dateOfBirth'); }
  get position() { return this.adherentForm.get('position'); }
  get function() { return this.adherentForm.get('function'); }
  get department() { return this.adherentForm.get('department'); }

  onFocused(e) {
  }

  onChangeSearch(e) {

  }

  selectEvent(e) {
    // TODO: remove this quick fix in next version
    this.searchMember(e);
  }
}
