import { Injectable } from '@angular/core';
import { IContact } from '@contactez_nous/interfaces/contact.interface';
import { ContactData, RegistrationStat, IsValidData } from '@contactez_nous/class/contact';
import { IConexion, IStat, IConfirmation } from '@connexion/interfaces/connexion.interface';
import { ConnexionData } from '@connexion/class/connexion';

@Injectable()
export class FormDataService {
    private contactFormData = new ContactData();
    private conexionFormData = new ConnexionData();
    private isValidData = new IsValidData();
    private registrationStat = new RegistrationStat();

    public getConexion(): IConexion {
        return {
            email: this.conexionFormData.email,
            password: this.conexionFormData.password
        }
    }

    public getStats() {
        return {
            association: this.registrationStat.association,
            member: this.registrationStat.member,
            coefficient: this.registrationStat.coefficient
        }
    }

    public setConexion(input: IConexion) {
        this.conexionFormData.email = input.email;
    }

    public setConfirmationPaiement(input: IConfirmation) {
        localStorage.setItem('isValid', input.isValid);
    }

    public getConfirmationPaiement(): IConfirmation {
        return {isValid: localStorage.getItem('isValid')}
    }

    public clearDataConexion() {
        const clearConexion: IConexion = {
            email: null,
            password: null
        }

        this.setConexion(clearConexion);
    }

    public getContact(): IContact {
        return {
            name: this.contactFormData.name,
            phone: this.contactFormData.phone,
            email: this.contactFormData.email,
            subject: this.contactFormData.subject,
            message: this.contactFormData.message
        }
    }

    public setContact(input: IContact) {
        this.contactFormData.name = input.name;
        this.contactFormData.email = input.email;
        this.contactFormData.phone = input.phone;
        this.contactFormData.subject = input.subject;
        this.contactFormData.message = input.message;
    }

    public clearDataContact() {
        const clearContact: IContact = {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        }

        this.setContact(clearContact);
    }
}

