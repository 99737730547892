export const HOME = 'home';
export const NAVBAR = 'nav';
export const CONNEXION = 'connexion';
export const FOOTER = 'footer';
export const ACTUALITES = 'actualites';
export const FAQ = 'faq';
export const CONNEXION_ID = 'connexion_id';
export const CONTRIBUTION_PARAM = 'contribution_param';
export const CONNEXION_UPDATE = 'update_connexion';
export const CHECK_CONNEXION_UPDATE = 'check_update_connexion';
export const APPLY_CONNEXION_UPDATE = 'apply_update_connexion';
export const ADHERENT_DETAILS = 'adherent_details';
export const ADHERENT_TRANSFERT = 'adherent_transfert';
export const ADHERENT_EVENT = 'adherent_event';
export const ADHERENT_EVENT_LIST = 'adherent_event_list';
export const ADHERENT_TRANSFERT_ACTION = 'adherent_transfert_action';
export const ADHERENT_TRANSFERT_LIST = 'adherent_transfert_list';
export const IMPORT_ETAPE1 = 'import_etape1';
export const RESET_PASSWORD = 'reset-password';
export const IMPORT_ETAPE2 = 'import_etape2';
export const IMPORT_ETAPE3 = 'import_etape3';
export const IMPORT_RESULT = 'import_result';
export const SEARCH_MEMBER = 'search_member';
export const ASSOCIATION_GROUP_LIST_COMPONENT = 'group_asso_list';
export const SEARCH_ADHERENTS = 'search_adherents';
export const PAIEMENT = 'paiement_UNKNOWN-LINK';
export const DETAIL_MEMBRE = 'detail_membre';
export const CONFIRM_DELETE = 'confirm_delete';
export const ADHERENT_SUBSCRIBE = 'adherent_subscribe';
export const HOME_MEMBER = 'home_member';
export const INSCRIPTION = 'inscription';
export const MANAGE_MEMBER = 'gestion_des_utilisateurs';
export const UPDATE_MEMBER = 'gestion_des_utilisateurs_modifier';
export const STEP1 = 'inscription_etape_1';
export const STEP1BIS = 'inscription_groupe';
export const STEP2 = 'inscription_etape_2';
export const STEP3 = 'inscription_etape_3';
export const STEP4 = 'inscription_etape_4';
export const STEP5 = 'inscription_etape_5';
export const STEP6 = 'inscription_etape_6';
export const CONTRIBUTION_PAIEMENT = 'paiement_contribution';
export const CONFIRMATION_PAIEMENT = 'confirm_paiement';
export const MARKETPLACE = 'marketplace_UNKNOWN-LINK';
export const CONTACTEZ_NOUS = 'contactez_nous';
export const INFO_PERSO = 'info_perso';
export const ABOUT_US = 'qui_nous_sommes';
export const WHITE_PAGE = 'error_page';
export const NOS_SERVICES = 'nos_services';
export const EMPTY_PATH = '';
export const SLASH_EMPTY_PATH = '/';
export const ALL = '**';




