import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppComponent } from './app.component';
import { AboutUsComponent } from '@about/components/about-us.component';
import { WhitePageComponent } from '@white_page/components/white-page.component';
import { ResetPasswordComponent } from '@reset_password/components/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HttpClientModule } from '@angular/common/http';
import {
  HOME, STEP1, STEP2, STEP5, IMPORT_ETAPE2, IMPORT_ETAPE3,
  ABOUT_US, STEP3, STEP4, STEP6, IMPORT_ETAPE1, SEARCH_ADHERENTS, WHITE_PAGE, CONTRIBUTION_PAIEMENT,
  NAVBAR, FOOTER, CONNEXION, ADHERENT_DETAILS, STEP1BIS, INFO_PERSO, UPDATE_MEMBER, ADHERENT_TRANSFERT_ACTION,
  ADHERENT_TRANSFERT_LIST, NOS_SERVICES, ACTUALITES, CONFIRM_DELETE, SEARCH_MEMBER, CONFIRMATION_PAIEMENT, ADHERENT_TRANSFERT,
  MARKETPLACE, INSCRIPTION, HOME_MEMBER, ADHERENT_SUBSCRIBE, RESET_PASSWORD, MANAGE_MEMBER, ADHERENT_EVENT, ADHERENT_EVENT_LIST,
  CONTACTEZ_NOUS, CONNEXION_UPDATE, CHECK_CONNEXION_UPDATE, CONTRIBUTION_PARAM, APPLY_CONNEXION_UPDATE, FAQ, CONNEXION_ID, PAIEMENT, DETAIL_MEMBRE, IMPORT_RESULT, ASSOCIATION_GROUP_LIST_COMPONENT,
  ALL
} from '@shared/constants/url-paths/url-paths';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from '@home/components/home/home.component';
import { NosServicesComponent } from '@nos_services/components/nos-services.component';
import { ContactezNousComponent } from '@contactez_nous/components/contactez-nous.component';
import { ActualiteComponent } from '@actualites/components/actualite.component';
import { MarketplaceComponent } from '@marketplace/components/marketplace.component';
import { FaqComponent } from '@faq/components/faq.component';
import { ConnexionIdComponent } from '@connexion_id/components/connexion-id.component';
import { ContributionParamComponent } from '@contribution_param/components/contribution-param.component';
import { ConnexionUpdateComponent } from '@connexion_update/components/connexion-update.component';
import { CheckConnexionUpdateComponent } from '@check_connexion_update/components/check-connexion-update.component';
import { ApplyConnexionUpdateComponent } from '@apply_connexion_update/components/apply-connexion-update.component';
import { InscriptionComponent } from '@inscription/components/inscription.component';
import { Step1Component } from '@step1/components/step1.component';
import { Step2Component } from '@step2/components/step2.component';
import { Step3Component } from '@step3/components/step3.component';
import { Step4Component } from '@step4/components/step4.component';
import { ConnexionComponent } from '@connexion/components/connexion.component';
import { Step5Component } from '@step5/components/step5.component';
import { Step6Component } from '@step6/components/step6.component';

import { FooterComponent } from '@shared/components/footer/footer.component';
import { NavbarComponent } from '@shared/components/navbar/navbar.component';
import { PaiementComponent } from '@paiement/components/paiement.component';
import { FormDataService } from '@contactez_nous/services/formDataService';
import { AdherentsService } from '@adherent_subscribe/services/adherents.service';
import { HomeMemberSpaceComponent } from '@member_space/home-member-space.component';
import { MemberDetailComponent } from '@member_detail/member-detail.component';
import { ConfirmDeleteComponent } from '@confirm_delete/confirm-delete.component';
import { AdherentSubscribeComponent } from '@adherent_subscribe/adherent-subscribe.component';
import { AdherentDetailsComponent } from '@adherent_details/adherent-details.component';
import { AdherentTransfertComponent } from '@adherent_transfert/adherent-transfert.component';
import { AdherentEventComponent } from '@adherent_event/adherent-event.component';
import { AdherentEventListComponent } from '@adherent_event_list/adherent-event-list.component';
import { AdherentTransfertActionComponent } from '@adherent_transfert_action/adherent-transfert-action.component';
import { ImportEtape1Component } from '@import_etape1/import-etape1.component';
import { ImportEtape2Component } from '@import_etape2/import-etape2.component';
import { ImportEtape3Component } from '@import_etape3/import-etape3.component';
import { ImportResultComponent } from '@import_result/import-result.component';
import { SearchMemberComponent } from '@search_member/search-member.component';
import { MemberManageComponent } from '@app/modules/manage-member/components/add-manager/member-manage.component';
import { UpdateManageComponent } from '@app/modules/manage-member/components/update-manager/update-manage.component';
import { AssociationGroupListComponent } from '@group_asso_list/association-group-list.component';
import { SearchAdherentsComponent } from '@search_adherents/search-adherents.component';
import { FormDataSubscribe } from '@inscription/components/services/formDataSubscribe';
import { FormDataConnexion } from '@connexion_update/class/formDataConnexion';
import { FormDataEvent } from '@adherent_event/class/formDataEvent';
import { AdherentsFormDataSubscribe } from '@adherent_subscribe/services/AdherentsFormDataSubscribe';
import { Step1bisComponent } from '@step1bis/step1bis.component';
import { ContactService } from '@contactez_nous/services/contact.service';
import { LofApiBackendService } from '@business/lof-backend/lof-api-backend.service';
import { InfoPersoComponent } from '@info_perso/info-perso.component';
import { ConfirmPaiementComponent } from '@confirm_paiement/confirm-paiement.component';
import { PaiementContributionComponent } from '@paiement_contribution/paiement-contribution.component';
import { HomeService } from '@home/services/home.service';
import { AuthGuardService } from '@connexion/services/auth-guard.service';
import {
  DateBirthPipe,
  DateBirthErrorPipe,
  RoleLof,
  Status,
  YesNo,
  FunctionAdherent,
  CarrenceAdherents,
  TypeMembers,
  ConvertNumber,
  genderMember,
} from './date-birth.pipe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AdherentTransfertListComponent } from './modules/adherent-transfert-list/adherent-transfert-list.component';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { EditNewsComponent } from './modules/shared/components/edit-news/edit-news.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ImageToDataUrlModule } from 'ngx-image2dataurl';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FileUploadComponent } from './modules/shared/components/file-upload/file-upload.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {ControlsModule} from "@app/modules/controls/controls.module";
const appRoutes: Routes = [
  {
    path: HOME_MEMBER,
    component: HomeMemberSpaceComponent, canActivate: [AuthGuardService]
  },
  {
    path: ABOUT_US,
    component: AboutUsComponent
  },
  {
    path: WHITE_PAGE,
    component: WhitePageComponent
  },
  {
    path: RESET_PASSWORD + '/:id',
    component: ResetPasswordComponent
  },
  {
    path: INFO_PERSO,
    component: InfoPersoComponent, canActivate: [AuthGuardService]
  },
  {
    path: MANAGE_MEMBER,
    component: MemberManageComponent, canActivate: [AuthGuardService]
  },
  {
    path: UPDATE_MEMBER + '/:id',
    component: UpdateManageComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONFIRMATION_PAIEMENT,
    component: ConfirmPaiementComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONTRIBUTION_PAIEMENT,
    component: PaiementContributionComponent, canActivate: [AuthGuardService]
  },
  {
    path: STEP1BIS,
    component: Step1bisComponent
  },
  {
    path: IMPORT_ETAPE1,
    component: ImportEtape1Component, canActivate: [AuthGuardService]
  },
  {
    path: IMPORT_ETAPE2,
    component: ImportEtape2Component, canActivate: [AuthGuardService]
  },
  {
    path: IMPORT_ETAPE3,
    component: ImportEtape3Component, canActivate: [AuthGuardService]
  },
  {
    path: IMPORT_RESULT,
    component: ImportResultComponent, canActivate: [AuthGuardService]
  },
  {
    path: SEARCH_MEMBER,
    component: SearchMemberComponent, canActivate: [AuthGuardService]
  },
  {
    path: ASSOCIATION_GROUP_LIST_COMPONENT,
    component: AssociationGroupListComponent
  },
  {
    path: ASSOCIATION_GROUP_LIST_COMPONENT + '/:id',
    component: MemberDetailComponent, canActivate: [AuthGuardService]
  },
  {
    path: SEARCH_ADHERENTS,
    component: SearchAdherentsComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_DETAILS + '/:id',
    component: AdherentDetailsComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_TRANSFERT + '/:id',
    component: AdherentTransfertComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_EVENT + '/:id',
    component: AdherentEventComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_EVENT_LIST,
    component: AdherentEventListComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_TRANSFERT_ACTION + '/:id',
    component: AdherentTransfertActionComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_TRANSFERT_LIST,
    component: AdherentTransfertListComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONFIRM_DELETE,
    component: ConfirmDeleteComponent, canActivate: [AuthGuardService]
  },
  {
    path: ADHERENT_SUBSCRIBE,
    component: AdherentSubscribeComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONNEXION,
    component: ConnexionComponent
  },
  {
    path: STEP1,
    component: Step1Component
  },
  {
    path: STEP2,
    component: Step2Component
  },
  {
    path: STEP3,
    component: Step3Component
  },
  {
    path: STEP4,
    component: Step4Component
  },
  {
    path: STEP5,
    component: Step5Component
  },
  {
    path: STEP6,
    component: Step6Component
  },
  {
    path: CONTACTEZ_NOUS,
    component: ContactezNousComponent
  },
  {
    path: INSCRIPTION,
    component: InscriptionComponent
  },
  {
    path: FAQ,
    component: FaqComponent
  },
  {
    path: CONNEXION_ID,
    component: ConnexionIdComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONTRIBUTION_PARAM,
    component: ContributionParamComponent, canActivate: [AuthGuardService]
  },
  {
    path: CONNEXION_UPDATE,
    component: ConnexionUpdateComponent, canActivate: [AuthGuardService]
  },
  {
    path: CHECK_CONNEXION_UPDATE,
    component: CheckConnexionUpdateComponent, canActivate: [AuthGuardService]
  },
  {
    path: APPLY_CONNEXION_UPDATE,
    component: ApplyConnexionUpdateComponent, canActivate: [AuthGuardService]
  },
  {
    path: PAIEMENT,
    component: PaiementComponent
  },
  {
    path: NOS_SERVICES,
    component: NosServicesComponent
  },
  {
    path: ACTUALITES,
    component: ActualiteComponent
  },
  {
    path: MARKETPLACE,
    component: MarketplaceComponent
  },
  {
    path: NAVBAR,
    component: NavbarComponent
  },
  {
    path: FOOTER,
    component: FooterComponent
  },
  {
    path: ALL,
    component: HomeComponent
  },
  {
    path: HOME,
    component: HomeComponent
  }
];

const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: {day: 'numeric', month: 'numeric', year: 'numeric'},
  },
  display: {
    //dateInput: {day: 'numeric', month: 'short', year: 'numeric'},
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: { year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    WhitePageComponent,
    ResetPasswordComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    NosServicesComponent,
    ActualiteComponent,
    MarketplaceComponent,
    FaqComponent,
    ConnexionUpdateComponent,
    CheckConnexionUpdateComponent,
    ApplyConnexionUpdateComponent,
    ConnexionIdComponent,
    ContributionParamComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    AdherentTransfertComponent,
    AdherentEventComponent,
    AdherentEventListComponent,
    AdherentTransfertActionComponent,
    ContactezNousComponent,
    InscriptionComponent,
    ConnexionComponent,
    Step5Component,
    Step6Component,
    PaiementComponent,
    HomeMemberSpaceComponent,
    MemberDetailComponent,
    ConfirmDeleteComponent,
    AdherentSubscribeComponent,
    AdherentDetailsComponent,
    ImportEtape1Component,
    ImportEtape2Component,
    ImportEtape3Component,
    ImportResultComponent,
    SearchMemberComponent,
    SearchAdherentsComponent,
    Step1bisComponent,
    InfoPersoComponent,
    AssociationGroupListComponent,
    ConfirmPaiementComponent,
    DateBirthPipe,
    RoleLof,
    DateBirthErrorPipe,
    Status,
    YesNo,
    FunctionAdherent,
    CarrenceAdherents,
    TypeMembers,
    ConvertNumber,
    genderMember,
    MemberManageComponent,
    UpdateManageComponent,
    AdherentTransfertListComponent,
    PaiementContributionComponent,
    EditNewsComponent,
    SafeHtmlPipe,
    FileUploadComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    AppRoutingModule,
    FormsModule,
    NgxCaptchaModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {enableTracing: true}),
    ModalDialogModule.forRoot(),
    AngularEditorModule,
    ImageToDataUrlModule, //ngx-image2dataurl
    ImageCropperModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
      ControlsModule
  ],
  providers: [
    HomeService,
    AdherentsService,
    AuthGuardService,
    FormDataService,
    FormDataSubscribe,
    FormDataConnexion,
    FormDataEvent,
    AdherentsFormDataSubscribe,
    ContactService,
    LofApiBackendService,
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ],
  entryComponents: [
    EditNewsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
