import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-association-group-list',
  templateUrl: './association-group-list.component.html',
  styleUrls: ['./association-group-list.component.css']
})
export class AssociationGroupListComponent implements OnInit {
  datas = null;
  public searchText: any;
  isConnect = false;
  isAdmin = false;
  public role;
  p: number = 1;
  todayDt = new Date();
  activationDateStr = '';
  activationId = null;
  minActivationDateStr: string;

  constructor(
    private contactService: ContactService,
    private router: Router,
  ) { }

  private _getTodayDate(): string {
    return this._normalizeDateToIsoYMD(this.todayDt);
  }

  private _normalizeDateToIsoYMD(theDate: Date) {
    return `${theDate.getFullYear()}-${(theDate.getMonth() + 1).toString().padStart(2, '0')}-${(theDate.getDate()).toString().padStart(2, '0')}`;
  }

  ngOnInit() {
    this.getAssociationGroup();
    if (localStorage.getItem('token')) {
      this.isConnect = true;
      this.getCurrentUser();
    }

    this.activationDateStr = this._getTodayDate();
  }

  ActiveAssociation(assoId) {
    this.contactService.activeAssociationWithGroupService(assoId).subscribe((item:any)=> {
      if (item) {
        window.location.reload();
        this.router.navigate(['group_asso_list']);
      }
    });
  }

  activeAssociationWithActivationDate(assoId) {
    const v = this.activationDateStr.split('-');
    const activateDateData = {
      year: v[0],
      month: v[1],
      day: v[2]
    };

    this.contactService.activeAssociationWithRequestBodyGroupService(assoId, {activateDate: activateDateData}).subscribe((item:any)=> {
      if (item) {
        window.location.reload();
        this.router.navigate(['group_asso_list']);
      }
    });
  }

  desActiveAssociation(assoId) {
    this.contactService.desActiveAssociationGroupService(assoId).subscribe((item:any)=> {
      if (item) {
        window.location.reload()
        this.router.navigate(['group_asso_list'])
      }
    })
  }

  // getCountOwnerAssociation() {
  //   this.contactService.getAssociationGroupService().subscribe((data: any) => {

  //   }
  // }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any)=> {
      this.role = item.data.role.value
    })
  }

  public getAssociationGroup() {
    this.contactService.getAssociationGroupService().subscribe((response: any) => {
      this.contactService.getCurrentUserService().subscribe((item:any)=> {
        if (response) {
          response.data.forEach(asso => {
            const isLofAdminOrSuperAdmin = item.data.role.value === 'SUPER_ADMIN' || item.data.role.value === 'LOF_ADMIN';
            // BUG LOF-159 -  Only Lof admin or super admin can see all enabled asso data.
            if (isLofAdminOrSuperAdmin) {
              this.datas = response.data;
            } else  {
              this.datas = response.data.filter(x => x.status === 'ENABLED');
            }
          });
          return this.datas;
        }
      },
      error => {
        this.datas = [{
          data: []
        }]
        response.data.forEach(asso => {
          if ((asso.status === "DISABLED" || asso.status === "DEMO")) {
            this.datas = response.data.filter(x => x.status === "ENABLED")
          } else {
            this.datas = response.data.filter(x => x.status === "ENABLED")
          }
        })
      })

    })
  }

  getExportAssociationGroupService() {
    this.contactService.exportAssociationGroupService().subscribe((data: any) => {
      if (data) {
        const target = data.url;
        if (target) {
          window.open(target, '_blank');
        }
      }
    })
  }

  getExportPdfAssociationGroupService() {
    this.contactService.exportPdfAssociationGroupService().subscribe((data: any) => {
      if (data) {
        const targetPdf = data.url;
        if (targetPdf) {
          window.open(targetPdf, '_blank');
        }
      }
    })
  }

  goToInfo(idMember) {
    if (this.role === "SUPER_ADMIN" || this.role === "LOF_ADMIN") {
      this.router.navigate(['/group_asso_list', idMember])
    }
  }

  selectActivationId(assoId: string, createdAt) {
    console.log(createdAt);
    this.activationDateStr = this._getTodayDate();
    this.activationId = assoId;
    const minDate: Date = new Date(createdAt);
    // The activation date should be gt creation date.
    this.minActivationDateStr = this._normalizeDateToIsoYMD(minDate);
  }

  confirmActivation() {
    this.activeAssociationWithActivationDate(this.activationId);
  }

  cancelActivation() {
    this.activationId = null;
  }

  onDownloadExcelExport(evt) {
    evt.preventDefault();
    this.getExportAssociationGroupService();
  }

  onDownloadPdfExport(evt) {
    evt.preventDefault();
    this.getExportPdfAssociationGroupService();
  }
}
