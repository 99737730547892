import { Injectable } from '@angular/core';
import { LofApiBackendService } from '@business/lof-backend/lof-api-backend.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
    constructor( 
                 private lofApiBackendService: LofApiBackendService,
                 ) { }

    public sendMailService(input) {
      return this.lofApiBackendService.sendMailBackendService(input);
    }

    public getService() {
      return this.lofApiBackendService.statsHomeBackendService()
    }

}