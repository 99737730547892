import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-actualite',
  templateUrl: './member-manage.component.html',
  styleUrls: ['./member-manage.component.css']
})
export class MemberManageComponent implements OnInit {
  add = false
  list = true
  public userForm: FormGroup;
  public datas: any;
  public searchText: any;
  constructor(
    private serviceNotif: NotificationsService,
    private contactService: ContactService,
    private router: Router,    
    ) { }

  public userFormBuilder(): FormGroup {
    return new FormGroup({
      'firstName': new FormControl("", [Validators.required]),
      'lastName': new FormControl("", [Validators.required]),
      'password': new FormControl("", [Validators.required]),
      'passwordConf': new FormControl("", [Validators.required]),
      'email': new FormControl("", [Validators.required]),
      'role': new FormControl("USER", [Validators.required]),
      'statut': new FormControl(""),
    })
  }

  ngOnInit() {
    this.getCurrentUser()
    this.getUsers()
    this.userForm = this.userFormBuilder()
  }

  goToInfo(lofId) {
      this.router.navigate(['/gestion_des_utilisateurs_modifier', lofId])
  }

  newUser() {
    this.add = true
    this.list = false
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item:any)=> {
      if (item.data.role.value === "USER" || item.data.role.value === "ASSOCIATION_MANAGER") {
        this.router.navigate(['home'])
      }
    })
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

    const input = {
      "firstName": this.userForm.get('firstName').value,
      "lastName": this.userForm.get('lastName').value,
      "password": this.userForm.get('password').value,
      "email": this.userForm.get('email').value,
      "role": this.userForm.get('role').value,
      "enabled": this.userForm.get('statut').value === ""? false: true

      };
    this.contactService.addUserService(input).subscribe((data: any) => {
      if (data) {
        this.userForm.reset();
        this.serviceNotif.success('Succès', "Utilisateur crée !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
      this.getUsers()
      this.add = false
      this.list = true
    },
    error => {
      this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
        position: ['top', 'left'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      });
    });
  }

  getUsers() {
    this.contactService.getUserService().subscribe((data: any) => {
      if (data) {
        this.datas = data.data.filter(user => user.role.value !== "ASSOCIATION_MANAGER")
        return this.datas;
      }
    });
  }

  listUser() {
    this.getUsers()
    this.add = false
    this.list = true
  }

  get f() { return this.userForm.controls; }
  get firstName() { return this.userForm.get('firstName'); }
  get lastName() { return this.userForm.get('lastName'); }
  get password() { return this.userForm.get('password'); }
  get passwordConf() { return this.userForm.get('passwordConf'); }
  get email() { return this.userForm.get('email'); }
  get role() { return this.userForm.get('role'); }
  get statut() { return this.userForm.get('statut'); }

}
