import { Component, OnInit } from '@angular/core';
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
declare let $: any;
import { Router } from '@angular/router'
@Component({
  selector: 'app-import-etape3',
  templateUrl: './import-etape3.component.html',
  styleUrls: ['./import-etape3.component.css']
})
export class ImportEtape3Component implements OnInit {
finishImport: boolean
  constructor(    
    private contactService: ContactService,
    private connectionService: ConnexionService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getCurrentMembersAssociation()  
    this.finishImport = false;  
  }

  changeFinish() {
    this.finishImport = !this.finishImport;
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
          }
        }
      )
  }

}
