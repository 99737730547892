import { Injectable } from '@angular/core';
import { LofApiBackendService } from '@business/lof-backend/lof-api-backend.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AdherentsService {
    header = {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
                .set('Authorization', `Bearer ${localStorage.getItem("token")}`)
    }
    constructor(
        private lofApiBackendService: LofApiBackendService,
    ) { }

    public adherentsSubscribeService(input) {
        return this.lofApiBackendService.adherentsSubscribeBackendService(input, this.header);
    }

}