import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ContactService } from '@contactez_nous/services/contact.service';
import { NotificationsService } from 'angular2-notifications';
import { MustMatch } from '../../inscription/helpers/must-match.validators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  fieldTextType: boolean;
  fieldTextType2: boolean;
  errorReset = false;
  loading = false;
  passwordPatern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?\-&])[A-Za-z\d@$!%*#?\-&]{8,20}$/;
  resetPasswordForm: FormGroup;
  reinitSuccess = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private serviceNotif: NotificationsService,
    private contactService: ContactService
  ) { }

  public resetFormBuilder(): FormGroup {

    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ["", Validators.required],
      confirmPassword: ["", [Validators.required, Validators.pattern(this.passwordPatern)]]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
    return this.resetPasswordForm
  }


  ngOnInit() {
    this.resetPasswordForm = this.resetFormBuilder()
  }

  submit(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    const resetToken = this.route.snapshot.paramMap.get("id")
    const newPassword = this.resetPasswordForm.get("confirmPassword").value
    this.loading = true;
    this.contactService.resetPasswordService(resetToken, newPassword).subscribe((data: any) => {
      if (data) {
        this.loading = false;
        this.errorReset = true
        this.serviceNotif.success("Succès", `Vous pouvez à présent vous connecter !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
      }
      this.reinitSuccess = true;
      this.resetPasswordForm.get("confirmPassword").setValue("");
    },
      error => {
        console.log(error)
        this.loading = false
        this.errorReset = false;
        this.serviceNotif.error("Error", `Lien de réinitialisation incorrect !`, {
          position: ["top", "left"],
          timeOut: 5000,
          animate: "fade",
          showProgressBar: true,
        });
    })
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  get newPassword() {
    return this.resetPasswordForm.get("newPassword");
  }
  get confirmPassword() {
    return this.resetPasswordForm.get("confirmPassword");
  }

}
