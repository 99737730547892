import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IContact } from '@contactez_nous/interfaces/contact.interface';
import { FormDataService } from '@contactez_nous/services/formDataService';
import { ContactService } from '../services/contact.service';
import { NotificationsService } from 'angular2-notifications';
import { ConnexionService } from '@app/modules/connexion/services/connexion.service';

@Component({
  selector: 'app-contactez-nous',
  templateUrl: './contactez-nous.component.html',
  styleUrls: ['./contactez-nous.component.css']
})
export class ContactezNousComponent implements OnInit {  
  public siteKey = "6Lei_hYaAAAAALvSy3yz9QQ6vg2vml4Iud_xyMgC"
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';
  captcha = false
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  contactForm: FormGroup;
  contactSend: boolean;
  constructor(
    private formDataService: FormDataService,
    private loginService: ConnexionService,
    private contactService: ContactService,
    private serviceNotif: NotificationsService,
  ) { }

  public contactFormBuiler(contactFormDataInput: IContact): FormGroup {
    return new FormGroup({
      'name': new FormControl("", [Validators.required]),
      'phone': new FormControl("", [Validators.required, Validators.pattern(this.phonePatern)]),
      'email': new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      'subject': new FormControl("", [Validators.required]),
      'message': new FormControl("", [Validators.required]),
      'recaptcha':  new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.contactForm = this.contactFormBuiler(this.formDataService.getContact());
  }

  async handleSuccess(response:any) {
    await this.sendTokenToBackend(response);
  }

  sendTokenToBackend(token) {
    this.loginService.sendTokenRecaptchaService(token).subscribe(
      data => {
      },
      err => {
        console.log(err)
      },
      () => {}
    );
  }

  goToCaptcha() {
    this.captcha = true
  }

  handleReset() {

  }

  handleExpire() {
    this.captcha = false
  }

  handleLoad () {

  }


  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

    const input: IContact = {
      name: this.contactForm.get('name').value,
      phone: this.contactForm.get('phone').value,
      email: this.contactForm.get('email').value,
      subject: this.contactForm.get('subject').value,
      message: this.contactForm.get('message').value,
    };

    this.formDataService.setContact(input);
    this.contactService.sendMailService(this.formDataService.getContact()).subscribe(
      res => {
        if (this.formDataService.getContact().email !== null) {
          this.serviceNotif.success('Succès', "Méssage envoyé avec succès !", {
            position: ['top', 'right'],
            timeOut: 2000,
            animate: 'fade',
            showProgressBar: true
          });
        }
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log('error send mail -> ', error);
      }
    )
    this.contactSend = true;
    this.contactForm.reset();
  }

  get f() { return this.contactForm.controls; }

  get name() { return this.contactForm.get('name'); }
  get phone() { return this.contactForm.get('phone'); }
  get email() { return this.contactForm.get('email'); }
  get subject() { return this.contactForm.get('subject'); }
  get message() { return this.contactForm.get('message'); }

}
