import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { IAdhrents } from '@adherent_subscribe/interfaces/adherent.interface';
import { AdherentsService } from '@adherent_subscribe/services/adherents.service';
import { AdherentsFormDataSubscribe } from '@adherent_subscribe/services/AdherentsFormDataSubscribe';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ContactService } from '../contactez-nous/services/contact.service';
import { ConnexionService } from '@connexion/services/connexion.service';
declare let $: any;

@Component({
  selector: 'app-adherent-subscribe',
  templateUrl: './adherent-subscribe.component.html',
  styleUrls: ['./adherent-subscribe.component.css']
})
export class AdherentSubscribeComponent implements OnInit {
  zipCodePatern = /^[0-9]{5}(?:-[0-9]{5})?$/;
  phonePatern = /^((\\+91-?)|0)?[0-9]{9}$/;
  emailPattern = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  adherentForm: FormGroup;
  public role;
  finishRegistration = {};
  keyword: any
  departements = [
    "Ain", "Aisne", "Allier", "Alpes-de-Haute-Provence", "Hautes-Alpes", "Alpes-Maritimes",
    "Ardèche", "Ardennes", "Ariège", "Aube", "Aude", "Aveyron", "Bouches-du-Rhône", "Calvados",
    "Cantal", "Charente", "Charente-Maritime", "Cher", "Corrèze", "Corse-du-Sud", "Haute-Corse",
    "Côte-d'Or", "Côtes d'Armor", "Creuse", "Dordogne", "Doubs", "Drôme", "Eure", "Eure-et-Loir",
    "Finistère", "Gard", "Haute-Garonne", "Gers", "Gironde", "Hérault", "Ille-et-Vilaine", "Indre",
    "Indre-et-Loire", "Isère", "Jura", "Landes", "Loir-et-Cher", "Loire", "Haute-Loire", "Loire-Atlantique",
    "Loiret", "Lot", "Lot-et-Garonne", "Lozère", "Maine-et-Loire", "Manche", "Marne", "Haute-Marne", "Mayenne",
    "Meurthe-et-Moselle", "Meuse", "Morbihan", "Moselle", "Nièvre", "Nord", "Oise", "Orne", "Pas-de-Calais",
    "Puy-de-Dôme", "Pyrénées-Atlantiques", "Hautes-Pyrénées", "Pyrénées-Orientales", "Bas-Rhin", "Haut-Rhin",
    "Rhône", "Haute-Saône", "Saône-et-Loire", "Sarthe", "Savoie", "Haute-Savoie", "Paris", "Seine-Maritime",
    "Seine-et-Marne", "Yvelines", "Deux-Sèvres", "Somme", "Tarn", "Tarn-et-Garonne", "Var", "Vaucluse",
    "Vandée", "Vienne", "Haute-Vienne", "Vosges", "Yonne", "Territoire de Belfort", "Essonne",
    "Hauts-de-Seine", "Seine-St-Denis", "Val-de-Marne", "Val-D'Oise"
  ]

  constructor(
    private adherentsFormDataSucribe: AdherentsFormDataSubscribe,
    private adherentsService: AdherentsService,
    private router: Router,
    private connectionService: ConnexionService,
    private contactService: ContactService,
    private serviceNotif: NotificationsService
  ) { }

  ngOnInit() {
    this.getCurrentUser()
    this.adherentForm = this.registerFormBuilder(this.adherentsFormDataSucribe.getAdherentsRegister())
  }

  public registerFormBuilder(adherendFormBuilderInput: IAdhrents): FormGroup {
    return new FormGroup({
      'firstName': new FormControl('', [Validators.required]),
      'lastName': new FormControl('', [Validators.required]),
      'dateOfBirth': new FormControl('', [Validators.required]),
      'position': new FormControl('', [Validators.required]),
      'function': new FormControl('', [Validators.required]),
      'department': new FormControl('', [Validators.required]),
      'sexe': new FormControl("", [Validators.required]),
    })
  }

  public ageValidator() {
    let d1 = new Date();
    let d2 = new Date(this.adherentForm.get('dateOfBirth').value);
    let d3 = d1.getTime() > d2.getTime()
    if (d3 === false) {
      this.adherentForm.controls.dateOfBirth.setValue('')
      this.adherentForm.controls.dateOfBirth.setValidators(Validators.required);
    }
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
      if (item.data.role.value === "USER" || item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.router.navigate(['home'])
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentMembersAssociation();
      }
    })
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
          }
        }
      )
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    this.finishRegistration =
    {
      "firstName": this.adherentForm.get('firstName').value,
      "lastName": this.adherentForm.get('lastName').value,
      "dateOfBirth": this.adherentForm.get('dateOfBirth').value,
      "position": this.adherentForm.get('position').value,
      "function": this.adherentForm.get('function').value,
      "department": this.adherentForm.get('department').value,
      "gender": this.adherentForm.get('sexe').value

    };

    this.adherentsService.adherentsSubscribeService(this.finishRegistration).subscribe(
      (res: any) => {
        if (res.data.id) {
          this.adherentForm.reset();
          this.adherentsFormDataSucribe.clearDataAdherents();
          this.serviceNotif.success('Succès', "Enregistrement réussi !", {
            position: ['top', 'right'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
        }
      },
      error => {
        if (error.error.code ===  "DUPLICATE_MEMBER_ERROR") {
          this.serviceNotif.error('Error', `Un adhérent existe déjà avec ce nom, ce prénom et cette date de naissance`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
        } else {
          this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
            position: ['top', 'left'],
            timeOut: 5000,
            animate: 'fade',
            showProgressBar: true
          });
          console.log("error send register -> ", error);
        }
      }
    )
  }

  get f() { return this.adherentForm.controls; }
  get firstName() { return this.adherentForm.get('firstName'); }
  get lastName() { return this.adherentForm.get('lastName'); }
  get dateOfBirth() { return this.adherentForm.get('dateOfBirth'); }
  get position() { return this.adherentForm.get('position'); }
  get function() { return this.adherentForm.get('function'); }
  get department() { return this.adherentForm.get('department'); }
  get sexe() { return this.adherentForm.get('sexe'); }


  onFocused(e) {

  }

  onChangeSearch(e) {

  }

  selectEvent(e) {

  }
}
