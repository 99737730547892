import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-connexion-id',
  templateUrl: './connexion-id.component.html',
  styleUrls: ['./connexion-id.component.css']
})
export class ConnexionIdComponent implements OnInit {
  public datas:any;
  public searchText: any;
  public role:string
  p: number = 1;

  constructor(
    private contactService: ContactService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getConnexionIdInfo();
    this.getCurrentUser()
  }

  public getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe(
      (res:any) => {
          this.role = res.data;
          if (res.data.role.value !== "SUPER_ADMIN") {
            this.router.navigate(["home"]);
          }
      }
    )
  }

  public getConnexionIdInfo() {
    this.contactService.getAssociationEmailInfoService().subscribe(
      (res:any)=> {
        this.datas = res.data
      }
    )
  }

}
