import { Component, OnInit } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { Router } from '@angular/router'
import { ConnexionService } from '@connexion/services/connexion.service';
declare let $: any;
@Component({
  selector: 'app-import-result',
  templateUrl: './import-result.component.html',
  styleUrls: ['./import-result.component.css']
})
export class ImportResultComponent implements OnInit {

  constructor(    
    private contactService: ContactService,
    private connectionService: ConnexionService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getCurrentMembersAssociation()
  }

  deconnexion() {
    this.connectionService.deconnexionUser()
    window.location.reload()
    this.router.navigate(['home'])
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
          }
        }
      )
  }
}
