import { Injectable } from '@angular/core';
import { AssociationData, ConnexionRegisterData, RegisterMemberData, GroupeData, ChoixData, ContactData } from '@inscription/class/inscription';
import { IAssociation, IConnexionRegister, IGroupe, IChoix, IContact, IRegisterMember } from '../interfaces/inscription.interface';

@Injectable()
export class FormDataSubscribe {
    private associationFormData = new AssociationData();
    private groupeFormData = new GroupeData();
    private registerMemberData = new RegisterMemberData();
    private choixFormData = new ChoixData();
    private contactFormData = new ContactData();
    private connexionRegisterData = new ConnexionRegisterData();

    public getConnexionRegister(): IConnexionRegister {
        return {
            identifiant: this.connexionRegisterData.identifiant,
            password: this.connexionRegisterData.password,
            confirmPassword: this.connexionRegisterData.confirmPassword,
        };
    }

    public setConnexionRegister(input: IConnexionRegister) {
        this.connexionRegisterData.password = input.password;
        this.connexionRegisterData.identifiant = input.identifiant;
    }

    public getContact(): IContact {
        return {
            // Gestionaires
            nomG: this.contactFormData.nomG,
            prenomG: this.contactFormData.prenomG,
            statutG: this.contactFormData.statutG,
            villeG: this.contactFormData.villeG,
            mobileG: this.contactFormData.mobileG,
            emailG: this.contactFormData.emailG,

             // 2ieme Contact
            nom1: this.contactFormData.nom1,
            prenom1: this.contactFormData.prenom1,
            statut1: this.contactFormData.statut1,
            ville1: this.contactFormData.ville1,
            mobile1: this.contactFormData.mobile1,
            email1: this.contactFormData.email1,

            // 3ieme Contact
            nom2: this.contactFormData.nom2,
            prenom2: this.contactFormData.prenom2,
            statut2: this.contactFormData.statut2,
            ville2: this.contactFormData.ville2,
            mobile2: this.contactFormData.mobile2,
            email2: this.contactFormData.email2,

            infosG: this.contactFormData.infosG,
            confirmLecture: this.contactFormData.confirmLecture,
        }
    }

    public setContact(input: IContact) {
        this.contactFormData.nomG = input.nomG;
        this.contactFormData.prenomG = input.prenomG;
        this.contactFormData.statutG = input.statutG;
        this.contactFormData.villeG = input.villeG;
        this.contactFormData.mobileG = input.mobileG;
        this.contactFormData.emailG = input.emailG;

        this.contactFormData.nom1 = input.nom1;
        this.contactFormData.prenom1 = input.prenom1;
        this.contactFormData.statut1 = input.statut1;
        this.contactFormData.ville1 = input.ville1;
        this.contactFormData.mobile1 = input.mobile1;
        this.contactFormData.email1 = input.email1;

        this.contactFormData.nom2 = input.nom2;
        this.contactFormData.prenom2 = input.prenom2;
        this.contactFormData.statut2 = input.statut2;
        this.contactFormData.ville2 = input.ville2;
        this.contactFormData.mobile2 = input.mobile2;
        this.contactFormData.email2 = input.email2;
        this.contactFormData.infosG = input.infosG;
        this.contactFormData.confirmLecture = input.confirmLecture;
    }

    public getRegisterMember(): IRegisterMember {
        return {
            name: this.registerMemberData.name,
            address: this.registerMemberData.address,
            city: this.registerMemberData.city,
            postalCode: this.registerMemberData.postalCode,
            rnaNumber: this.registerMemberData.rnaNumber,
            phone: this.registerMemberData.phone,
            email: this.registerMemberData.email,
            department: this.registerMemberData.department,
            type: this.registerMemberData.type,

            //Gestionnaire
            nomG: this.registerMemberData.nomG,
            prenomG: this.registerMemberData.prenomG,
            statutG: this.registerMemberData.statutG,
            villeG: this.registerMemberData.villeG,
            mobileG: this.registerMemberData.mobileG,
            emailG: this.registerMemberData.emailG,

            //Premier contact
            nom1: this.registerMemberData.nom1,
            prenom1: this.registerMemberData.prenom1,
            statut1: this.registerMemberData.statut1,
            ville1: this.registerMemberData.ville1,
            mobile1: this.registerMemberData.mobile1,
            email1: this.registerMemberData.email1,

            //Deuxième contact
            nom2: this.registerMemberData.nom2,
            prenom2: this.registerMemberData.prenom2,
            statut2: this.registerMemberData.statut2,
            ville2: this.registerMemberData.ville2,
            mobile2: this.registerMemberData.mobile2,
            email2: this.registerMemberData.email2,
            infosG:  this.registerMemberData.infosG,
            confirmLecture:  this.registerMemberData.confirmLecture,

            //Paiement
            reference: this.registerMemberData.reference,
            amount: this.registerMemberData.amount,
            provider: this.registerMemberData.provider,
            status: this.registerMemberData.status,

        }
    }

    public setRegisterMember(input: IRegisterMember) {
        //Association
        this.registerMemberData.name = input.name;
        this.registerMemberData.address = input.address;
        this.registerMemberData.city = input.city;
        this.registerMemberData.postalCode = input.postalCode;
        this.registerMemberData.rnaNumber = input.rnaNumber;
        this.registerMemberData.phone = input.phone;
        this.registerMemberData.email = input.email;
        this.registerMemberData.department = input.department;
        this.registerMemberData.type = input.type;

        //Gestionnaire
        this.registerMemberData.nomG = input.nomG;
        this.registerMemberData.prenomG = input.prenomG;
        this.registerMemberData.city = input.city;
        this.registerMemberData.villeG = input.villeG;
        this.registerMemberData.mobileG = input.mobileG;
        this.registerMemberData.emailG = input.emailG;

        //Premier contact
        this.registerMemberData.nom1 = input.nom1;
        this.registerMemberData.prenom1 = input.prenom1;
        this.registerMemberData.statut1 = input.statut1;
        this.registerMemberData.ville1 = input.ville1;
        this.registerMemberData.mobile1 = input.mobile1;
        this.registerMemberData.email1 = input.email1;

        //Deuxième contact
        this.registerMemberData.nom2 = input.nom2;
        this.registerMemberData.prenom2 = input.prenom2;
        this.registerMemberData.statut2 = input.statut2;
        this.registerMemberData.ville2 = input.ville2;
        this.registerMemberData.mobile2 = input.mobile2;
        this.registerMemberData.email2 = input.email2;
        this.registerMemberData.infosG = input.infosG;
        this.registerMemberData.confirmLecture = input.confirmLecture;
    }

    public getAssociation(): IAssociation {
        return {
            nom: this.associationFormData.nom,
            adresse: this.associationFormData.adresse,
            numVoie: this.associationFormData.numVoie,
            ville: this.associationFormData.ville,
            code: this.associationFormData.code,
            identifiant: this.associationFormData.identifiant,
            telephone: this.associationFormData.telephone,
            email: this.associationFormData.email,
            department: this.associationFormData.department
        }
    }

    public setAssociation(input: IAssociation) {
        this.associationFormData.nom = input.nom;
        this.associationFormData.adresse = input.adresse;
        this.associationFormData.numVoie = input.numVoie;
        this.associationFormData.ville = input.ville;
        this.associationFormData.code = input.code;
        this.associationFormData.identifiant = input.identifiant;
        this.associationFormData.telephone = input.telephone;
        this.associationFormData.email = input.email;
        this.associationFormData.department = input.department;
    }

    public getGroupe(): IGroupe {
        return {
            nom: this.groupeFormData.nom,
            adresse: this.groupeFormData.adresse,
            numVoie: this.groupeFormData.numVoie,
            ville: this.groupeFormData.ville,
            code: this.groupeFormData.code,
            telephone: this.groupeFormData.telephone,
            email: this.groupeFormData.email,
            department: this.groupeFormData.department
        }
    }

    public setGroupe(input: IGroupe) {
        this.groupeFormData.nom = input.nom;
        this.groupeFormData.adresse = input.adresse;
        this.groupeFormData.numVoie = input.numVoie;
        this.groupeFormData.ville = input.ville;
        this.groupeFormData.code = input.code;
        this.groupeFormData.telephone = input.telephone;
        this.groupeFormData.email = input.email;
        this.groupeFormData.department = input.department;
    }

    public getChoix(): IChoix {
        return {
            type: this.choixFormData.type
        }
    }

    public setChoix(input: IChoix) {
        this.choixFormData.type = input.type;
    }

    public clearDataGroupe() {
        const clearGroupe: IGroupe = {
            nom: null,
            adresse: null,
            numVoie: null,
            ville: null,
            code: null,
            telephone : null,
            email : null,
            department : null
        }
        this.setGroupe(clearGroupe);
    }

    public clearConnexionRegister() {
        const clearConnexionRegister: IConnexionRegister = {
            identifiant: null,
            password: null,
            confirmPassword: null,
        }
        this.setConnexionRegister(clearConnexionRegister);
    }

    public clearDataAssociation() {
        const clearAssociation: IAssociation = {
            nom: null,
            adresse: null,
            numVoie: null,
            ville: null,
            code: null,
            identifiant: null,
            telephone : null,
            email : null,
            department : null
        }
        this.setAssociation(clearAssociation);
    }

    public clearDataContact() {
        const clearContact: IContact = {
            nomG: null,
            prenomG: null,
            statutG: null,
            villeG: null,
            mobileG: null,
            emailG: null,
            nom1: null,
            prenom1: null,
            statut1: null,
            ville1: null,
            mobile1: null,
            email1: null,
            nom2: null,
            prenom2: null,
            statut2: null,
            ville2: null,
            mobile2: null,
            email2: null,
            infosG: null,
            confirmLecture: null
        }
        this.setContact(clearContact);
    }
}
