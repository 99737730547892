import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  stats = null;
  isValid: boolean;
  public coef = 0
  constructor(
    private homeService: HomeService,      
    ) {
  }
  ngOnInit() {
    this.homeService.getService().subscribe(
      (data:any) => {
        this.coef = data.data.coefficient;  
        this.stats = data.data;
        return this.stats;
      }
    )
  }
}
