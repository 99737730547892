import { Component, OnInit } from '@angular/core';
import { ConnexionService } from '../connexion/services/connexion.service';

@Component({
  selector: 'app-info-perso',
  templateUrl: './info-perso.component.html',
  styleUrls: ['./info-perso.component.css']
})
export class InfoPersoComponent implements OnInit {
  fieldTextType: boolean;

  constructor(      
  ) { }

  ngOnInit() {
   
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
