export class AssociationData {
    nom: string;
    adresse: string;
    numVoie: string;
    ville: string;
    code: number;
    identifiant: string;
    telephone: string;
    email: string;
    department: string;

    constructor() {
      this.nom = null;
      this.adresse = null;
      this.numVoie = null;
      this.ville = null;
      this.code = null;
      this.identifiant = null;
      this.telephone = null;
      this.email = null;
      this.department = null;
    }
  }

  export class GroupeData {
    nom: string;
    adresse: string;
    numVoie: string;
    ville: string;
    code: number;
    telephone: string;
    email: string;
    department: string;

    constructor() {
      this.nom = null;
      this.adresse = null;
      this.numVoie = null;
      this.ville = null;
      this.code = null;
      this.telephone = null;
      this.email = null;
      this.department = null;
    }
  }

  export class ContactData {
    // Gestionaires
    nomG: string;
    prenomG: string;
    statutG: string;
    villeG: string;
    mobileG: string;
    emailG: string;

    // 2ieme Contact
    nom1: string;
    prenom1: string;
    statut1: string;
    ville1: string;
    mobile1: string;
    email1: string;

    // 3ieme Contact
    nom2: string;
    prenom2: string;
    statut2: string;
    ville2: string;
    mobile2: string;
    email2: string;
    infosG: boolean;
    confirmLecture: boolean;

    constructor() {
      this.nomG = null;
      this.prenomG = null;
      this.statutG = null;
      this.villeG = null;
      this.mobileG = null;
      this.emailG = null;
      this.nom1 = null;
      this.prenom1 = null;
      this.statut1 = null;
      this.ville1 = null;
      this.mobile1 = null;
      this.email1 = null;
      this.nom2 = null;
      this.prenom2 = null;
      this.statut2 = null;
      this.ville2 = null;
      this.mobile2 = null;
      this.email2 = null;
      this.infosG = null;
      this.confirmLecture = null;
    }
  }

  export class ChoixData {
    type: string;

    constructor() {
      this.type = null;
    }
  }

  export class ConnexionRegisterData {
    identifiant: string;
    password: string;
    confirmPassword: string;

    constructor() {
      this.identifiant = null;
      this.password = null;
      this.confirmPassword = null;
    }
  }

  export class RegisterMemberData {
    //Association
    name: string;
    address: string;
    city: string;
    postalCode: number;
    rnaNumber: string;
    phone: string;
    email: string;
    department: string;
    type: string;

    //Gestionnaire
    nomG: string;
    prenomG: string;
    statutG: string;
    villeG: string;
    mobileG: string;
    emailG: string;

    //Premier contact
    nom1: string;
    prenom1: string;
    statut1: string;
    ville1: string;
    mobile1: string;
    email1: string;

    //Deuxième contac
    nom2: string;
    prenom2: string;
    statut2: string;
    ville2: string;
    mobile2: string;
    email2: string;
    infosG: boolean;
    confirmLecture: boolean;

    //Paiement
    reference: string;
    amount: string;
    provider: string;
    status: string;

    constructor() {
      this.name = null;
      this.address = null;
      this.city = null;
      this.postalCode = null;
      this.rnaNumber = null;
      this.phone = null;
      this.email = null;
      this.department = null;
      this.type = null;

      this.nomG = null;
      this.prenomG = null;
      this.statutG = null;
      this.villeG = null;
      this.mobileG = null;
      this.emailG = null;

      this.nom1 = null;
      this.prenom1 = null;
      this.statut1 = null;
      this.ville1 = null;
      this.mobile1 = null;
      this.email1 = null;

      this.nom2 = null;
      this.prenom2 = null;
      this.statut2 = null;
      this.ville2 = null;
      this.mobile2 = null;
      this.email2 = null;

    }
  }
