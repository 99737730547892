import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAdhrents } from '../adherent-subscribe/interfaces/adherent.interface';
import { AdherentsFormDataSubscribe } from '../adherent-subscribe/services/AdherentsFormDataSubscribe';
import { ConnexionService } from '@connexion/services/connexion.service';
import {formatFormDate} from "@app/utils";
declare let $: any;
@Component({
  selector: "app-adherent-details",
  templateUrl: "./adherent-details.component.html",
  styleUrls: ["./adherent-details.component.css"],
})
export class AdherentDetailsComponent implements OnInit {
  edit: boolean;
  adherentForm: FormGroup;
  _adherent = null;
  public role;
  public detailId;
  public user = localStorage.getItem("user");
  owners = null;
  keyword: any
  departements = [
    "Ain", "Aisne", "Allier", "Alpes-de-Haute-Provence", "Hautes-Alpes", "Alpes-Maritimes",
    "Ardèche", "Ardennes", "Ariège", "Aube", "Aude", "Aveyron", "Bouches-du-Rhône", "Calvados",
    "Cantal", "Charente", "Charente-Maritime", "Cher", "Corrèze", "Corse-du-Sud", "Haute-Corse",
    "Côte-d'Or", "Côtes d'Armor", "Creuse", "Dordogne", "Doubs", "Drôme", "Eure", "Eure-et-Loir",
    "Finistère", "Gard", "Haute-Garonne", "Gers", "Gironde", "Hérault", "Ille-et-Vilaine", "Indre",
    "Indre-et-Loire", "Isère", "Jura", "Landes", "Loir-et-Cher", "Loire", "Haute-Loire", "Loire-Atlantique",
    "Loiret", "Lot", "Lot-et-Garonne", "Lozère", "Maine-et-Loire", "Manche", "Marne", "Haute-Marne", "Mayenne",
    "Meurthe-et-Moselle", "Meuse", "Morbihan", "Moselle", "Nièvre", "Nord", "Oise", "Orne", "Pas-de-Calais",
    "Puy-de-Dôme", "Pyrénées-Atlantiques", "Hautes-Pyrénées", "Pyrénées-Orientales", "Bas-Rhin", "Haut-Rhin",
    "Rhône", "Haute-Saône", "Saône-et-Loire", "Sarthe", "Savoie", "Haute-Savoie", "Paris", "Seine-Maritime",
    "Seine-et-Marne", "Yvelines", "Deux-Sèvres", "Somme", "Tarn", "Tarn-et-Garonne", "Var", "Vaucluse",
    "Vandée", "Vienne", "Haute-Vienne", "Vosges", "Yonne", "Territoire de Belfort", "Essonne",
    "Hauts-de-Seine", "Seine-St-Denis", "Val-de-Marne", "Val-D'Oise"
  ]
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private connectionService: ConnexionService,
    private adherentsFormDataSucribe: AdherentsFormDataSubscribe,
    private serviceNotif: NotificationsService
  ) { }

  public registerFormBuilder(adherendFormBuilderInput: IAdhrents): FormGroup {
    return new FormGroup({
      'firstName': new FormControl(''),
      'lastName': new FormControl(''),
      'dateOfBirth': new FormControl(''),
      'function': new FormControl(''),
      'department': new FormControl(''),
      'position': new FormControl(''),
      'dateOfDeath': new FormControl(''),
      'cityOfDeath': new FormControl(''),
      'countryOfDeath': new FormControl(''),
      'status': new FormControl(''),
      'sexe': new FormControl(''),
      'createdAt': new FormControl(
          {value: null, disabled: true},
          {updateOn: 'change'}
      ),
    });
  }

  ngOnInit() {
    this.getCurrentMembersAssociation();
    this.adherentForm = this.registerFormBuilder(this.adherentsFormDataSucribe.getAdherentsRegister());
    this.edit = true;
    this.getAllMemberAssociation();
    this.getCurrentUser()
  }

  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }

    const input = {
      "firstName": this.adherentForm.get('firstName').value || this.datas.firstName,
      "lastName": this.adherentForm.get('lastName').value || this.datas.lastName,
      "dateOfBirth": this.adherentForm.get('dateOfBirth').value || this.datas.dateOfBirth,
      "function": this.adherentForm.get('function').value || this.datas.function,
      "department": this.adherentForm.get('department').value || this.datas.department,
      "position": this.adherentForm.get('position').value || this.datas.position,
      "dateOfDeath": this.adherentForm.get('dateOfDeath').value || this.datas.dateOfDeath || '',
      "cityOfDeath": this.adherentForm.get('cityOfDeath').value || this.datas.cityOfDeath || '',
      "countryOfDeath": this.adherentForm.get('countryOfDeath').value || this.datas.countryOfDeath || '',
      "status": this.adherentForm.get('status').value || this.datas.status,
      "gender": this.adherentForm.get('sexe').value || this.datas.gender
    };
    if (this.isAdminGrantee()) {
      input['createdAt'] = this.getCreationDate();
    }

   this.contactService.updateMemberService(input, this.datas.lofId).subscribe(
      res => {
        this.serviceNotif.success('Succès', "Modification réussie !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        this.adherentForm.get('createdAt').disable();
        this.getAllMemberAssociation();
        this.edit = true;
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      }
    )
  }

  deconnexion() {
    this.connectionService.deconnexionUser();
    window.location.reload();
    this.router.navigate(['home']);
  }

  public getCurrentMembersAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
          }
        }
      );
  }

  deleteMember() {
    this.contactService.deleteMemberService(this.datas.lofId).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['search_adherents'])
      }
    },
      error => {
        this.serviceNotif.error('Error', "Echec de suppression !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      });
  }

  getAllMemberAssociation() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.detailId = id;
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.contactService.getAllMemberService().subscribe((res: any) => {
          if (res) {
            const isExist = res.data.find(e => parseInt(e.id) === parseInt(this.detailId))
            if (isExist === undefined) {
              this.router.navigate(['search_adherents'])
            }
            res.data.forEach((info) => {
              if (parseInt(info.id) === parseInt(this.detailId)) {
                this.datas = info;
                return this.datas;
              }
            });
          }
        })
      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.contactService
          .getMemberAssociationGroupService()
          .subscribe((res: any) => {
            if (res) {
              res.data.forEach((info) => {
                if (parseInt(info.id) === parseInt(this.detailId)) {
                  this.datas = info;
                  return this.datas;
                }
              });
            }
          });
      }
    })
  }

  public getAllMember() {
    this.contactService.getAllMemberService().subscribe((res: any) => {
      if (res) {
        this.datas = res.data;
      }
    })
  }


  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    })
  }

  readOnly() {
    this.edit = !this.edit;
    if (!this.edit && this.isAdminGrantee()) {
      this.adherentForm.get('createdAt').enable();
    } else {
      this.adherentForm.get('createdAt').disable();
    }
  }

  get f() { return this.adherentForm.controls; }
  get firstName() { return this.adherentForm.get('firstName'); }
  get lastName() { return this.adherentForm.get('lastName'); }
  get dateOfBirth() { return this.adherentForm.get('dateOfBirth'); }
  get position() { return this.adherentForm.get('position'); }
  get function() { return this.adherentForm.get('function'); }
  get department() { return this.adherentForm.get('department'); }
  get sexe() { return this.adherentForm.get('sexe'); }

  onFocused(e) {

  }

  onChangeSearch(e) {

  }

  selectEvent(e) {

  }

  // LOF-400: the form value are not bound to field
  get datas() {
    return this._adherent;
  }

  set datas(v) {
    this._adherent = v;
    this.adherentForm.get('firstName').patchValue(this._adherent.firstName);
    this.adherentForm.get('lastName').patchValue(this._adherent.lastName);
    this.adherentForm.get('dateOfBirth').patchValue(this._adherent.dateOfBirth);
    this.adherentForm.get('function').patchValue(this._adherent.function);
    this.adherentForm.get('department').patchValue(this._adherent.department);
    this.adherentForm.get('position').patchValue(this._adherent.position);
    this.adherentForm.get('dateOfDeath').patchValue(this._adherent.dateOfDeath || null);
    this.adherentForm.get('cityOfDeath').patchValue(this._adherent.cityOfDeath || null);
    this.adherentForm.get('countryOfDeath').patchValue(this._adherent.countryOfDeath);
    this.adherentForm.get('status').patchValue(this._adherent.status);
    this.adherentForm.get('sexe').patchValue(this._adherent.gender);
    this.adherentForm.get('createdAt').patchValue(formatFormDate(this._adherent.createdAt) || null);
  }

  private getCreationDate(): Date {
    const v = this.adherentForm.get('createdAt') && this.adherentForm.get('createdAt').value;
    return v ? new Date(v) : null;
  }

  private isAdminGrantee(): boolean {
    return this.role === 'LOF_ADMIN' || this.role === 'SUPER_ADMIN';
  }
}
