import { Component, OnInit } from "@angular/core";
import { ContactService } from "@app/modules/contactez-nous/services/contact.service";
import { FormDataEvent } from '@adherent_event/class/formDataEvent';
import { IEvent } from '@adherent_event/interface/event.interface';
import { ActivatedRoute, Router, Routes } from "@angular/router";
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAdhrents } from '../adherent-subscribe/interfaces/adherent.interface';
declare let $: any;

@Component({
  selector: "app-adherent-event",
  templateUrl: "./adherent-event.component.html",
  styleUrls: ["./adherent-event.component.css"],
})
export class AdherentEventComponent implements OnInit {
  public imagePath;
  imgURL: any;
  public imagePathPicture;
  imgURLPicture: any;
  public imagePathCardRectoFile;
  imgURLCardRectoFile: any;
  public imagePathCertificateFile;
  imgURLCertificateFile: any;
  public message: string;
  public messagePicture: string;
  public messageCardRectoFile: string;
  public messageCertificateFile: string;
  status: boolean = false;
  statusPicture: boolean = false;
  statusCardRectoFile: boolean = false;
  statusCertificateFile: boolean = false;
  edit: boolean = false;
  selectFile: File = null;
  selectFilePicture: File = null;
  selectFileCardRectoFile: File = null;
  selectFileCertificateFile: File = null;
  eventsData: any = [];
  isUpdatingEvents: boolean = false;
  isDataSend: boolean
  eventForm: FormGroup;
  assosLofId;
  currentAsso: Object
  datas = null;
  public role;
  public detailId;
  owners = null;
  keyword: any
  memberSelected = []
  memberInfo = []
  allMember = []
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private eventFormDataSucribe: FormDataEvent,
    private serviceNotif: NotificationsService
  ) { }

  public eventFormBuilder(eventFormBuilderInput: IEvent): FormGroup {
    return new FormGroup({
      'dateOfDeath': new FormControl(eventFormBuilderInput.dateOfDeath, Validators.required),
      'statut': new FormControl(''),
      'countryOfDeath': new FormControl(eventFormBuilderInput.countryOfDeath, Validators.required),
      'cityOfDeath': new FormControl(eventFormBuilderInput.cityOfDeath, Validators.required),
      'deathCertificateFile': new FormControl(eventFormBuilderInput.deathCertificateFile),
      'pictureFile': new FormControl(eventFormBuilderInput.pictureFile),
      'idCardRectoFile': new FormControl(eventFormBuilderInput.idCardRectoFile),
      'livingCertificateFile': new FormControl(eventFormBuilderInput.livingCertificateFile),
    })
  }

  ngOnInit() {
    //this.canTransfert(); // FIXME: why?
    this.eventForm = this.eventFormBuilder(this.eventFormDataSucribe.getEvent())
    this.getAllMemberAssociation();
    this.getCurrentUser();
    this.initDeclareEvent();
  }

  preview(event) {
    this.selectFile = <File>event.target.files[0];
    var formData: FormData = new FormData();
    formData.append('image', this.selectFile)

    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match('image.*|application.pdf*') == null) {
      this.message = "Fichier non valide.";
      this.imgURL = "";
      return;
    }

    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.message = ""
    }
  }

  previewPicture(event) {
    this.selectFilePicture = <File>event.target.files[0];
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match('image.*|application.pdf*') == null) {
      this.messagePicture = "Fichier non valide.";
      this.imgURLPicture = "";
      return;
    }

    var reader = new FileReader();
    this.imagePathPicture = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURLPicture = reader.result;
      this.messagePicture = ""
    }
  }

  previewCardRectoFile(event) {
    this.selectFileCardRectoFile = <File>event.target.files[0];
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match('image.*|application.pdf*') == null) {
      this.messageCardRectoFile = "Fichier non valide.";
      this.imgURLCardRectoFile = "";
      return;
    }

    var reader = new FileReader();
    this.imagePathCardRectoFile = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURLCardRectoFile = reader.result;
      this.messageCardRectoFile = ""
    }
  }

  previewCertificateFile(event) {
    this.selectFileCertificateFile = <File>event.target.files[0];
    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match('image.*|application.pdf*') == null) {
      this.messageCertificateFile = "Fichier non valide.";
      this.imgURLCertificateFile = "";
      return;
    }

    var reader = new FileReader();
    this.imagePathCertificateFile = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURLCertificateFile = reader.result;
      this.messageCertificateFile = ""
    }
  }

  public ageValidator() {
    let d1 = new Date();
    let d2 = new Date(this.eventForm.get('dateOfDeath').value);
    let d3 = d1.getTime() > d2.getTime()
    if (d3 === false) {
      this.eventForm.controls.dateOfDeath.setValue('')
      this.eventForm.controls.dateOfDeath.setValidators(Validators.required);
    }
    this.eventForm.controls.dateOfDeath.setValidators(Validators.required);
    this.eventForm.controls.countryOfDeath.setValidators(Validators.required);
    this.eventForm.controls.cityOfDeath.setValidators(Validators.required);
  }

  public initDeclareEvent() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));

    this.contactService.getAllEventSubmitService().subscribe(
      (res: any) => {
        if (res) {
          res.forEach(eventData => {
            if (id === parseInt(eventData.member_id)) {
              this.isUpdatingEvents = true;
              this.eventsData = eventData;
              this.eventForm.get('dateOfDeath').clearValidators();
              this.eventForm.get('countryOfDeath').clearValidators();
              this.eventForm.get('cityOfDeath').clearValidators();
            }
          })
        }
      }
    )
  }

  updateEvent(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    const formData = new FormData();
    formData.append('memberId', this.eventsData.member_id)
    formData.append('dateOfDeath', this.eventForm.get('dateOfDeath').value || this.eventsData.dateOfDeath)
    formData.append('countryOfDeath', this.eventForm.get('countryOfDeath').value || this.eventsData.countryOfDeath)
    formData.append('cityOfDeath', this.eventForm.get('cityOfDeath').value || this.eventsData.cityOfDeath)
    formData.append('deathCertificateFile', this.selectFile)
    formData.append('idCardRectoFile', this.selectFileCardRectoFile)
    formData.append('livingCertificateFile', this.selectFileCertificateFile)
    formData.append('pictureFile', this.selectFilePicture)

    this.contactService.updateEventSubmitService(formData, this.eventsData.id).subscribe(
      (res: any) => {
        this.isDataSend = true
        this.serviceNotif.success('Succès', "Modification réussie !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      }
    )
  }

  clickEvent() {
    this.status = !this.status;
  }

  clickEventPicture() {
    this.statusPicture = !this.statusPicture;
  }

  clickEventCardRectoFile() {
    this.statusCardRectoFile = !this.statusCardRectoFile;
  }

  clickEventCertificateFile() {
    this.statusCertificateFile = !this.statusCertificateFile;
  }

  canTransfert() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            this.currentAsso = res.data
            if (res.data.status !== "ENABLED") {
              this.router.navigate(['search_adherents'])
            }
          }
        });
  }

  public getCurrentAssociation() {
    this.contactService.getCurrentMembersAssociationGroupService()
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.data.status === "DEMO") {
              $("#exampleModalCenter_UNKNOW").click();
              $('#exampleModalCenter_UNKNOW').modal({
                backdrop: 'static',
                keyboard: false  // to prevent closing with Esc button (if you want this too)
              })
            }
            this.contactService.getAssociationGroupService().subscribe((data: any) => {
              if (data) {
                let filterData = data.data.filter(assoName => assoName.name !== res.data.name)
                this.allMember = data.data
                filterData.forEach(element => {
                  if (element.status === "ENABLED") {
                    this.canTransfert()
                    this.assosLofId = element
                    this.memberSelected.push(element.name)
                  }
                });
              }
            })
          }
        }
      )
  }


  public send(form: FormGroup): boolean {
    if (!form.valid) {
      return;
    }
    const formData = new FormData();
    formData.append('memberId', this.eventFormDataSucribe.getEvent().memberId)
    formData.append('dateOfDeath', this.eventForm.get('dateOfDeath').value)
    formData.append('countryOfDeath', this.eventForm.get('countryOfDeath').value)
    formData.append('cityOfDeath', this.eventForm.get('cityOfDeath').value)
    formData.append('deathCertificateFile', this.selectFile)
    formData.append('idCardRectoFile', this.selectFileCardRectoFile)
    formData.append('livingCertificateFile', this.selectFileCertificateFile)
    formData.append('pictureFile', this.selectFilePicture)

    this.contactService.declareEventSubmitService(formData)
      .subscribe(
        (res: any) => {
          this.isDataSend = true
        this.serviceNotif.success('Succès', "Création de l'évenement réussie !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      },
      error => {
        this.serviceNotif.error('Error', `Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028`, {
          position: ['top', 'left'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
        console.log("error send register -> ", error);
      })
  }

  getAllMemberAssociation() {
    let id = parseInt(this.route.snapshot.paramMap.get("id"));
    this.detailId = id;
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      if (item.data.role.value === "LOF_ADMIN" || item.data.role.value === "SUPER_ADMIN") {
        this.contactService.getUserLastEventById(this.detailId).subscribe(event => {
          if (!event) {
            this.router.navigate(['search_adherents']);
          }
          const input = {
            memberId: event['member_id'],
            dateOfDeath: null,
            countryOfDeath: null,
            cityOfDeath: null,
            deathCertificateFile: null,
            pictureFile: null,
            idCardRectoFile: null,
            idCardVersoFile: null,
            livingCertificateFile: null,
          }
          this.eventFormDataSucribe.setEvent(input);
          // set the association
          this.currentAsso = event['association'];
          // set the member
          this.datas = event['member'];
          return this.datas;

        });

      } else if (item.data.role.value === "ASSOCIATION_MANAGER") {
        this.getCurrentAssociation();
        this.contactService
          .getMemberAssociationGroupService()
          .subscribe((res: any) => {
            if (res) {
              res.data.forEach((info) => {
                if (parseInt(info.id) === parseInt(this.detailId)) {
                  const input = {
                    memberId: info.id,
                    dateOfDeath: null,
                    countryOfDeath: null,
                    cityOfDeath: null,
                    deathCertificateFile: null,
                    pictureFile: null,
                    idCardRectoFile: null,
                    idCardVersoFile: null,
                    livingCertificateFile: null,
                  }
                  this.eventFormDataSucribe.setEvent(input);
                  this.datas = info;
                  return this.datas;
                }
              });
            }
          });
      }
    })
  }

  readOnly() {
    this.edit = !this.edit;
  }

  getCurrentUser() {
    this.contactService.getCurrentUserService().subscribe((item: any) => {
      this.role = item.data.role.value
    })
  }

  get f() { return this.eventForm.controls; }
  get countryOfDeath() { return this.eventForm.get('countryOfDeath'); }
  get statut() { return this.eventForm.get('statut'); }
  get cityOfDeath() { return this.eventForm.get('cityOfDeath'); }
  get dateOfDeath() { return this.eventForm.get('dateOfDeath'); }
  get deathCertificateFile() { return this.eventForm.get('deathCertificateFile'); }
  get pictureFile() { return this.eventForm.get('pictureFile'); }
  get idCardRectoFile() { return this.eventForm.get('idCardRectoFile'); }
  get livingCertificateFile() { return this.eventForm.get('livingCertificateFile'); }

  onFocused(e) {
  }

  onChangeSearch(e) {

  }

  selectEvent(e) {

  }
}
