import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '@app/modules/contactez-nous/services/formDataService';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';

@Component({
  selector: 'app-white-page',
  templateUrl: './white-page.component.html',
  styleUrls: ['./white-page.component.css']
})
export class WhitePageComponent implements OnInit {
  public errorPage: any = false;
  constructor(
    private router: Router,
    private formDataService: FormDataService,
    private contactService: ContactService,
    ) {}

  ngOnInit() {
    this.getCallHttp();
    
  }

  getCallHttp() {
    this.contactService.getCallHttp().subscribe((data: any) => {
        
    },
    error => {
      if (error.status !== 200) {
        this.errorPage = true;
      } else {
        this.router.navigate(['home'])
      }
    })
  }

}
