import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ContactService } from '@app/modules/contactez-nous/services/contact.service';
import { EditNewsComponent } from '@app/modules/shared/components/edit-news/edit-news.component';
import { NotificationsService } from 'angular2-notifications';
import { ModalDialogService } from 'ngx-modal-dialog';
import { Observable, Subject } from 'rxjs';
import { LofNews } from '../interfaces/lof-news.interface';
import { NewsService } from '../services/news.service';

@Component({
  selector: 'app-actualite',
  templateUrl: './actualite.component.html',
  styleUrls: ['./actualite.component.css']
})
export class ActualiteComponent implements OnInit {

  // used regarding current impl
  isConnected = false;
  isLofAdminOrSuperAdmin = false;
  currentUser: any;

  lofNewsArray: LofNews[] = [];

  closingSubject$: Subject<void>;

  constructor(
    private modalService: ModalDialogService, private viewRef: ViewContainerRef,
    private contactService: ContactService, private newsService: NewsService,
    private serviceNotif: NotificationsService,
  ) {}

  ngOnInit() {
    this.newsService.getLofNews().subscribe(newsDatas => this.lofNewsArray = newsDatas);
    this._init();
  }

  public addNews() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Ajouter actualité',
      childComponent: EditNewsComponent
    });
  }

  private _init(): void {
    if (localStorage.getItem('token')) {
      this.isConnected = true;
      // why calling each time? user role may change, right???
      this.contactService.getCurrentUserService().subscribe((item: any) => {
        this.currentUser = item.data;
        this.isLofAdminOrSuperAdmin = item.data.role.value === 'SUPER_ADMIN' || item.data.role.value === 'LOF_ADMIN';
      });
      
    } else {
      this.isConnected = false;
      this.isLofAdminOrSuperAdmin = false;
      this.currentUser = undefined;
    }
  }


  review(lNews: LofNews): void {
    this.modalService.openDialog(this.viewRef, {
      title: 'Modification actualité',
      childComponent: EditNewsComponent,
      data: lNews
    });
    
  }

  delete(lNewsId: number): void {
    if(confirm("Confirmez vous la suppression de l'actualité?")) {
      this.newsService.deleteNews(lNewsId);
    }
  }

  publish(lNews: LofNews): void {
    const bodyData = {id: this.currentUser.id, role: this.currentUser.role.value};
    this.newsService.publishNews(lNews.id, bodyData).subscribe(result => {
      if (result && result.data) {
        const updatedNews = result.data;
        lNews.status = updatedNews['status'];
        lNews.publishedDate = updatedNews['publishedDate'];
        lNews.updatedById = updatedNews['updatedById'];
        this.serviceNotif.success('Succès', "Votre actualité est publiée !", {
          position: ['top', 'right'],
          timeOut: 5000,
          animate: 'fade',
          showProgressBar: true
        });
      }
    },
    error => {
      this.serviceNotif.error('Erreur', "Une erreur technique est survenue. Si elle persiste veuillez contacter LOF par mail à contact@lodysseefinale.com ou par téléphone au 0186860028", {
        position: ['top', 'right'],
        timeOut: 5000,
        animate: 'fade',
        showProgressBar: true
      });
    }
    );
  }

}
