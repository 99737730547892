import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

import { IAuthSecure } from "@app/modules/connexion/interfaces/connexion.interface";
import { IAdhrents } from "@app/modules/adherent-subscribe/interfaces/adherent.interface";
import { LofNews } from "@app/modules/actualites/interfaces/lof-news.interface";

@Injectable({
  providedIn: "root",
})
export class LofApiBackendService {
  constructor(private $http: HttpClient) { }

  public sendMailBackendService(dataMail: any) {
    const apiUrl = `${environment.apiBaseUrl}/contact`;

    return this.$http.post<boolean>(apiUrl, dataMail);
  }

  public registerBackendService(dataRegister: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/create`;
    return this.$http.post<boolean>(apiUrl, dataRegister);
  }

  public getAssociationGroupBackendService() {
    const apiUrl = `${environment.apiBaseUrl}/associations/all`;

    return this.$http.get(apiUrl);
  }

  public getAssociationEmailInfoService() {
    const apiUrl = `${environment.apiBaseUrl}/associations/all?includeUser=true`;

    return this.$http.get(apiUrl);
  }

  public getBackupDatabase(header) {
    const apiUrl = `${environment.apiBaseUrl}/database/backup`;

    return this.$http.get(apiUrl, header);
  }

  public getCallHttp() {
    const apiUrl = `${environment.apiBaseUrl}`;
    return this.$http.get(apiUrl);
  }

  public getOwnerAssociationGroupBackendService(header, memberId) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${memberId}/owners`;

    return this.$http.get(apiUrl, header);
  }

  public getMembersAssociationGroupBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/members`;

    return this.$http.get(apiUrl, header);
  }

  // public activeAssociationGroupBackendService(lofId, header) {
  //   const apiUrl = `${environment.apiBaseUrl}/associations/${lofId}/launch`;
  //   return this.$http.post<boolean>(apiUrl,"", header);
  // }

  public activeAssociationWithGroupBackendService(lofId, header) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${lofId}/activate`;
    return this.$http.put<boolean>(apiUrl,"", header);
  }

  public activeAssociationWithRequestBodyGroupBackendService(lofId, rqstBody, header) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${lofId}/activate`;
    return this.$http.put<boolean>(apiUrl, rqstBody, header);
  }


  public transfertMemberSubmitBackendService(dataBody, header) {
    const apiUrl = `${environment.apiBaseUrl}/transfers/create`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public checkAssociationBackendService(dataBody) {
    const apiUrl = `${environment.apiBaseUrl}/associations/check`;
    return this.$http.post<boolean>(apiUrl, dataBody);
  }

  public contributionAssociationBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/associations/contribution`;
    return this.$http.get(apiUrl, header);
  }

  public declareEventSubmitBackendService(dataBody, header) {
    const apiUrl = `${environment.apiBaseUrl}/events/create`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public updateEventSubmitBackendService(dataBody, idEvent, header) {
    const apiUrl = `${environment.apiBaseUrl}/events/${idEvent}`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public updateEventFilesBackendService(dataBody, idEvent, header) {
    //TODO: tests
    const apiUrl = `${environment.apiBaseUrl}/events/${idEvent}/files/update`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public deleteEventSubmitBackendService(idMember, header) {
    const apiUrl = `${environment.apiBaseUrl}/events/${idMember}`;
    return this.$http.delete<boolean>(apiUrl, header);
  }

  public getAllEventSubmitBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/events/all`;
    return this.$http.get(apiUrl, header);
  }

  public viewEventSubmitBackendService(dataBody, header) {
    const apiUrl = `${environment.apiBaseUrl}/events/generate-link`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public desActiveAssociationGroupBackendService(lofId, header) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${lofId}/deactivate`;
    return this.$http.put<boolean>(apiUrl,"", header);
  }

  public getSettingGroupBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/settings`;
    return this.$http.get(apiUrl, header);
  }

  public addSettingsBackendService(dataBody, header) {
    const apiUrl = `${environment.apiBaseUrl}/settings/update`;
    return this.$http.post<boolean>(apiUrl, dataBody, header);
  }

  public getCheckAssociationApiBackendService(assosName, header) {
    const apiUrl = `https://entreprise.data.gouv.fr/api/rna/v1/full_text/<${assosName}>?per_page=20`;
    return this.$http.get(apiUrl, header);
  }

  public getCheckAdressApiBackendService(addName) {
    const apiUrl = `https://api-adresse.data.gouv.fr/search/?q=${addName}&type=street`;
    return this.$http.get(apiUrl);
  }

  public getUserLofBackendService(userId, header) {
    const apiUrl = `${environment.apiBaseUrl}/users/${userId}`;

    return this.$http.get(apiUrl, header);
  }

  public getTransfertRequestBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/transfers`;

    return this.$http.get(apiUrl, header);
  }

  public putTransfertRequestBackendService(body, transfertId, header) {
    const apiUrl = `${environment.apiBaseUrl}/transfers/${transfertId}`;

    return this.$http.put<boolean>(apiUrl, body, header);
  }

  public deleteTransfertBackendService(transfertId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/transfers/${transfertId}`;
    return this.$http.delete<boolean>(apiUrl, header);
  }

  public sendTokenRecaptchaBackendService(token) {
    const apiUrl = `${environment.apiBaseUrl}/auth/captcha/validate`;
    return this.$http.post<boolean>(apiUrl, token);
  }

  public getCurrentMembersAssociationGroupBackendService(header) {
    const apiUrl = `${environment.apiBaseUrl}/associations/current`;

    return this.$http.get(apiUrl, header);
  }

  public statsHomeBackendService() {
    const apiUrl = `${environment.apiBaseUrl}/registration-stat`;

    return this.$http.get(apiUrl);
  }

  public adherentsSubscribeBackendService(dataRegister: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/create`;

    return this.$http.post<IAdhrents>(apiUrl, dataRegister, header);
  }

  public loginBackendService(dataLogin: any): Observable<IAuthSecure> {
    const apiUrl = `${environment.apiBaseUrl}/auth/login`;
    return this.$http.post<IAuthSecure>(apiUrl, dataLogin);
  }

  public exportBackendService(header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/export`;
    return this.$http.post<boolean>(apiUrl, { format: "table" }, header);
  }

  public exportPdfBackendService(header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/export`;
    return this.$http.post<boolean>(apiUrl, { format: "pdf" }, header);
  }

  public exportMemberBackendService(infoLofConnect: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/export`;
    return this.$http.post<boolean>(apiUrl, infoLofConnect, header);
  }

  public exportPdfMemberBackendService(infoLofConnect: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/export`;
    return this.$http.post<boolean>(apiUrl, infoLofConnect, header);
  }

  public importBackendService(fileImport: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/import`;
    return this.$http.post<boolean>(apiUrl, fileImport, header);
  }

  public updateMemberBackendService(newData: any, lofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/${lofId}`;
    return this.$http.put<boolean>(apiUrl, newData, header);
  }

  public updateAssociationBackendService(newData: any, lofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${lofId}/update`;
    return this.$http.put<boolean>(apiUrl, newData, header);
  }

  public getAllMemberBackendService(header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/all`;
    return this.$http.get(apiUrl, header);
  }

  public getMemberAssoBackendService(assoId, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${assoId}/members`;
    return this.$http.get(apiUrl, header);
  }

  public forgotPasswordBackendService(email: any) {
    const apiUrl = `${environment.apiBaseUrl}/auth/forgot/password`;
    return this.$http.post<boolean>(apiUrl, email);
  }

  public resetPasswordBackendService(newCredentials: any) {
    const apiUrl = `${environment.apiBaseUrl}/auth/reset/password`;
    return this.$http.post<boolean>(apiUrl, newCredentials);
  }

  public addUserBackendService(data: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/users/create`;
    return this.$http.post<boolean>(apiUrl, data, header);
  }

  public updateUserBackendService(data: any, lofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/users/${lofId}`;
    return this.$http.put<boolean>(apiUrl, data, header);
  }

  public deleteUserBackendService(lofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/users/${lofId}`;
    return this.$http.delete<boolean>(apiUrl, header);
  }

  public deleteMemberBackendService(memberLofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/members/${memberLofId}`;
    return this.$http.delete<boolean>(apiUrl, header);
  }

  public deleteAssociationBackendService(assosLofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${assosLofId}`;
    return this.$http.delete<boolean>(apiUrl, header);
  }

  public getAssociationBackendService(assosLofId: any, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/associations/${assosLofId}`;
    return this.$http.get(apiUrl, header);
  }

  public getUsersBackendService(header: any) {
    const apiUrl = `${environment.apiBaseUrl}/users`;
    return this.$http.get(apiUrl, header);
  }

  public getCurrentUsersBackendService(header: any) {
    const apiUrl = `${environment.apiBaseUrl}/users/current`;
    return this.$http.get(apiUrl, header);
  }

  public updateCurrentUsersEmailBackendService( data:Record<string, string | boolean>, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/auth/forgot/email`;
    return this.$http.post<boolean>(apiUrl, data, header);
  }

  public checkCurrentUsersEmailBackendService( data:Record<string, string>, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/auth/validate/email`;
    return this.$http.post<boolean>(apiUrl, data, header);
  }

  public validateCurrentUsersEmailBackendService( data:Record<string, string>, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/auth/change/email`;
    return this.$http.post<boolean>(apiUrl, data, header);
  }

  public addNewsBackendService(data: LofNews, headerOptions: any): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/news/new`;
    return this.$http.post<any>(apiUrl, data, headerOptions);
  }

  getPublishedNewsBackendService() {
    const apiUrl = `${environment.apiBaseUrl}/news`;
    return this.$http.get(apiUrl);
  }

  getAllNewsBackendService(requestOptions: any) {
    const apiUrl = `${environment.apiBaseUrl}/all-news`;
    return this.$http.get(apiUrl, requestOptions);
  }

  remove(lofNewsId: number, headerOptions: any): Observable<HttpEvent<boolean>> {
    const apiUrl = `${environment.apiBaseUrl}/news/${lofNewsId}`;
    return this.$http.delete<boolean>(apiUrl, headerOptions);
  }
  updateNewsBackendService(newsToUpdate: LofNews, headerOptions: any): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/news`;
    return this.$http.put<any>(apiUrl, newsToUpdate, headerOptions);
  }
  publishNewsBackendService(newsId: number, newsDataToPublish: any, headerOptions: any): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/news/${newsId}`;
    return this.$http.put<any>(apiUrl, newsDataToPublish, headerOptions);
  }

  getMemberByIdBackendService(userId: string, option: any): Observable<any> {
    const apiUrl = `${environment.apiBaseUrl}/members/${userId}`;
    return this.$http.get(apiUrl, option);
  }

  public getUserLastEventBackendService(userId: string, header: any) {
    const apiUrl = `${environment.apiBaseUrl}/events/user/${userId}`;
    return this.$http.get(apiUrl, header);
  }

}
